/*!
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

@use "sass:color";
@use "scss/colours";
@use "scss/scrollbar";
@use "scss/shadows";


/** Make sure that the loader fills the available space. */
.loader { flex: 1 1 0; }

/** Set the style of the item list. */
.list {
    // Fill the parent.
    flex: 1 1 0;

    // Darken the background and add a bottom separator.
    @include colours.themed {
        background-color: colours.t("page-background");
    }
}

/** Set the style of the buttons. */
.button {
    // Display the icon in the center.
    display: flex; justify-content: center; align-items: center;

    // Set the style of the button.
    font-size: 0.75rem; font-weight: normal;

    &__icon { height: 0.625rem; width: 0.625rem; }

    // Set the style of the border.
    border-radius: 1rem; padding: 0.25rem;
    @include colours.themed {
        background-color: colours.t("background");
        border: solid 1px colours.t("text");
        &--edit {
            color: colours.t("primary");
            border-color: colours.t("primary");
            &:hover {
                color: colours.t("background");
                background-color: colours.t("primary");
            }
        }
        &--delete {
            color: colours.t("danger");
            border-color: colours.t("danger");
            &:hover {
                color: colours.t("background");
                background-color: colours.t("danger");
            }
        }
        &--add {
            color: colours.t("primary");
            border-color: colours.t("primary");
            &:hover {
                color: colours.t("background");
                background-color: colours.t("primary");
            }
        }
    }

    // Set the style of the add button.
    &--add { align-self: flex-end; gap: 0.5rem; }
}
