/*!
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

@use "scss/colours";


/** Set the style of the search bar. */
.search {
    // Display the contents in a row.
    display: flex; flex-direction: row;
    justify-content: flex-start; align-items: center;
    gap: 0.5rem; padding: 0.25rem 0.5rem 0.5rem; margin: 0;
}

/** Set the style of the icon. */
.icon {
    // Set the size of the icon.
    height: 0.75rem; width: 0.75rem;
}

/** Set the style of the input. */
.input {
    // Clear the default styling.
    all: unset;

    // Fill the parent.
    flex: 1 1 0;

    // Set the size of the element.
    padding: 0;

    // Add a bottom border.
    @include colours.themed { border-bottom: solid 1px colours.t("text"); }

    // Set the style of the text.
    font-size: 0.875rem; line-height: 1;
    @include colours.themed {
        color: colours.t("text");
        &::placeholder { color: colours.t("text"); }
    }
}

/** Set the style of the separator. */
.separator {
    // Clear the default margin.
    margin: 0;

    // Set the colour of the separator.
    opacity: 1;
    @include colours.themed { border-color: colours.t("background-dark"); }
}
