/*!
 * Copyright © 2021-2022 - Zimproov.
 * All rights reserved.
 */

@use "scss/colours";
@use "scss/shadows";
@use "scss/mixins";
@import "./common";

/** Set the style of the toolbar. */
.toolbar {
    // Place on top of the parent.
    z-index: 1;

    // Place at the bottom right of the parent on small screens.
    @include mixins.media-breakpoint-down(md) {
        position: absolute;
        bottom: 0.75rem; right: 0.75rem;
        width: 2.5rem; max-height: 90%;
        overflow: hidden auto; pointer-events: all;
    }
    // Place at the bottom on larger screens.
    @include mixins.media-breakpoint-up(md) {
        position: absolute;
        bottom: 0.5rem; left: 50%; transform: translateX(-50%);
        height: 2.5rem;
    }

    // Display the contents in a column.
    display: flex; flex-direction: column-reverse;
    justify-content: flex-start; align-items: center;
    gap: 0.5rem; overflow: hidden; flex-wrap: nowrap;
    // Display in a row on larger screens.
    @include mixins.media-breakpoint-up(md) { flex-direction: row; }

    // Set the style of the box.
    border-radius: 1.25rem; padding: 0.25rem;
    @include colours.themed {
        background-color: transparentize(colours.t("text"), 0.5);
    }
    @include shadows.distance($distance: 0, $spread: 0.125rem, $transparency: 0.1);
}

/** Set the style of the show button. */
.button {
    // Change the background when opened.
    .toolbar--open & {
        @include colours.themed {
            background-color: colours.t("danger");
        }
    }
}
