/*!
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import the colours.
@import "../../../../assets/src/css/colours";
@import "../../../../assets/src/css/mixins";

/** Set the style of the tag reader. */
.tag-container {
    flex: 1 1 0;

    // Clear the start padding.
    padding-left: 0;

    // Display the contents in a row.
    display: flex; flex-direction: row;
    justify-content: flex-start; align-items: center;
    flex-wrap: wrap;

    /** Set the style of the title. */
    &__title {
        // Make sure the text is centered.
        flex: 0 0 auto; width: auto;
        display: inline; text-align: center;

        // Clear the end padding.
        padding-right: 0;

        // Make the text not selectable.
        user-select: none;

        // Set the style of the font.
        color: $dark-text-colour;
        font-weight: normal;
        font-size: 1rem;

        // Remove the default margin.
        margin: 0.5rem 0.5rem 0 0;
    }

    /** Set the style of the search bar. */
    &__search-bar { flex: 1 1 0; }
}

/** Set the style of the tags. */
.tag {
    // Display the elements in a row.
    display: flex; flex-direction: row;
    justify-content: center; align-items: center;
    flex-wrap: nowrap;

    // Add some margins/padding.
    margin: 0.25rem;
    padding: 0.125rem 0.5rem;

    // Add a rounded border.
    border-radius: 1rem; background-color: white;
    // shadows
    box-shadow: 0.25rem 0.25rem 0.5rem $tertiary-colour;

    // Make the text not selectable.
    user-select: none;

    // Set the style of the text.
    p { text-align: start; font-size: 1rem; color: $dark-text-colour; flex: 1 1 0; margin: 0 0.5rem; }
    /** Set the style of the image. */
    img { display: none; height: 1rem; width: 1.5rem; margin: 0 0 0 0; }

    // Set the hovered style.
    cursor: pointer;

    /** Set the style of the editable element. */
    &--editable {
        // Show the image.
        img { display: block; }

        // Change the padding.
        padding-left: 0.25rem;
    }

    /** Set the style of the openable button. */
    &__open:hover { background-color: $blue; p { color: white; } }

    /** Set the style of the remove tag button. */
    &__remove img { filter: $low-colour-filter; }
    &__remove:hover { img { filter: none; }; background-color: $low-colour; }

    /** Set the style of the add tag button. */
    &__add img { filter: $high-colour-filter; }
    &__add:hover { img { filter: none; }; background-color: $high-colour; }
}

/** Set the style of the add elements. */
.tag-add {
    /** Set the style of the button. */
    &__button {
        // Remove the default styling.
        &:active, &:focus, &:hover, & { background-color: white; outline: none; box-shadow: none; border: none; }

        // Display the image in the center.
        height: 2.5rem; width: 2.5rem;
        display: flex; justify-content: center; align-items: center;
        img { width: 100%; height: 100%; filter: $high-colour-filter; }
        &:hover img { filter: $primary-colour-filter; }

        // Set the margin-padding.
        margin: 0; padding: 0;

        // Roundify the element.
        border-radius: 100%;
    }

    /** Set the style of the input. */
    &__input {
        // Set the size of the element.
        height: 2.5rem; flex: 1 1 10rem;
    }
}
