/*!
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import the colours.
@import "css/colours";

/** Set the style of the new user item. */
.user {
    // Display the contents in a row.
    display: flex; flex-direction: row;
    justify-content: flex-start; align-items: flex-start;

    // Display the labelled values in a row.
    &__label {
        flex: 1 1 0;
        display: flex; flex-direction: row;
        justify-content: flex-start; align-items: flex-start;
    }

    // Display the feedback group in a column.
    &__feedback {
        flex: 1 1 0;
        display: flex; flex-direction: column;
        justify-content: flex-start; align-items: stretch;
    }

    // Set the style of the delete button.
    button {
        // Display the image in the center.
        display: flex; justify-content: center; align-items: center;
        padding: 0.25rem 0.5rem; width: 4rem; height: 2rem; border-radius: 1rem;
        img { max-height: 100%; filter: $low-colour-filter; }

        // Change the style when hovered.
        &:hover {
            background-color: $low-colour;
            img { filter: unset; }
        }
    }
    // Set the style of the label text.
    &__label p { margin: 0.25rem 0.5rem 0; }
}
