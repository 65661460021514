/*!
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */


.item {
    // Clear the default style.
    all: unset;

    // Fill the parent.
    flex: 1 1 0; align-self: stretch;
    text-align: start;
}
