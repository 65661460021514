/*!
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

@use "sass:color";
@use "sass:math";
@use "colours";

/**
 * Generates a shadow with the provided distance.
 *
 * @param {rem} [$distance=0.25rem] The distance of the item relative to the background.
 * @param {%} [$intensity=20%] The intensity of the shadow.
 */
@mixin distance($distance: 0.25rem, $intensity: 20%, $spread: $distance, $inset: false, $transparency: 0.5) {
    @include colours.themed {
        // Compute the shadow colour.
        $shadow-colour: color.adjust(color.scale(colours.t("background"), $lightness: -$intensity), $alpha: $transparency);

        // Render the shadow.
        @if ($inset) {
            box-shadow: inset $distance $distance math.abs($spread * 2) $shadow-colour;
        } @else {
            box-shadow: $distance $distance math.abs($spread * 2) $shadow-colour;
        }
    }
}

/**
 * Generates a shadow with the provided distance.
 *
 * @param {rem} [$distance=0.25rem] The distance of the item relative to the background.
 * @param {%} [$intensity=20%] The intensity of the shadow.
 */
@mixin distance-filter($distance: 0.25rem, $intensity: 20%) {
    @include colours.themed {
        // Compute the shadow colour.
        $shadow-colour: color.adjust(color.scale(colours.t("background"), $lightness: -$intensity), $alpha: 0.5);

        // Render the shadow.
        filter: drop-shadow($distance $distance ($distance * 2) $shadow-colour);
        // NOTE: Forces Safari to defer the rendering to the GPU to avoid blocking the whole page.
        //  see: https://stackoverflow.com/a/67022519
        transform: translateZ(0);
    }
}

/**
 * Generates a top-border shadow.
 *
 * @param {rem} [$distance=0.25rem] The distance of the item relative to the background.
 * @param {%} [$intensity=20%] The intensity of the shadow.
 */
@mixin top($distance: 0.25rem, $intensity: 20%) {
    @include colours.themed {
        // Compute the shadow colour.
        $shadow-colour: color.adjust(color.scale(colours.t("background"), $lightness: -$intensity), $alpha: 0.5);

        // Render the shadow.
        box-shadow: inset 0 $distance $distance (-$distance) $shadow-colour;
    }
}

/**
 * Generates a bottom-border shadow.
 *
 * @param {rem} [$distance=0.25rem] The distance of the item relative to the background.
 * @param {%} [$intensity=20%] The intensity of the shadow.
 */
@mixin bottom($distance: 0.25rem, $intensity: 20%, $spread: $distance) {
    @include colours.themed {
        // Compute the shadow colour.
        $shadow-colour: color.adjust(color.scale(colours.t("background"), $lightness: -$intensity), $alpha: 0.5);

        // Render the shadow.
        box-shadow: 0 $distance $spread math.div(-$spread, 2) $shadow-colour;
    }
}

