/*!
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import the application colours.
@use "css/colours";

/** Set the style of the loader. */
.loader {
    // Fill the parent.
    position: absolute; top: 0; right: 1rem; bottom: 0; left: 1rem;
    &--block { position: relative; top: unset; right: unset; bottom: unset; left: unset; min-height: 24rem; }

    // Set the colour of the background.
    &:not(&--transparent) { background-color: colours.$background-colour; }

    // Display the contents in the center.
    display: flex; flex-direction: column;
    justify-content: center; align-items: center;

    /** Set the style of the image. */
    &__loader { height: 25vh; width: 25vh; }

    /** Set the style of the text. */
    p {
        // Set the style of the font.
        text-align: center;
        font-size: 2.5vh;
        font-weight: bold;
        color: colours.$blue;
        line-height: 1.1;
        white-space: pre;
    }
}
