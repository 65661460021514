/*!
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import the colours.
@import "css/colours";

/** Set the style of the notice modal. */
.notice {
    // Display the contents in a row.
    display: flex; flex-direction: row;
    justify-content: center; align-items: center;
    overflow: visible; flex-wrap: wrap;

    // Add some padding.
    padding: 1rem 0.5rem;

    /** Set the style of the body. */
    &__body {
        // Add a slight inset shadow.
        box-shadow: inset 0 0 1rem $tertiary-colour;
        border-radius: 0 0 1rem 1rem;
    }

    /** Set the style of the container. */
    &__container {
        // Fill the container.
        flex: 1 1 0;

        // Display the contents in a column.
        display: flex; flex-direction: column;
        justify-content: flex-start; align-items: stretch;
        overflow: visible; flex-wrap: wrap;

        max-width: 50rem;
    }

    /** Set the style of the list container. */
    &__list-container {
        // Fill the parent.
        flex: 1 1 auto;
        // Set the size of the element.
        max-height: 25vh; min-height: 4rem;

        // Hide any overflow.
        overflow: hidden scroll;

        // Add a bottom margin
        margin-bottom: 0.5rem;

        // Add a border.
        border: solid 2px $tertiary-colour;
        box-shadow: inset 0 0 5px lightgray;
        border-radius: 0.5rem;
        background-color: white;
    }

    /** Set the style of the button container. */
    &__button-container {
        /** Display the contents in a row. */
        display: flex; flex-direction: row;
        justify-content: space-between; align-items: flex-end;

        // Add a small top margin.
        margin-top: 0.7rem;
    }

    /** Set the style of the buttons. */
    &__button {
        // Display the contents in a row.
        display: flex; flex-direction: row;
        justify-content: space-between; align-items: center;
        overflow: hidden; flex-wrap: nowrap;
        background-color: $blue;

        // Set the size of the button.
        height: 2.5rem; min-width: 6rem; max-width: 10rem;
        padding: 0.25rem 0.5rem 0.25rem 0.25rem;
        &--delete { padding-left: 0.75rem; }

        // Roundify the borders.
        border-radius: 2rem;

        // Set the style of the image.
        img { height: 100%; }
        // Set the style of the text.
        p { margin: 0 0 0 0.5rem; flex: 1 1 0; color: white; font-size: 1rem; }
        &:disabled { background-color: $tertiary-colour;}

        // Set the style of the confirm and cancel buttons.
        &--cancel { background-color: $low-colour; }
        &--confirm { background-color: $high-colour; }
    }

}
