/*!
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

@use "scss/mixins";

@import "css/colours";

.files {
    padding-bottom: 6rem;

    &__title {
        // Make the text not selectable.
        user-select: none;

        // Set the style of the font.
        color: $dark-text-colour;
        font-weight: normal;
        font-size: 1rem;

        // Remove the default margin.
        margin: 0 0.5rem 0 0;
    }
}

/** Set the style of the edit button. */
.edit-button {
    // Place at the top-right of the page.
    position: fixed; top: 3.5rem; right: 1rem;

    // Set the style of the icon.
    &__icon { height: 1rem; width: 1rem; }
}

/** Set the style of the share button. */
.share-button {
    // Place at the top-right of the page.
    position: fixed; top: 8.5rem; right: 1rem;
    @include mixins.media-breakpoint-down(sm) { top: 3.5rem; right: 6rem; }
}
