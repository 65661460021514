/*!
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

/** Set the style of the main menu. */
.main-menu {
    // Fill the screen.
    min-height: 100%; width: 100%;

    // Display the elements in a row.
    display: flex; flex-direction: column;
    justify-content: flex-start; align-items: stretch;

}
/** Set the style of the search container row. */
.search-container {
    // Display the contents in a row.
    display: flex; flex-direction: row;
    justify-content: flex-start; align-items: flex-end;
    gap: 1rem 0; padding: 1rem 1rem 2rem;

    // Add a white background.
    background-color: white;
}

/** Set the style of the home button. */
.home {
    // Fill the parent.
    height: 100%; width: 100%;
}

/** Set the style of the results container row. */
.result-container { flex: 1 0 auto; }
