/*!
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import the colours.
@import "css/colours";

/** Set the style of the row. */
.table-row {
    // Set the colour of the background on all cells.
    td, th { background-color: white; }
    // Add a border around the first and last cells.
    &:first-of-type > th { border-top-left-radius: 1rem; }
    &:last-of-type  > th { border-bottom-left-radius: 1rem; }

    // Add the borders around the cells.
    td { border-top: solid 1px $tertiary-colour; border-left: solid 1px $tertiary-colour; }
    &:last-of-type > td { border-bottom: solid 1px $tertiary-colour; }
    td:last-of-type { border-right: solid 1px $tertiary-colour; }

    // Set the size of the header column.
    th { width: auto; }

    // Align the titles in the center.
    th { text-align: center; font-weight: normal; font-size: 1rem; padding: 0.25rem 0.5rem; }
}
