/*!
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

@use "scss/colours";
@use "scss/mixins";


/** Set the style of the item. */
.item {
    // Display the contents in a grid.
    display: grid;
    grid-template-columns: 6rem 3rem 1fr 1.5rem;
    grid-template-rows: auto auto;
    grid-template-areas:
        "type      icon text delete"
        "favourite icon text edit";
    padding: 0.5rem 1rem;

    // Change the disposition on smaller screens.
    @include mixins.media-breakpoint-down(sm) {
        grid-template-columns: 5rem 3rem 1fr 1.5rem;
    }
}

/** Set the style of the item type. */
.type { grid-area: type; justify-self: center; }
/** Set the style of the favourite button. */
.favourite { grid-area: favourite; justify-self: center; }
/** Set the style of the text area. */
.text { grid-area: text; }

/** Set the style of the icon container. */
.icon {
    // Align at the end of the parent.
    grid-area: icon; align-self: flex-start; justify-self: flex-end;
}

/** Set the style of the buttons. */
.button {
    // Align at the center of the area.
    justify-self: center; align-self: center;

    /** Set the grid areas of the button types. */
    &--delete { grid-area: delete; }
    &--edit { grid-area: edit; }
}
