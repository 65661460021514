/*!
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

@use "scss/colours";

// Compute the sizes of the elements.
$loader-width: 1rem; $buttons-width: 2rem; $gap: 0.25rem;
$title-width: min(calc(90vw - $loader-width - $buttons-width - 2 * $gap), 12rem);

/** Set the style of the link. */
.link {
    // Display the contents in a grid.
    display: grid; grid-auto-rows: auto; grid-template-columns: $loader-width $title-width $buttons-width;
    grid-template-areas: "loader title buttons" ". activity buttons" ". expires buttons";
    align-items: center; justify-items: center; gap: 0.25rem $gap;
    padding: 0.25rem;

    // Set the style of the background.
    @include colours.themed { background-color: colours.t("background"); }
}

/** Set the style of the loader. */
.loader {
    // Hide the icon by default.
    visibility: hidden;

    // Set the size of the icon.
    height: 0.8rem; width: 0.8rem;

    // Set the colour of the icon.
    @include colours.themed { color: colours.t("primary"); }

    // Build the rotation animation.
    @keyframes rotate {
        from { rotate: 0deg; }
        to { rotate: 360deg; }
    }

    // Make the icon rotate when active.
    &--visible { visibility: visible; animation: rotate 1s linear infinite; }
}

/** Set the style of the title. */
.title { grid-area: title; width: $title-width; }
/** Set the style of the activity field. */
.activity { grid-area: activity; justify-self: stretch; padding-left: 1rem; }
/** Set the style of the expiration field. */
.expires { grid-area: expires; justify-self: stretch; padding-left: 1rem; }
/** Set the style of the buttons field. */
.buttons { grid-area: buttons; align-self: stretch; justify-self: end; }
