/*!
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

@use "sass:color";
@use "scss/colours";
@use "scss/shadows";


/** Set the style of the modal. */
.modal {
    &__content {
        // Override the default border.
        border: none; border-radius: 1rem;
    }
}

/** Set the style of the header. */
.header {
    // Display the contents in a row.
    display: flex; flex-direction: row;
    justify-content: space-between; align-items: center;

    // Set the style of the box.
    padding: 0.5rem; border: none;
}

/** Set the style of tye body. */
.body {
    // Display the contents in a column.
    display: flex; flex-direction: column;
    justify-content: flex-start; align-items: stretch;
    gap: 1rem; padding: 0.5rem 1rem 2rem;

    // Add a separation shadow.
    @include shadows.top;
}

/** Set the style of the title. */
.title {
    // Set the style of the text.
    text-indent: 1rem; font-size: 1.5rem;
    @include colours.themed { color: colours.t("primary"); }
}

/** Set the style of the close button. */
.close {
    // Clear the default styling.
    all: unset;

    // Display the icon in the center.
    display: flex; justify-content: center; align-items: center;

    // Set the style of the box.
    height: 1.5rem; width: 1.5rem;
    border-radius: 1.5rem; margin-right: 0.25rem;
    @include colours.themed {
        background-color: colours.t("background");
        border: solid 2px colours.t("danger");
        color: colours.t("danger");
        &:hover {
            background-color: colours.t("danger");
            color: colours.t("background");
        }
        &:active {
            background-color: color.adjust(colours.t("danger"), $lightness: -25%, $saturation: -50%);
            border-color: color.adjust(colours.t("danger"), $lightness: -25%, $saturation: -50%);
            color: colours.t("background");
        }
    }
}
