/*!
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

@use "css/colours";
@use "css/shadows";

/** Set the style of the input. */
.searchbar-input {
    position: relative;

    // Display the contents in a row.
    display: flex; flex-direction: row;
    justify-content: flex-start; align-items: stretch;

    // Set the default size of the element.
    height: 2rem;
    margin: 0; padding: 0.25rem 1.5rem 0.25rem 0.5rem;

    // Set the style of the box.
    border-radius: 1rem;
    border: solid 1px colours.$blue;
    background-color: white;

    // Set the style of the icon.
    &__icon { height: 100%; width: auto; margin-right: 0.5rem; }

    // Set the style of the input.
    &__input {
        // Fill the parent.
        flex: 1 0 0; width: 100%;

        // Set the style of the text.
        line-height: 1;
        color: colours.$dark-text-colour;
        font-weight: normal; font-size: 1rem;
        &:focus { font-style: italic; }

        // Set the style of the box.
        &, &:hover, &:focus, &:active {
            outline: none;
            border: none;
            box-shadow: none;
        }
    }
}
