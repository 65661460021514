/*!
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

@use "scss/colours";


/** Set the style of the container. */
.container {
    position: relative;

    // Display the page contents in the center.
    display: grid; justify-items: center; align-items: center;

    // Add a slight border.
    box-sizing: border-box;
    @include colours.themed {
        border-top: solid 1px colours.t("text");
    }
}

/** Set the style of the loader. */
.loader {
    justify-content: flex-start; padding-top: 8rem;
    &__icon { height: 12rem; }
}
