/*!
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

@use "scss/colours";
@use "./common.module";


/** Set the style of the button. */
.button {
    @extend .cell;

    // Set the style of the text.
    text-decoration: underline;
    @include colours.themed {
        color: colours.t("primary");
        &:hover {
            color: colours.t("secondary");
        }
    }
}

/** Set the style of the cell value. */
.value {
    // Display the contents in a row.
    display: flex; flex-direction: row;
    justify-content: flex-start; align-items: center;
    gap: 0.5rem;
}

/** Set the style of the recurrence. */
.recurrence {
    // Display the contents in a row.
    display: flex; flex-direction: row;
    justify-content: flex-start; align-items: center;
    gap: 0.25rem;

    // Set the style of the text.
    font-size: 0.75rem;
    &__icon { height: 1rem; }
}
