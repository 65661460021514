/*!
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Use the math module.
@use "sass:math";
// Import the colours.
@use "colours";

// Offset used for box shadows.
$bsoff: 0.125rem;

$regular-box-shadow: 0.25rem 0.25rem 0.5rem colours.$tertiary-colour;
$regular-box-shadow-dark: 0.25rem 0.25rem 0.5rem colours.$dark-text-colour;
$light-box-shadow: 0.125rem 0.125rem 0.25rem colours.$tertiary-colour;
$regular-centered-box-shadow: 0 0 0.25rem colours.$tertiary-colour;
$regular-centered-box-shadow-dark: 0.25rem 0.25rem 0.5rem colours.$dark-text-colour;
$light-centered-box-shadow: 0 0 0.125rem colours.$tertiary-colour;
$regular-bottom-box-shadow: 0 0.25rem 0.25rem colours.$tertiary-colour;
$light-bottom-box-shadow: 0 0.125rem 0.125rem colours.$tertiary-colour;

/// Generates a box shadow value.
///
/// @param $dark If true, renders a dark shadow.
/// @param $centered If true, renders the shadow centered.
@function box-shadow($dark: false, $centered: false, $light: false, $inset-spread: false) {
    $color: transparentize(colours.$tertiary-colour, 0.25);
    $offset-x: $bsoff; $offset-y: $bsoff;
    $spread: $bsoff * 2; $inset-spread: 0;

    // Check the arguments.
    @if $dark { $color: transparentize(colours.$dark-text-colour, 0.5); }
    @if $light { $offset-x: math.div($bsoff, 2); $offset-y: math.div($bsoff, 2); $spread: $bsoff; }
    @if $centered { $offset-x: 0; $offset-y: 0; }
    @if $inset-spread { $inset-spread: $bsoff; }

    @return $offset-x $offset-y $spread $inset-spread $color;
}
