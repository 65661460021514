/*!
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

@use "scss/colours";


/** Set the style of the container. */
.container {
    // Fill the parent.
    align-self: stretch; overflow: hidden;

    // Display the button in the center.
    display: flex; flex-direction: column;
    justify-content: center; align-items: center;
    padding: 0.5rem;

    // Set the style of the background.
    border-radius: 0 0 0.25rem 0.25rem;
    @include colours.themed {
        background-color: colours.t("background");
    }
}

/** Set the style of the button. */
.button {
    // Set the style of the background.
    border-radius: 1rem;
    @include colours.themed {
        color: colours.t("primary");
        border: solid 1px colours.t("primary");

        // Change the style when hovered.
        &:hover {
            color: colours.t("text-light");
            background-color: colours.t("primary");
        }

        // Change the style when active.
        &:active {
            color: colours.t("text-light");
            background-color: colours.t("secondary");
        }

        // Change the style when disabled.
        &:disabled {
            color: colours.t("text-light");
            background-color: colours.t("secondary");
            border-color: colours.t("secondary");
        }
    }

    // Add a transition for the colours.
    transition: color 0.1s ease-in-out, background-color 0.1s ease-in-out;

    // Set the style of the contents.
    &__contents {
        // Display the elements in a row.
        display: flex; flex-direction: row;
        justify-content: flex-start; align-items: center;
        gap: 0.5rem; padding: 0 0.25rem;

        // Set the style of the text.
        font-size: 0.8rem;

        // Hide the disabled contents when the button is enabled.
        .button:not(:disabled) &--disabled { display: none; }
        // Hide the enabled contents when the button is disabled.
        .button:disabled &--enabled { display: none; }
    }

    // Build the rotation animation.
    @keyframes rotate {
        from { rotate: 0deg; }
        to { rotate: 360deg; }
    }

    // Rotate the icon when the button is disabled.
    &__contents--disabled &__icon { animation: rotate 1s linear infinite; }
}
