/*!
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import the app colours.
@import "../../../../../assets/src/css/colours";
// Import the mixins.
@import "../../../../../assets/src/css/mixins";

/** Set the style of the popup. */
.popup {
    // Display the contents in a column.
    display: flex; flex-direction: column;
    justify-content: flex-start; align-items: stretch;

    // Add a slight inset shadow.
    box-shadow: inset 0 0 1rem $tertiary-colour;
    border-radius: 0 0 1rem 1rem;

    /** Set the style of the input container. */
    &__input {
        // Display the contents in a column.
        display: flex; flex-direction: column;
        justify-content: flex-start; align-items: center;

        // Add a small bottom margin.
        margin-bottom: 1rem;

        /** Set the style of the feedback. */
        &__feedback { margin: 0 0 0.5rem 1rem; font-size: 1rem; }

        /** Set the style of the field. */
        &__field { border: none; height: auto; font-size: 1rem; }
        &__field::placeholder { font-style: italic; }
    }

    /** Set the style of the buttons. */
    &__button-container {
        // Display the elements in a row.
        display: flex; flex-direction: row;
        justify-content: space-between; align-items: flex-end;
    }

    /** Set the style of each button. */
    &__button {
        // Display the contents in a row.
        display: flex; flex-direction: row;
        justify-content: space-between; align-items: center;
        overflow: hidden; flex-wrap: nowrap;
        background-color: $blue;

        // Set the size of the button.
        height: 2.5rem; min-width: 6rem; max-width: 10rem;
        padding: 0.25rem 0.5rem 0.25rem 0.25rem;
        &--delete { padding-left: 0.75rem; }

        // Roundify the borders.
        border-radius: 2rem;

        // Set the style of the image.
        img { height: 100%; }
        // Set the style of the text.
        p { margin: 0 0 0 0.5rem; flex: 1 1 0; color: white; font-size: 1rem; }
        &:disabled { background-color: $tertiary-colour; }

        // Change the style of the confirm and cancel buttons.
        &--cancel { background-color: $low-colour; }
        &--confirm { background-color: $high-colour; }
    }
}
