/*!
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

@use "./index.module.scss";
@use "scss/colours";
@use "scss/shadows";

/** Set the style of the image module. */
.image {
    @extend .module;

    // Use the position as an anchor.
    position: relative;

    // Display the contents in the center.
    height: 100%;
    display: flex; flex-direction: column;
    justify-content: center; align-items: center;
    overflow: hidden;

    // Set the style of the image.
    &__image {
        // Fill the parent.
        max-height: 100%; max-width: 100%;
        object-fit: contain; cursor: pointer;

        // Set the style of the box.
        // Set the style of the container.
        &__container {
            // Fill the parent.
            height: 100%; width: 100%;

            // Display the image in the center.
            display: flex; justify-content: center; align-items: center;
        }
    }

    // Set the style of the title.
    &__title {
        // Place at the top of the parent.
        position: absolute;
        left: 0; right: 0; top: 0; height: 6rem;

        // Display the text in the center.
        display: flex; flex-direction: column;
        justify-content: flex-start; align-items: center;
        padding: 0.25rem 0 1.5rem; overflow: hidden;

        // Generate a background gradient.
        pointer-events: none;
        border-radius: 1rem 1rem 0 0;
        @include colours.themed {
            background: linear-gradient(
                180deg,
                colours.t("text"),
                transparentize(colours.t("text"), 0.25) 50%,
                transparentize(colours.t("text"), 0.5) 75%,
                transparent 100%
            );
        }

        // Set the style of the visible element.
        opacity: 0; transition: opacity 250ms ease-out;
        &--visible { opacity: 1; }

        // Set the style of the text.
        &__text {
            @extend .title;
            @extend .module;
            @include colours.themed { color: colours.t("text-light"); }

            // Fill the parent.
            flex: 1 1 0;
            overflow: hidden auto;

            // Wrap the text.
            white-space: break-spaces;
        }
    }
}
