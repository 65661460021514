/*!
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

@use "scss/colours";
@use "scss/mixins";


/** Set the style of the icons. */
.icon {
    // Set the size of the icons.
    height: calc(1rem - 1px); width: calc(1rem - 1px); object-fit: contain;

    // Change the size on smaller displays.
    @include mixins.media-breakpoint-down(sm) {
        height: calc(0.75rem - 1px); width: calc(0.75rem - 1px);
    }

    // Display the icon in the center.
    justify-content: center; align-items: center;

    // Add some inside padding.
    padding: 0.125rem;

    // Hide by default.
    display: none;

    /** Set the style of the complete icon. */
    &--completed {
        // Set the style of the background.
        border-radius: 2rem;
        @include colours.themed {
            border: solid 1px colours.t("success");
            color: colours.t("success");
        }

        // Show when the training is complete.
        :global(.zaq-training__training-status--completed) & { display: flex; }
    }

    /** Set the style of the past due icon. */
    &--past-due {
        // Set the style of the background.
        border-radius: 2rem; padding: 0;
        height: 1.5rem; width: 1.5rem;
        @include colours.themed {
            color: colours.t("danger");
        }

        // Change the size on smaller displays.
        @include mixins.media-breakpoint-down(sm) {
            height: 1rem; width: 1rem;
        }

        // Show when the training is past due and not complete.
        :global(.zaq-training__training-status--past-due):not(:global(.zaq-training__training-status--completed)) & { display: flex; }
    }

    /** Set the style of the validated icon. */
    &--validated {
        // Set the style of the background.
        border-radius: 2rem;
        @include colours.themed {
            border: solid 1px colours.t("success");
            background-color: colours.t("success");
            color: colours.t("text-light");
        }

        // Show when the training is complete.
        :global(.zaq-training__training-status--validated) & { display: flex; }
    }
}
