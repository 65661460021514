/*!
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import the app colours.
@use "scss/colours";

/** Set the style of the user details. */
.details {
    // Display the contents in a row.
    display: flex; flex-direction: row;
    justify-content: flex-start; align-items: center;
    gap: 0.5rem; overflow: hidden;
    padding: 0.5rem 1rem; margin: 0 0 0.5rem 0;

    // Set the style of the user icon.
    &__icon { height: 5rem; }
    // Set the style of the text.
    &__legend { font-weight: bold; margin: 0 0.5rem; }
    &__text { margin: 0; }
}

/** Set the style of the table. */
.table {
    // Fill the container.
    width: 100%;

    /** Set the style of the container. */
    &__container {
        // Fill the parent.
        align-self: stretch; margin: 1rem 2rem;
        overflow: auto hidden;

        // Set the style of the container.
        padding: 0.5rem 1rem; border-radius: 1rem;
        @include colours.themed {
            background-color: colours.t("background");
        }
    }

    /** Set the style of the header row. */
    &__header {
        @include colours.themed {
            color: colours.t("secondary");
        }
    }
    /** Set the style of the legend cells. */
    &__legend {
        text-align: left;
        @include colours.themed {
            color: colours.t("primary");
            border: solid 1px colours.t("background-dark");
        }
    }
    /** Set the style of the rows. */
    &__row {
        height: 2.5rem;
    }

    // Set the style of the cells.
    &__cell, &__legend { padding: 0 !important; height: 1px; }

    // Set the style of the name.
    &__name {
        height: 100%; width: 100%;
        display: flex; justify-content: center; align-items: center;

        // Set the style of the validated name.
        @include colours.themed {
            color: colours.t("primary");
            &:hover { color: colours.t("secondary"); }
            &--validated {
                color: colours.t("text-light");
                background-color: colours.t("primary");
            }
        }
    }

    /** Set the style of the level container. */
    &__level {
        // Display the contents in a row.
        display: flex; flex-direction: row;
        justify-content: center; align-items: center;
        gap: 0.5rem; height: 100%;

        &__text { margin: 0; width: 4rem; }
        &__icon { height: 1.5rem; width: 1.5rem; }

        &--success {
            @include colours.themed {
                color: colours.t("text-light");
                background-color: colours.t("primary");
            }
        }
    }

    /** Set the style of the icons. */
    &__icon {
        // Set the style of the container.
        &__container {
            display: flex; justify-content: center; align-items: center; height: 100%;
        }

        @include colours.themed {
            color: colours.t("text-light");
            &__container--success {
                background-color: colours.t("primary");
            }
        }
        // Set the style of the icons.
        height: 1.5rem; width: 1.5rem;

        // Hide by default.
        visibility: hidden; &__container--success & { visibility: visible; }
    }

    /** Set the style of the due-date column. */
    &__due-date {
        all: unset;

        // Fill the parent.
        width: 100%; height: 100%;

        // Display the contents in the center.
        display: flex; justify-content: center; align-items: center;
        gap: 0.5rem;

        // Set the colour of the text.
        @include colours.themed {
            color: colours.t("text");
        }

        // Set the style of the past-due box.
        &--past-due {
            @include colours.themed {
                color: colours.t("text-light");
                background-color: colours.t("danger");
            }
        }

        // Set the style of the done box.
        &--done {
            @include colours.themed {
                color: colours.t("text-light");
                background-color: colours.t("primary");
            }
        }

        // Set the style of the icon.
        &__icon {
            height: 1rem; width: 1rem;
        }

        // Set the style of the recurrence.
        &__recurrence {
            // Display the elements in a row.
            display: flex; flex-direction: row;
            justify-content: flex-start; align-items: center;
            gap: 0.25rem;

            &__text { font-size: 0.75rem; margin: 0; line-height: 1; }
        }
    }

    /** Set the style of the wide cells. */
    &__wide {
        display: flex; flex-direction: row;
        justify-content: center; align-items: center;
        gap: 0.25rem;

        &__icon { height: 2rem; width: 2rem; }
        &__text { margin: 0; }
    }
}
