/*!
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

@use "scss/colours";

/** Set the style of the title. */
.title {
    // Clear the default styling.
    all: unset;

    // Set the style of the text.
    font-size: 1rem; font-weight: 400;
    @include colours.themed {
        color: colours.t("primary");
    }

    // Set the style of the box.
    @include colours.themed {
        border-bottom: solid 1px colours.t("primary");
    }

    // Change the style of the inactive links.
    :global(.share-link--expired) &, :global(.share-link--inactive) & {
        @include colours.themed {
            color: colours.t("background-dark");
            border-bottom-color: colours.t("background-dark");
        }
    }

    // Change the style of the empty link names.
    &:placeholder-shown, &::placeholder {
        font-style: italic;
        @include colours.themed {
            color: colours.t("danger");
            border-bottom-color: colours.t("danger");
        }
    }
}
