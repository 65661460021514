/*!
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import the colours.
@use "css/colours";
// Import the shadows.
@use "css/shadows";

/** Set the style of the loading icon. */
.loading-icon {
    // Display the image in the center.
    height: 1.5rem; width: 1.5rem;
    display: flex; flex-direction: column;
    justify-content: center; align-items: center;
    overflow: hidden;
    img { max-width: 100%; height: 100%; }

    // Set the style of the box.
    background-color: white; padding: 0.2rem;
    border-radius: 1rem; box-shadow: shadows.box-shadow();

    /** Set the style of the queued animation. */
    &--queued, &--preparing {
        background-color: colours.$dark-text-colour;
        img { animation: rotating-hourglass 0.75s linear infinite; }
    }

    /** Set the style of the upload animation. */
    &--uploading {
        background-color: colours.$blue;
        img { animation: moving-arrow-up 0.75s linear infinite; }
    }

    /** Set the style of the converting animation. */
    &--converting { background-color: colours.$blue; padding: 0; }

    /** Set the style of the download animation. */
    &--downloading {
        background-color: colours.$high-colour;
        img { animation: moving-arrow-down 0.75s linear infinite; }
    }

    /** Set the style of the storing animation. */
    &--storing { background-color: colours.$high-colour; padding: 0; }

    /** Set the style of the done animation. */
    &--done { background-color: colours.$high-colour; padding: 0.25rem; }
}
/** Animation for the hourglass. */
@keyframes rotating-hourglass {
    from { transform: rotateZ(0deg); }
    25% { transform: rotateZ(0deg); }
    75% { transform: rotateZ(180deg); }
    75.001% { transform: rotateZ(0deg); }
    to { transform: rotateZ(0deg); }
}

/** Animation for the arrows. */
@keyframes moving-arrow-up {
    from { transform: translateY(150%) scaleY(-1); }
    to { transform: translateY(-100%) scaleY(-1); }
}
@keyframes moving-arrow-down {
    from { transform: translateY(-100%); }
    to { transform: translateY(150%); }
}
