/*!
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

@use "scss/colours";


/** Set the style of the button. */
.button {
    // Fill the parent.
    flex: 0 1 auto;

    // Display the contents in a row.
    display: flex; flex-direction: row;
    justify-content: flex-start; align-items: center;
    gap: 0.25rem; flex-wrap: nowrap; overflow: hidden;

    // Clear the default padding/margin.
    padding: 0.25rem 0.5rem; margin: 0;

    // Set the style of the box.
    border-radius: 1rem;
    @include colours.themed {
        color: colours.t("primary");
        background-color: colours.t("background");
        border: solid 1px colours.t("primary");

        // Change the style when open.
        &--open {
            color: colours.t("background");
            background-color: colours.t("primary");
        }
    }

    // Add a slight transition to the button.
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out;

    /** Set the style of the text. */
    &__text {
        // Fill the parent.
        flex: 0 1 auto;

        // Set the style of the text.
        font-size: 0.75rem; text-align: start;
        text-indent: 0.25rem;
    }

    // Set the size of the icon.
    &__icon { height: 0.75rem; width: 0.75rem; }
}
