/*!
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

@use "scss/colours";
@use "scss/shadows";
@use "scss/mixins";


/** Set the style of the header. */
.header {
    // Stick to the top of the screen.
    position: sticky; top: 0; z-index: 1;

    // Display the contents in a row.
    display: flex; flex-direction: row;
    justify-content: flex-start; align-items: center;
    gap: 1rem; padding: 0.25rem;

    // Add a separation shadow.
    @include shadows.bottom($distance: 0.125rem);

    // Set the background of the element.
    @include colours.themed { background-color: colours.t("background"); }
}

/** Set the style of the title. */
.title {
    // Take as much space as needed.
    flex: 0 0 auto;

    // Set the size of the text.
    font-size: 1.25rem; line-height: 1.5; text-indent: 0.5rem;

    // Don't wrap.
    white-space: nowrap;

    // Set the colour of the title.
    @include colours.themed {
        color: colours.t("secondary");
    }
}

/** Set the style of the collapsing button. */
.collapse {
    // Hide by default.
    display: none;

    // Rotate when the box is open.
    transform: rotateZ(0);
    .header--open & { transform: rotateZ(90deg); }
    transition: transform 0.1s ease-in-out;

    // Set the style of the icon.
    @include colours.themed {
        color: colours.t("secondary");
    }
}
