/*!
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import the colours.
@use "scss/colours";
@use "scss/shadows";

/** Delete confirmation body. */
.confirm {
    // Display the elements in a column.
    display: flex; flex-direction: column;
    align-items: stretch; overflow: hidden;

    padding-top: 0.5rem;
    @include shadows.top();
}

/** Set the style of the text. */
.text {
    // Set the style of the font.
    font-size: 1rem; white-space: break-spaces;
    text-align: center; padding: 0 0.5rem;
}

/** Set the style of the buttons. */
.button {
    /** Set the style of the container. */
    &__container {
        // Display the elements in a row.
        display: flex; flex-direction: row;
        justify-content: center; align-items: center;
        gap: 1rem; padding: 0 1rem 1rem;
    }

    // Fill the parent.
    flex: 1 1 0;

    // Set the style of the box.
    border-radius: 1rem;
    @include colours.themed {
        color: colours.t("background");
        border: solid 2px colours.t("background");

        &--validate { background-color: colours.t("success"); }
        &--validate:hover {
            background-color: white;
            border-color: colours.t("success");
            color: colours.t("success");
        }
        &--cancel   { background-color: colours.t("danger") ; }
        &--cancel:hover   {
            background-color: white;
            border-color: colours.t("danger");
            color: colours.t("danger");
        }
    }
}

