/*!
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import the colours.
@import "css/colours";
// Import the shadows.
@import "css/shadows";
// Import the mixins.
@import "css/mixins";

/** Set the style of the account creator header. */
.header {
    // Display the contents in a column.
    display: flex; flex-direction: column;
    justify-content: flex-start; align-items: stretch;

    // Add a slight shadow.
    box-shadow: $light-bottom-box-shadow;

    /** Set the style of the title. */
    h2 { font-size: 1.25rem; color: $blue-dark; margin: 0.5rem 0 0.5rem 2rem; padding: 0; font-weight: normal; }

    /** Set the style of the tabs. */
    &__tab {
        // Remove the default button style.
        background: unset; border: none; outline: none;

        // Display the tabs in a row in their container.
        &-container { display: flex; flex-direction: row; align-items: stretch; }
        flex: 1 1 0;

        // Display the contents in a row.
        display: flex; flex-direction: row;
        justify-content: center; align-items: center;


        // Set the style of the tab.
        background-color: $blue-dark;
        height: 1.5rem; margin-top: 0.5rem;
        border-radius: 0.75rem 0.75rem 0 0;
        &:first-child { border-top-left-radius: 0; }
        &:last-child { border-top-right-radius: 0; }

        // Change the hovered and selected styles.
        transition: height 0.1s ease-in-out, margin-top 0.1s ease-in-out;
        &:hover { height: 2rem; margin-top: 0; }
        &--active { height: 2rem; margin-top: 0; background-color: $blue; }

        // Set the style of the contents.
        h3 { color: white; margin: 0; font-size: 1.125rem; font-weight: normal; }
        @include media-breakpoint-down(sm) { h3 { display: none; } }
        img { max-height: 80%; margin-right: 0.5rem; }
    }
}
