/*!
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */


/** Set the style of the loader. */
.loader {
    margin-top: 1rem;
}

/** Set the style of the step container. */
.container {
    // Display the elements in a column.
    display: flex; flex-direction: column;
    justify-content: flex-start; align-items: stretch;
    gap: 1rem; padding-bottom: 2rem;
}
