/*!
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import the colours.
@import "css/colours";

/** Set the style of the fullscreen modal. */
.fullscreen-modal {
    // Fill the screen.
    width: 100%; height: 100%;
    &__dialog { height: 100%; width: 100%; max-width: unset; margin: 0; padding: 1rem; }

    // Remove the default background.
    background: unset; border: unset;

    // Display the contents in the center.
    display: grid;
    justify-items: stretch; align-items: stretch;
    flex-wrap: nowrap; overflow: hidden scroll;

    // Make the contents fit the page.
    > * { max-height: 100%; max-width: 100%; }

    /** Set the style of the close icon. */
    &__close {
        // Remove the default styling.
        background: $low-colour; outline: unset; border: none;

        // Place at the top right.
        position: fixed; top: 0.25rem; right: 0.25rem;
        width: 2.5rem; height: 2.5rem;

        // Set the margin/padding.
        margin: 0; padding: 0.25rem;

        // Set the hovered style.
        border-radius: 100%;
        &:hover { background-color: desaturate(darken($low-colour, 20%), 20%); }
    }
}
