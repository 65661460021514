/*!
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

@use "scss/colours";
@use "scss/shadows";
@use "sass:color";

/** Set the style of the backdrop. */
.backdrop {
    // Render underneath the navbar.
    margin-top: 3rem; z-index: 90;

    // Set the style of the background.
    &:global(.show) { opacity: 1; }
    backdrop-filter: blur(8px);
    -webkit-backdrop-filter: blur(8px);
    @include colours.themed {
        background-color: color.change(colours.t("background"), $alpha: 0.5);
    }
    @include shadows.top;
}

/** Set the style of the modal. */
.modal { margin-top: 5rem; z-index: 95; }

/** Set the style of the content. */
.content {
    // Clear the default styling.
    border: none; border-radius: 0;
    padding: 0 2rem; background: none;

    // Display the contents in a column.
    display: flex; flex-direction: column;
    justify-content: flex-start; align-items: stretch;
    gap: 1rem;
}

/** Set the style of the logo. */
.logo { height: 4rem; }
