/*!
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import the colours.
@use "css/colours";
// Import the shadows.
@use "css/shadows";
// Import the mixins.
@import "css/mixins";


/** Set the style of the switch. */
.switch {
    --width: 1.5rem; --margin: 0.25rem;
    @include media-breakpoint-down(sm) { --width: 1rem; --margin: 0.125rem; }

    position: relative;
    * { user-select: none; }

    // Set the size of the element.
    width: auto;
    flex: 0 0 auto;

    // Display the contents in a row.
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;

    // Change the hover cursor.
    cursor: pointer;

    // Set the style of the disabled element.
    &--disabled { cursor: not-allowed; }

    /** Set the style of the slider element. */
    &__slider {
        position: relative;
        // Set the size of the element.
        width: calc(var(--width) * 2); height: var(--width);

        // Roundify the element.
        border-radius: var(--width);

        // Set the colour of the background.
        background-color: colours.$dark-text-colour;
        transition: background-color 0.15s linear, border-color 0.15s linear;

        // Display the padlock at the start of the item.
        display: flex; flex-direction: row;
        justify-content: flex-end; align-items: center;
        .switch--checked & { justify-content: flex-start; }
        padding: 0 calc(1.5 * var(--margin));

        /** Set the style of the toggle element. */
        &::before {
            // Place on the left of the slider.
            position: absolute;
            left: var(--margin);
            bottom: var(--margin);

            // Set the size of the toggle.
            height: calc(var(--width) - calc(var(--margin) * 2));
            width : calc(var(--width) - calc(var(--margin) * 2));
            content: "";

            // Roundify the element.
            border-radius: 100%;

            // Set the colour of the toggle.
            background-color: white;

            // Add a transition for the position.
            transition: left 0.3s ease-in-out, background-color 0.15s linear;
        }
    }

    /** Set the style of the text. */
    &__text {
        // Set the style of the font.
        color: colours.$dark-text-colour;
        font-size: calc(var(--width) - var(--margin));
        .switch--small & { font-size: calc(var(--width) - calc(var(--margin) * 2)); }
        transition: color 0.15s linear;

        // Force the width of the element.
        flex: 0 0 auto;

        // Change the margins.
        margin: { left: calc(var(--width) / 2); bottom: 0; right: calc(var(--width) / 4); }
    }

    /** Set the style of the checked slider. */
    &--checked &__slider {
        // Set the color of the background.
        background-color: colours.$blue;

        // Override the color according to the app color.
        &--primary      { background-color: colours.$primary-colour }
        &--secondary    { background-color: colours.$secondary-colour }
        &--tertiary     { background-color: colours.$tertiary-colour }
        &--low          { background-color: colours.$low-colour }
        &--med          { background-color: colours.$med-colour }
        &--high         { background-color: colours.$high-colour }
        &--highest      { background-color: colours.$highest-colour }
    }

    /** Move the checked toggle item to the right. */
    &--checked &__slider::before { left: calc(var(--width) * 2 - var(--width) + var(--margin)); background-color: white; }

    /** Set the style of the checked text. */
    &--checked:not(&--disabled) &__text {
        // Set the color of the text.
        color: colours.$blue;

        // Override the color according to the app color.
        &--primary      { color: colours.$primary-colour }
        &--secondary    { color: colours.$secondary-colour }
        &--tertiary     { color: colours.$tertiary-colour }
        &--low          { color: colours.$low-colour }
        &--med          { color: colours.$med-colour }
        &--high         { color: colours.$high-colour }
        &--highest      { color: colours.$highest-colour }
    }

    // Set the style of the disabled element.
    &--disabled {
        cursor: not-allowed;
        .switch__text { color: colours.$tertiary-colour; }
        .switch__slider { background-color: colours.$tertiary-colour; }
    }

    // Set the style of the padlock.
    &__padlock { height: calc(2 * var(--width) / 3); width: calc(2 * var(--width) / 3); }

    // Set the style of the explanation.
    &__explanation {
        // Set the style of the container.
        height: calc(3 * var(--width) / 4); width: calc(3 * var(--width) / 4);
        background-color: colours.$blue;
        padding: 0.2rem; border-radius: 100%;
        margin-right: 0.25rem;

        cursor: help;

        // Set the style of the popover.
        &__popover {
            border: none; padding: 0.25rem 0.5rem;
            color: white; background-color: colours.$blue;
            white-space: pre-line;
            :global(.popover-arrow) {
                &::after { border-top-color: colours.$blue; }
                &::before { border: none; }
            }
        }
    }
}
