/*!
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

@use "scss/colours";
@use "scss/shadows";


/** Set the style of the title. */
.title {
    // Display the text in the center.
    display: flex; flex-direction: row;
    justify-content: flex-start; align-items: center;

    // Set the style of the box.
    font-weight: normal;
    padding: 0.25rem 0 0.25rem 2rem;
    @include colours.themed {
        background-color: colours.t("background");
        &:not(.last) {
            border-bottom: solid 1px colours.t("background-dark");
        }
    }
}

/** Set the style of the buttons. */
.button {
    @include colours.themed {
        color: colours.t("primary");
        border-left: solid 1px colours.t("background-dark");
        &:not(.last) {
            border-bottom: solid 1px colours.t("background-dark");
        }
        background-color: colours.t("background");
    }

    /** Set the style of the icon. */
    &__icon {
        height: 1.5rem; width: 1.5rem;

        /** Set the style of the success icon. */
        &--success {
            @include colours.themed {
                color: colours.t("success");
            }
        }

        /** Set the style of the danger icon. */
        &--danger {
            @include colours.themed {
                color: colours.t("danger");
            }
        }
    }

}

/** Set the style of the text-editor. */
.text-editor {
    // Fill an entire row.
    grid-column-start: 1; grid-column-end: -1;
    position: relative;

    // Display the contents in a row.
    display: flex; flex-direction: row;
    justify-content: flex-start; align-items: center;
    gap: 0.5rem; padding-left: 2rem;

    // Set the style of the box.
    @include colours.themed {
        &:not(.last) {
            border-bottom: solid 1px colours.t("background-dark");
        }
        background-color: colours.t("background");
    }

    /** Set the style of the text-area. */
    &__textarea {
        // Remove the default border.
        border: none; outline: none;

        // Set the style of the box.
        padding: 0.25rem 0.5rem;
        @include colours.themed {
            color: colours.t("text");
        }

        // Fill the parent.
        flex: 1 1 0;
    }

    /** Set the style of the save button. */
    &__save {
        // Justify at the end of the parent.
        align-self: flex-end;

        // Set the size of the button.
        height: 1.5rem; width: 1.5rem;

        // Display the icon in the center.
        display: flex; justify-content: center; align-items: center;
        &__icon { height: 0.875rem; width: 0.875rem; }

        // Set the style of the box.
        margin: 0.25rem 0.5rem 0.25rem 0;
        border-radius: 1rem;
        @include colours.themed {
            color: colours.t("primary");
            background-color: colours.t("background");
            border: solid 1px colours.t("primary");
            &:hover {
                color: colours.t("text-light");
                background-color: colours.t("primary");
            }
        }
    }
}
