/*!
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

@use "scss/colours";


/** Set the style of the title. */
.title {
    // Fill the row.
    grid-column-start: 1; grid-column-end: -1;

    // Display the text in the center.
    display: flex; flex-direction: row;
    justify-content: flex-start; align-items: center;
    margin-top: 0.5rem;

    // Set the style of the cell.
    font-size: 1rem;
    padding: 0.5rem 1rem;
    @include colours.themed {
        background-color: colours.t("primary");
        color: colours.t("background");
    }
}
