/*!
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

@use "scss/colours";


/** Set the style of the favourites list. */
.list {
    // Clear the default styling.
    all: unset;

    // Display the items in a column.
    display: flex; flex-direction: column;
    justify-content: flex-start; align-items: stretch;
    flex-wrap: nowrap; gap: 0.25rem; padding: 0.5rem 0.25rem 6rem;
}


/** Set the style of the items. */
.item {
    // Clear the default styling.
    all: unset;

    // Set the style of the container.
    @include colours.themed {
        background-color: colours.t("background");
    }
}

/** Set the style of the empty message. */
.empty {
    // Fill the parent.
    flex: 1 1 auto;

    // Display the contents in a column.
    display: flex; flex-direction: column;
    justify-content: center; align-items: center;
    gap: 1rem;

    // Add some margins.
    margin: 0.5rem;

    // Set the style of the text.
    font-size: 1.25rem;
    text-align: center; font-style: italic;
    @include colours.themed {
        color: colours.t("secondary");
    }

    // Set the style of the icon.
    &__icon { height: 2rem; }
}
