/*!
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

@use "scss/colours";

.list {
    // Display the elements in a wrapping row.
    display: flex; flex-direction: row;
    justify-content: center; align-items: center;
    flex-wrap: wrap; gap: 0.25rem 1rem; padding: 0.25rem 1rem;

    // Set the style of the text.
    font-size: 0.8rem;
    @include colours.themed {
        color: colours.t("text");
    }

    // Set the style of the box.
    @include colours.themed { background-color: colours.t("background"); }
}

/** Set the style of the rows. */
.row {
    // Take only as much space as needed.
    flex: 0 0 auto;

    // Display the items in a row.
    display: flex; flex-direction: row;
    justify-content: center; align-items: center;
    gap: 1rem;

    // Hide the current row in validated mode.
    .list--validated &--current { display: none; }
    // Hide the validated row in current mode.
    .list--current &--validated { display: none; }
}

/** Set the style of the items. */
.item {
    // Display the elements in a row.
    display: flex; flex-direction: row;
    justify-content: flex-start; align-items: center;
    gap: 0.25rem;
}

/** Set the style of the icons. */
.icon {
    // Set the size of the icon.
    height: 1rem; width: 1rem;

    // Set the style of the danger icon.
    &--danger {
        @include colours.themed { color: colours.t("danger"); }
    }

    // Set the style of the needs-validation icon.
    &--needs-validation {
        // Change the height of the icon.
        height: 0.75rem; width: 0.75rem;

        // Set the style of the box.
        border-radius: 1rem; padding: 0.125rem;
        @include colours.themed {
            background-color: colours.t("background");
            color: colours.t("success");
            border: solid 1px colours.t("success");
        }
    }

    // Set the style of the done icon.
    &--done {
        // Change the height of the icon.
        height: 0.75rem; width: 0.75rem;

        // Set the style of the box.
        border-radius: 1rem; padding: 0.125rem;
        @include colours.themed {
            background-color: colours.t("success");
            color: colours.t("background");
            border: solid 1px colours.t("success");
        }
    }
}
