/*!
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import the colours.
@import "css/colours";

/** Set the style of the creation footer. */
.footer {
    // Display the button in the center.
    display: flex; align-items: stretch;
    &__button { flex: 1 1 0; }

    // Display the button contents in the center.
    &__button {
        display: flex; flex-direction: row;
        justify-content: center; align-items: center;
    }

    // Set the style of the button itself.
    &__button { border: none; outline: none; background: $high-colour; padding: 0.5rem 0; }

    // Set the style of the button contents.
    &__button {
        img { height: 2rem; margin: 0 0.5rem; }
        p { margin: 0; color: white; font-size: 1.25rem; }
    }

    // Set the style of the disabled button.
    &__button:disabled { background-color: $tertiary-colour; cursor: not-allowed; }
}
