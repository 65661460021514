/*!
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

@use "scss/colours";
@use "scss/shadows";


// Set the style of the popper.
.popper {
    // Display the contents in a column.
    display: flex; flex-direction: column;
    justify-content: flex-start; align-items: stretch;
    gap: 0; padding: 0; margin: 0; overflow: hidden;

    // Limit the width of the element.
    max-width: calc(100vw - 1rem);

    // Show on top of most things.
    z-index: 100;

    // Set the style of the box.
    border-radius: 0.25rem;
    @include colours.themed {
        background-color: colours.t("background");
    }
    @include shadows.distance($distance: 0.125rem, $intensity: 50%);
}
