/*!
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

@use "scss/colours";


/** Set the style of the button. */
.button {
    // Remove the default styling.
    all: unset;

    // Display the icon in the center.
    display: flex; flex-direction: column;
    justify-content: center; align-items: center;
    margin-top: 1rem;

    /** Set the style of the icon. */
    &__icon {
        // Set the size of the icon.
        height: 2.5rem; width: 2.5rem;

        // Set the style of the icon.
        border-radius: 100%; padding: 0.5rem;
        @include colours.themed {
            background-color: colours.t("background");
            color: colours.t("primary");
            border: solid 2px colours.t("primary");
        }
        .button:hover & {
            @include colours.themed {
                background-color: colours.t("primary");
                color: colours.t("text-light");
            }
        }
    }
}
