/*!
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

@use "scss/colours";


/** Set the style of the list. */
.list {
    // Clear the default styling.
    all: unset;

    // Add a max height to the list.
    max-height: 12rem; overflow: hidden auto;

    // Display the contents in a column.
    display: flex; flex-direction: column;
    justify-content: flex-start; align-items: stretch;
    gap: 0; padding: 0; margin: 0;

    @include colours.themed {
        background-color: colours.t("page-background");
    }
}

/** Set the style of the list items. */
.item {
    // Clear the default styling.
    all: unset; position: relative;

    // Display the contents in a row.
    display: flex; flex-direction: row;
    justify-content: flex-start; align-items: center;
    gap: 0.5rem; padding: 0.25rem 0.5rem;

    // Set the style of the text.
    font-size: 0.75rem; line-height: 1rem;
    @include colours.themed {
        color: colours.t("primary");
        background-color: colours.t("background");

        // Change the colours of the current item.
        &[aria-disabled="true"], &:hover {
            color: colours.t("background");
            background-color: colours.t("primary");
        }
    }

    // Change the cursor.
    cursor: pointer;
    &[aria-disabled="true"] {
        cursor: default;
    }

    // Set the style of the icon.
    &__icon {
        // Set the size of the icon.
        width: 0.75rem; height: 0.75rem;

        // Hide by default.
        visibility: hidden;

        // Show on the current item.
        .item[aria-disabled="true"] & { visibility: visible; }
    }
}

/** Set the style of the no-result text. */
.no-result {
    // Inherit properties of the item.
    @extend .item;

    // Set the style of the text.
    font-style: italic;
    @include colours.themed {
        color: colours.t("text");
        background-color: colours.t("page-background");
    }

    // Disable pointer events.
    pointer-events: none; cursor: default;
}
