/*!
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

@use "scss/colours";
@use "scss/core/heading";

/** Set the style of the title. */
.title {
    // Fill the parent.
    flex: 1 1 auto;

    // Remove the default padding/margins.
    padding: 0; margin: 0;
    line-height: 1;

    // Remove the default styling of the input.
    &, &:active, &:hover, &:focus {
        border: none;
        outline: none;
        background: none;
    }

    &:focus {
        font-style: italic;
    }

    // Change the cursor when the text is editable.
    &:is(input) { cursor: text; }
}
