/*!
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

@use "scss/colours";

/** Set the style of the buttons. */
@mixin button {
    // Set the size of the button.
    height: 1.5rem; width: 1.5rem;

    // Display the icon in the center.
    display: flex; justify-content: center; align-items: center;

    // Set the style of the background.
    border-radius: 1rem;
    @include colours.themed {
        border: solid 1px colours.t("priamry");
        color: colours.t("primary");

        // Change the style when hovered.
        &:hover {
            background-color: colours.t("primary");
            color: colours.t("text-light");
        }

        // Change the style when active.
        &:active {
            background-color: colours.t("secondary");
            color: colours.t("text-light");
        }

        // Override the disabled style.
        &:disabled {
            cursor: wait;
            color: colours.t("background-dark");
            border-color: colours.t("background-dark");
            background-color: colours.t("background");
        }

        // Change the style of the danger button.
        &--danger {
            border-color: colours.t("danger");
            color: colours.t("danger");

            // Change the style when hovered.
            &:hover {
                background-color: colours.t("danger");
                color: colours.t("text-light");
            }

            // Change the style when active.
            &:active {
                background-color: colours.t("danger");
                color: colours.t("text-light");
            }

            // Override the disabled style.
            &:disabled {
                cursor: wait;
                color: colours.t("danger");
                border-color: colours.t("danger");
                background-color: colours.t("background");
            }
        }
    }

    // Add a transition to the colours.
    transition: color 0.1s ease-in-out, background-color 0.1s ease-in-out;

    // Set the size of the icon.
    &__icon { height: 0.75rem; width: 0.75rem; }
}
