/*!
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

@use "scss/colours";
@use "scss/core/heading";

/** Set the style of the title nav. */
.title {
    // Set the style of the link.
    &__link {
        // Display the contents in a row.
        display: flex; flex-direction: row;
        justify-content: flex-start; align-items: center;
        gap: 0.5rem;

        // Change the colour of the link when hovered.
        &:hover {
            @include colours.themed { color: colours.t("primary"); }
            // Display the hover icon.
            .title__link__icon { display: none; }

            .title__link__hover-icon { display: block; }
        }

        // Set the style of the text.
        text-decoration: none; font-weight: bold;

        // Hide the hover icon.
        &__icon, &__hover-icon { height: 1.2rem; }

        &__icon { display: block; }

        &__hover-icon { display: none; }
    }

    // Set the style of the title.
    &__heading {
        // Add some top margin.
        margin-top: 1rem;
        // Add a slight left padding.
        padding-left: 0.5rem;

        // Add a line after the title.
        &::after {
            content: ""; display: block;
            width: calc(100% + 0.5rem); height: 2px; margin-left: -0.5rem;
            @include colours.themed { background-color: colours.t("primary"); }
        }
    }

    /** Set the style of the subtitle. */
    &__subheading {
        // Set the style of the text.
        font-size: 1rem;
        @include colours.themed { color: colours.t("lowlight"); }

        // Add a slight left padding.
        padding-left: 0.75rem;
    }
}
