/*!
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

@use "scss/colours";
@use "scss/mixins";

/** Set the style of the module titles. */
.title {
    flex: 0 0 auto; max-width: 100%;
    text-align: center; font-size: 2rem; font-weight: 600; line-height: 1;
    margin: 0; padding: 0 0 0.5rem; overflow: hidden auto; pointer-events: all;
    @include colours.themed {
        color: colours.t("text");
    }
}

/** Set the base style for all the modules. */
.module {
    // Set the style of the scrollbar.
    &::-webkit-scrollbar {
        // Set the width of the scrollbar background.
        border-radius: 0.5rem; width: 0.5rem;

        // Set the colour of the background.
        @include colours.themed {
            background-color: colours.t("text");
            box-shadow: inset 0 0 0.125rem transparentize(colours.t("text-light"), 0.5);
        }
    }

    // Set the style of the scrollbar thumb.
    &::-webkit-scrollbar-thumb {
        // Set the width of the scrollbar background.
        border-radius: 0.5rem;

        @include colours.themed {
            background-color: colours.t("primary");
            box-shadow: inset 0 0 0.125rem transparentize(colours.t("text-light"), 0.75);
        }
    }
}

/** Set the style of the loader. */
.loader {
    // Fill the parent.
    flex: 1 1 0;
}
