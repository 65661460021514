/*!
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

@use "scss/colours";


/** Set the style of a tab. */
.tab {
    // Clear the default styling.
    all: unset;

    // Display the contents in a row.
    display: flex; flex-direction: row;
    justify-content: flex-start; align-items: center;
    gap: 0.25rem;

    // Disable user selection.
    user-select: none; -webkit-user-select: none;

    // Set the style of the tabs.
    font-weight: 300;
    @include colours.themed { color: colours.t("background-dark"); }

    // Change the style of the current tab.
    &:disabled {
        font-weight: bold;
        @include colours.themed {color: colours.t("primary");}
    }
}

/** Set the style of the text. */
.text {
    // Clear the default margin.
    margin: 0;

    // Add a transparent border at the bottom.
    border-bottom: solid 2px transparent;

    // Show the border when the parent is active.
    .tab:disabled & {
        @include colours.themed { border-bottom-color: colours.t("secondary"); }
    }
}

/** Set the style of the loader. */
.loader {
    // Hide by default.
    visibility: hidden;

    // Show only when the tab is loading.
    .tab--loading:disabled & { visibility: visible; }

    // Rotate the icon.
    @keyframes rotate {
        from { transform: rotateZ(-45deg); }
        to { transform: rotateZ(315deg); }
    }
    animation: rotate 1s linear infinite;
}
