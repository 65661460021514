/*!
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import the colours.
@import "css/colours";
// Import the mixins.
@import "css/mixins";

/** Set the style of the user list table. */
.user-list {
    // Display the content in a column.
    display: flex; flex-direction: column;
    justify-content: flex-start; align-items: stretch;
    overflow: hidden scroll; flex-wrap: nowrap;

    // Remove the default styling.
    list-style: none; padding: 0.5rem 0 1rem 0; margin: 0;

    // Set the min and max sizes of the table.
    max-height: 50vh;

    /** Set the style of the search bar. */
    &__search-bar {
        // Add some margins.
        margin: 0.5rem 2rem;
    }

    /** Set the style of the list items. */
    &__item {
        // Display the content in a row.
        display: flex; flex-direction: row;
        justify-content: flex-start; align-items: center;
        overflow: hidden; flex-wrap: wrap;

        // Set the size of the rows.
        flex: 0 0 auto;

        // Add some inside padding.
        margin: 0; padding: 0.25rem 0;

        /** Set the style of the text. */
        &__name {
            // Fill 65% of the parent.
            flex: 65 65 0;
            display: flex; flex-direction: row;
            justify-content: flex-start; align-items: center;
            overflow: hidden; flex-wrap: nowrap;

            button {
                // Align the elements.
                display: flex; flex-direction: row;
                justify-content: flex-start; align-items: center;

                // Fill the parent.
                flex: 1 1 0;
                // Remove the default styling.
                background: unset;
                outline: unset;

                // Add ellipses if the text overflows.
                > p {
                    margin: 0;
                    flex: 1 1 0;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    border-bottom: solid 2px $blue;
                }

                // Set the style of the font.
                text-align: start;
                font-size: 1rem;
                color: $blue;
                font-weight: 500;
                font-style: normal;

                // Remove the default margin.
                margin: 0 0.5rem;
                padding: 0 2rem 0 0.25rem;
                // Add a bottom border.
                border: unset;
                &:focus > p {
                    border-bottom-color: $primary-colour;
                }

                // Set the style of the image.
                img { visibility: hidden; height: 1rem; filter: $tertiary-colour-filter; margin-right: 0.5rem; }
            }
        }

        /** Set the style of the disabled accounts. */
        &--disabled &__name button { color: $tertiary-colour; }
        &--disabled &__name button img { visibility: visible; }

        /** Hide the role and actions on smaller screens. */
        &__actions, &__role {
            @include media-breakpoint-down(lg) {
                display: none !important;
            }
        }

        /** Set the style of the role. */
        &__role {
            // Fill 25% of the parent.
            flex: 25 25 0;

            // Display the contents in a row.
            display: flex; flex-direction: row;
            justify-content: flex-start; align-items: stretch;
            overflow: hidden; flex-wrap: nowrap;
        }

        /** Set the style of the action buttons. */
        &__actions {
            // Fill 10% of the parent.
            flex: 10 10 0;

            // Display the contents in a row.
            display: flex; flex-direction: row;
            justify-content: space-evenly; align-items: stretch;
            overflow: hidden; flex-wrap: nowrap;

            /** Set the style of the buttons. */
            button {
                // Remove the default styling.
                background: unset; outline: none;
                height: 2.5rem;

                // Add a focused effect.
                border: {
                    top: unset; right: unset; left: unset;
                    bottom: solid 2px transparent;
                }
                &:focus { border-bottom-color: $primary-colour; }

                // Display the image in the center.
                display: flex; justify-content: center; align-items: center;
                padding: 0.25rem 0.5rem;
                img { max-width: 100%; max-height: 100%; }
            }

            /** Set the style of the edit button. */
            &__edit {
                // Add a filter to the image.
                img { filter: $secondary-colour-filter; }
            }

            /** Set the style of the delete button. */
            &__delete {
                // Add a filter to the image.
                img { filter: $low-colour-filter; }

                // Hide the icon if the button is disabled.
                &:disabled img { visibility: hidden; }
            }
        }
    }
}
