/*!
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import the colours.
@import "css/colours";

/** Set the style of the search bar list. */
.list {
    $row-height: 2rem;

    /** Set the style of the directions. */
    &--down {
        // Place below the parent.
        position: absolute; top: 100%;
        overflow: hidden; width: 95%;

        // Roundify the borders.
        border-radius: 0 0 0.5rem 0.5rem;
    }
    &--up {
        // Place above the parent.
        position: absolute; bottom: 100%;
        overflow: hidden; width: 95%;

        // Roundify the borders.
        border-radius: 0.5rem 0.5rem 0 0;
    }

    // Add a slight border.
    box-shadow: 0.25rem 0.25rem 0.5rem $tertiary-colour;//, inset 0 0 0.5rem $tertiary-colour;

    // Add a background colour.
    background-color: white;

    /** Set the style of the scrollable container. */
    &__container {
        // Display the contents in a column.
        display: flex; flex-direction: column;
        justify-content: flex-start; align-items: stretch;
        overflow: hidden scroll; flex-wrap: nowrap;

        // Define the size of the list.
        max-height: 4.5 * $row-height;
        width: 100%;

        // Set the padding/margin.
        padding: 0; margin: 0;
    }

    /** Set the style of the list elements. */
    &__item {
        // Display the contents in a row.
        display: flex; flex-direction: row;
        justify-content: flex-start; align-items: center;
        overflow: hidden; flex-wrap: nowrap;

        // Set the height of the element.
        flex: 0 0 auto; min-height: $row-height;

        // Add a small top border.
        &:not(:first-child) { border-top: solid 1px $tertiary-colour; }

        // Change the cursor.
        cursor: pointer;

        // Add some inside padding.
        padding: 0 0.5rem;

        /** Set the style of the default text. */
        &__text {
            // Remove the default margin.
            margin: 0;

            // Set the style of the text.
            color: $secondary-colour;

            // Fill the parent.
            flex: 1 1 0;

            // Add ellipses.
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }

        /** Set the style of the selected item. */
        &--selected { background-color: $secondary-colour; p { color: white; } font-weight: bold; }
    }
}
