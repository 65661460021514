/*!
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

@use "css/colours";
@use "css/shadows";


/** Set the style of the search bar list. */
.searchbar-list {
    // Display below the parent.
    position: absolute; z-index: 1000;

    // Display the contents in a column.
    display: flex; flex-direction: column;
    justify-content: flex-start; align-items: stretch;
    overflow: hidden auto; max-height: min(24rem, 50vh);

    // Set the style of the box.
    background-color: white; box-shadow: shadows.box-shadow();
    border-radius: 0 0 1rem 1rem; padding: 0.5rem 0 0;
    list-style: none;

    // Set the style of the category separator.
    &__category:not(:last-child)::after {
        content: ""; height: 1px; background-color: transparentize(colours.$tertiary-colour, 0.33);
        margin: 0.5rem 0;
    }

    // Set the style of the category sections.
    &__category {
        // Display the contents in a column.
        display: flex; flex-direction: column;
        justify-content: flex-start; align-items: stretch;

        // Set the style of the list.
        &__list {
            // Display the contents in a column.
            display: flex; flex-direction: column;
            justify-content: flex-start; align-items: stretch;

            // Set the style of the box.
            padding: 0; margin: 0;
            list-style: none;
        }

        // Set the style of the header and footer.
        header, footer {
            &:not(:empty) {
                color: lighten(colours.$dark-text-colour, 0.5);
                font-size: 0.8rem;
                padding: 0 0.5rem 0 1rem; margin: 0;
            }
        }
    }

    // Set the style of the items.
    &__item {
        // Make the button fill the space.
        display: flex; align-items: stretch; &__button { flex: 1 1 0; }
        padding: 0; margin: 0;

        // Set the style of the button.
        &__button {
            // Display the contents in a row.
            display: flex; flex-direction: row;
            justify-content: flex-start; align-items: stretch;
            overflow: hidden;
            padding: 0.25rem 0.5rem 0.25rem 1rem; margin: 0;

            // Clear the default styling.
            &:hover, &:focus, &:active, & {
                border: none; background: none; outline: none;
            }

            // Set the style when hovered.
            color: colours.$blue;
            &:hover { background-color: colours.$blue; color: white; }
        }
    }

    /** Set the style of the show all button. */
    &__show-all {
        font-size: 0.75rem; padding: 0.25rem;

        // Clear the default styling.
        &:hover, &:focus, &:active, & {
            border: none; background: none; outline: none;
        }

        // Set the style when hovered.
        color: colours.$dark-text-colour;
        &:hover { background-color: colours.$blue-dark; color: white; }
    }
}
