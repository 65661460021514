/*!
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

@use "scss/colours";
@use "scss/shadows";
@use "scss/mixins";


/** Set the style of the container. */
.container {
    // Fill the available space.
    flex: 1 0 auto;

    // Display the elements in a column.
    display: flex; flex-direction: column;
    justify-content: flex-start; align-items: stretch;
}

/** Set the style of the search area. */
#search-area {
    // Sticky to the top.
    position: sticky; top: 0;
    z-index: 10;

    // Dont stick on smaller displays.
    @include mixins.media-breakpoint-down(sm) {
        position: relative;
    }

    // Display the elements in a grid.
    display: grid; grid-template-areas: "tabs logo searchbar";
    grid-template-columns: auto 1fr 1fr; grid-template-rows: auto;
    align-items: center; justify-items: center;
    gap: 1rem; padding: 1.5rem 0 0.5rem;

    // Change the arrangement on smaller screens.
    @include mixins.media-breakpoint-down(md) {
        grid-template-areas:
            "logo"
            "searchbar"
            "tabs";
        grid-template-columns: 1fr;
        grid-auto-rows: auto;
        justify-items: stretch;
        padding-top: 0.5rem;
    }


    // Set the style of the background.
    @include colours.themed {
        background-color: colours.t("background");
    }
    @include shadows.bottom;
}

/** Set the style of the logo. */
.logo { grid-area: logo;
    // Remove any padding.
    padding: 0;

    // Set the size of the icon.
    &__icon { height: 2.5rem; }

    // Justify to the end of the parent.
    justify-self: end;

    // Center on smaller screens.
    @include mixins.media-breakpoint-down(md) {
        justify-self: center;
    }
}

/** Set the style of the searchbar. */
.searchbar { grid-area: searchbar;
    // Fill the available space, with a max width.
    justify-self: flex-start; width: 100%; max-width: 24rem;
    padding: 0 2rem;

    // Center on smaller screens.
    @include mixins.media-breakpoint-down(md) {
        justify-self: center;
    }
}

/** Set the style of the tab bar. */
.tabs { grid-area: tabs; align-self: end; }
