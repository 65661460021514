/*!
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import the colours.
@import "css/colours";
// Import the shadows.
@import "css/shadows";

/** Set the style of the carousel element. */
.carousel {
    // Use the position as an anchor.
    position: relative;
    overflow: visible;

    // Force the container to be square.
    display: flex; &::after { content: ""; padding-bottom: 100%; }

    /** Set the style of the carousel items. */
    &__item {
        // Compute the position as an absolute.
        position: absolute; transform: translateX(-50%);

        // Display the contents in the center.
        display: flex; flex-direction: column;
        justify-content: center; align-items: center;
        overflow: hidden;

        // Add a position transition.
        transition:
            left         ease-in-out 0.1s,
            bottom       ease-in-out 0.1s,
            width        ease-in-out 0.1s,
            height       ease-in-out 0.1s,
            border-width ease-in-out 0.1s;

        // Style the box.
        background-color: white;
        border-radius: 1rem;
        box-shadow: box-shadow();

        // Hide the elements outside the carousel.
        &---4, &--4 { display: none; }
        &---4, &--4, &---3, &--3 { border-width: 0; }

        /** Set the position of the items. */
        &---3 { left: 0; }
        &---2 { left: 15%; }
        &---1 { left: 30%; }
        &--0  { left: 50%; }
        &--1  { left: 70%; }
        &--2  { left: 85%; }
        &--3  { left: 100%; }
        &---3, &--3 { bottom: 50%; }
        &---2, &--2 { bottom: 49.33%; }
        &---1, &--1 { bottom: 46.66%; }
        &--0        { bottom: 45%; }

        /** Set the size of the items. */
        &---4, &--4 { height: 0;      width: 0;      }
        &---3, &--3 { height: 0;      width: 0;  }
        &---2, &--2 { height: 25%;    width: 25%;    }
        &---1, &--1 { height: 37.5%;  width: 37.5%;  }
        &--0        { height: 50%;    width: 50%;    }

        /** Set the z-indices of the items. */
        &---3, &--3 { z-index: 2; }
        &---2, &--2 { z-index: 3; }
        &---1, &--1 { z-index: 4; }
        &--0        { z-index: 5; }

        /** Set the style of the current title. */
        &__title {
            // Center the text in the parent.
            position: absolute; top: 70%; left: 0; right: 0;
            text-align: center; font-size: 1rem; line-height: 1;

            // Show only three lines.
            height: calc(1rem * 3);
            overflow: hidden;

            // Set the colour of the text.
            color: $dark-text-colour;
        }
    }

    /** Set the style of the arrows. */
    &__arrow {
        // Remove the default button styling.
        background-color: unset; border: unset; outline: unset;
        &:focus:active { background-color: unset; border: unset; outline: unset; box-shadow: unset; }
        &:hover { background-color: unset; border: unset; outline: unset; box-shadow: unset; }
        &:focus { background-color: unset; border: unset; outline: unset; box-shadow: unset; }

        // Place at the sides of the parent.
        position: absolute; top: 40%; transform: translateY(-50%);
        z-index: 10;
        filter:
            drop-shadow(1px 1px white)
            drop-shadow(-1px 1px white)
            drop-shadow(1px -1px white)
            drop-shadow(-1px -1px white);

        // Set the style of the image inside.
        img { height: 2.5rem; filter: $dark-text-colour-filter; }
        &:hover img { filter: $darker-text-colour-filter; }

        /** Set the style of the specific buttons. */
        &--next { right: 5%; }
        &--previous { left: 5%; }
        &--next img { transform: scaleX(-1); }
    }

    /** Set the style of the title. */
    &__title {
        // Center the text in the parent.
        position: absolute; top: 0; left: 0; right: 0; bottom: 70%;
        text-align: center;

        // Display the text at the bottom.
        display: flex; flex-direction: column;
        justify-content: flex-end;

        // Set the colour of the text.
        color: $dark-text-colour;
    }

    /** Set the style of the dots container. */
    &__dots {
        // Place in the parent.
        position: absolute; left: 0; right: 0; bottom: 33%;
        height: 1rem;

        // Display the items in a row.
        display: flex; flex-direction: row;
        justify-content: center; align-items: center;
        overflow: hidden;

        /** Set the style of the elements. */
        &__element {
            // Render as a round element.
            height: 0.75rem; width: 0.75rem;
            border-radius: 0.75rem;

            // Add some margins.
            margin: 0 0.25rem;

            // Allow the user to click on the dots.
            cursor: pointer;

            // Set the colours of the background.
            background-color: $tertiary-colour;
            &--current { background-color: $dark-text-colour; }
        }
    }
}
