/*!
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

@use "scss/colours";

/** Set the style of the list. */
.list {
    // Set a max-height for the component.
    max-height: 75vh; overflow: hidden auto;

    // Display the items in a grid.
    display: grid;
    grid-template-columns: [text] 2fr [editor] 1fr [switch] 1fr;
    grid-auto-rows: auto 2px;
    grid-row-gap: 0.25rem;
    align-items: center;

    //// Hack to add separators between the ines.
    //@include colours.themed {
    //    background: repeating-linear-gradient(
    //        to bottom,
    //        transparent 0,
    //        transparent 2.5rem,
    //        colours.t("background-dark") 2.5rem,
    //        colours.t("background-dark") calc(2.5rem + 2px)
    //    );
    //}
}
