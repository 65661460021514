/*!
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import the app colours.
@import "css/colours";
// Import the mixins.
@import "css/mixins";

/** Set the style of the creation menu. */
.create-menu {
    img {
        pointer-events: none;
    }

    // Place at the bottom right of the screen.
    position: fixed;
    bottom: 2vw;
    right: 4vw;
    z-index: 1000;

    // Display the contents in a column.
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-end;
    align-items: flex-end;
    flex-wrap: nowrap;
    overflow: visible;

    /** Set the style of the anchor. */
    &__anchor {
        // Set the size of the button.
        height: 4rem;
        width: 4rem;
        @include media-breakpoint-up(lg) {
            height: 5rem;
            width: 5rem;
        }
        // Set the visual style of the button.
        background-color: white;
        padding: 0;
        margin-right: calc(0.25rem + 1px);
        border-radius: 100%;

        img {
            filter: $high-colour-filter;
        }

        border: none;

        // Fit the image in the center.
        display: flex;
        justify-content: center;
        align-items: center;

        img {
            width: 100%;
            height: 100%;
        }

        // Add a transform transition.
        img {
            transition: rotate 0.1s ease-in-out;
        }
    }

    &--open &__anchor img {
        rotate: 45deg;
    }
}
