/*!
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

@use "scss/colours";


/** Set the style of the list. */
.list {
    // Fill the parent.
    flex: 0 1 auto;

    // Display the contents in a column.
    display: flex; flex-direction: column;
    justify-content: flex-start; align-items: stretch;
    gap: 0.25rem; padding: 0.25rem; overflow: hidden auto;

    // Set the style of the background.
    @include colours.themed {
        background-color: colours.t("page-background");
    }

    // Set the style of the scrollbar.
    &::-webkit-scrollbar {
        // Set the width of the scrollbar background.
        border-radius: 0.5rem; width: 0.5rem;

        // Set the colour of the background.
        @include colours.themed {
            background-color: colours.t("text");
            box-shadow: inset 0 0 0.125rem transparentize(colours.t("text-light"), 0.5);
        }
    }

    // Set the style of the scrollbar thumb.
    &::-webkit-scrollbar-thumb {
        // Set the width of the scrollbar background.
        border-radius: 0.5rem;

        @include colours.themed {
            background-color: colours.t("primary");
            box-shadow: inset 0 0 0.125rem transparentize(colours.t("text-light"), 0.75);
        }
    }
}

/** Set the style of the button. */
.button {
    // Center in the parent.
    align-self: center;

    // Display the contents in a row.
    display: flex; flex-direction: row;
    justify-content: flex-start; align-items: center;
    gap: 0.5rem;

    // Set the style of the button.
    border-radius: 1rem; padding: 0 0.5rem;
    @include colours.themed {
        border: solid 1px colours.t("primary");
        color: colours.t("text");
    }

    // Set the style of the icon.
    &__icon {
        @include colours.themed { color: colours.t("primary"); }
    }

    // Set the style of the loading button.
    &--loading &__icon {
        @keyframes rotate {
            from { transform: rotateZ(0); }
            to { transform: rotateZ(360deg); }
        }
        animation: rotate 1s linear infinite;
    }
}
