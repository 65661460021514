/*!
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

@use "scss/colours";
@use "scss/shadows";


/** Set the style of the loader. */
.loader { height: 12rem; }

/** Set the style of the container. */
.container {
    // Display the contents in a grid.
    display: grid; grid-gap: 1rem 0.5rem;
    justify-items: center; align-items: center;
    grid-template-areas:
        "title title title title title"
        "none novice apprentice master expert";
    overflow: auto hidden; padding: 0 0 1rem;
}

/** Set the style of the title. */
.title {
    // Fill the area.
    grid-area: title; justify-self: stretch;

    // Set the style of the text.
    text-indent: 1rem;
    @include colours.themed {
        border-bottom: solid 1px colours.t("primary");
    }
}

/** Set the style of the buttons. */
.button {
    // Display the contents in a row.
    display: flex; flex-direction: row;
    justify-content: center; align-items: center;
    gap: 0.5rem;

    // Set the style of the box.
    border-radius: 1rem; cursor: pointer;
    padding: 0.25rem 0.5rem 0.25rem 0.25rem;
    @include colours.themed {
        color: colours.t("primary");
        background-color: colours.t("background");
        border: solid 1px colours.t("primary");
    }

    // Set the style of the icon.
    &__icon { height: 1.5rem; width: 1.5rem; }

    /** Set the style of the checked label. */
    &__input:checked + .button, &__input:hover + .button {
        @include colours.themed {
            background-color: colours.t("primary");
            color: colours.t("text-light");
        }
    }

    // Hide the radio button.
    &__input { display: none; }
}
