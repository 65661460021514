/*!
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

@use "sass:color";
@use "scss/colours";
@use "scss/shadows";
@use "scss/scrollbar";


/** Set the style of the list. */
.list {
    // Clear the default styling.
    all: unset;

    // Fill the parent.
    flex: 1 1 0;

    // Display the elements in a column.
    display: flex; flex-direction: column;
    justify-content: flex-start; align-items: stretch;
    overflow: hidden auto; gap: 0.125rem;
    @include scrollbar.with-scrollbar;
}

/** Set the style of the categories. */
.category {
    // Force the size of the element.
    flex: 0 0 auto;

    // Display the elements in a column.
    display: flex; flex-direction: column;
    justify-content: flex-start; align-items: stretch;
    overflow: hidden;

    // Set the style of the name.
    &__name {
        // Display the elements in a row.
        display: flex; flex-direction: row;
        justify-content: flex-start; align-items: center;
        gap: 0.25rem; padding: 0 0.5rem;

        // Set the size of the element.
        height: 1.5rem;

        // Set the style of the box.
        @include colours.themed {
            color: colours.t("secondary");
        }

        // Change the style when the category is open.
        .category--open & {
            font-weight: bold;

            // Change the colour of the elements.
            @include colours.themed {
                background-color: colours.t("primary");
                color: colours.t("background");
            }
        }
    }

    // Set the style of the chevron.
    &__chevron {
        // Rotate when the menu is open.
        transition: transform 0.1s linear;
        .category--open & { transform: rotateZ(90deg); }
    }
}
