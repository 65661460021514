/*!
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

@use "scss/colours";

/** Set the style of the container. */
.container {
    // Display the elements in a small table.
    display: grid;
    grid-template-columns: 1rem 1fr;
    grid-auto-rows: 1.5rem;
    justify-items: center; align-items: center;
    grid-gap: 0.5rem 0.5rem;

    // Add a separator line between the elements.
    @include colours.themed {
        background-image: repeating-linear-gradient(to bottom,
            colours.t("background") 0px,
            colours.t("background") 1.75rem,
            colours.t("primary") calc(1.75rem - 1px),
            colours.t("primary") calc(1.75rem + 1px),
            colours.t("background") calc(1.75rem + 1px),
            colours.t("background") 2rem
        );
        background-origin: content-box;
        background-repeat: no-repeat;
        background-position-x: center;
        background-size: 90% 100%;
    }

    // Set the style of the text.
    line-height: 1; text-align: center;
    font-size: 0.8rem; font-style: italic;
}

/** Set the style of the icons. */
.icon {
    // Set the size of the element.
    height: 1.5rem; width: 1.5rem;

    // Set the style of the element.
    border-radius: 100%;

    /** Set the style of the objective met icon. */
    &--objective-met {
        height: 0.75rem; width: 0.75rem;
        padding: 0.125rem;
        @include colours.themed {
            color: colours.t("success");
            border: solid 1px colours.t("success");
        }
    }

    /** Set the style of the validated icon. */
    &--validated {
        height: 0.75rem; width: 0.75rem;
        padding: 0.125rem;
        @include colours.themed {
            color: colours.t("text-light");
            border: solid 1px colours.t("success");
            background-color: colours.t("success");
        }
    }
}
