/*!
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */


/** Set the style of the status indicator. */
.status-indicator {
    // Display the contents in a row.
    display: inline-flex; flex-direction: row;
    justify-content: flex-start; align-items: center;
    gap: 0.5rem; overflow: hidden;

    padding: 0.5rem 1rem;
}
