/*!
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

@use "scss/colours";
@use "scss/shadows";


/** Set the style of the validation link. */
.validate {
    // Clear the default styling.
    all: unset;

    // Align to the center of the parent.
    align-self: center;

    // Set the style of the button.
    padding: 0.25rem 1rem; border-radius: 1rem;
    @include colours.themed {
        background: colours.t("background");
        border: solid 1px colours.t("primary");
        color: colours.t("primary");
        &:hover {
            background-color: colours.t("primary");
            color: colours.t("background");
        }
    }
}
