/*!
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

@use "scss/colours";
@use "scss/shadows";


/** Set the style of the button container. */
.button-container {
    // Display the items in a row.
    display: flex; flex-direction: row;
    align-items: stretch; gap: 0.5rem;
}

/** Set the style of the buttons. */
.button {
    // Clear the default style.
    all: unset;

    // Fill the parent.
    flex: 1 1 0;

    // Display the items in the center.
    display: flex; flex-direction: row;
    justify-content: center; align-items: center;
    gap: 0.5rem;

    // Set the style of the button.
    border-radius: 1rem;
    cursor: pointer;
    @include colours.themed {
        &--add {
            background-color: colours.t("background");
            color: colours.t("primary");
            border: solid 1px colours.t("primary");
            &:hover {
                background-color: colours.t("primary");
                color: colours.t("text-light");
            }
        }
        &--cancel {
            background-color: colours.t("background");
            color: colours.t("danger");
            border: solid 1px colours.t("danger");
            &:hover {
                background-color: colours.t("danger");
                color: colours.t("text-light");
            }
        }
        &:disabled {
            cursor: not-allowed;
            background-color: colours.t("background-dark");
            color: colours.t("text");
            border: solid 1px colours.t("background-dark");
        }
    }
}

/** Set the style of the body. */
.body {
    // Set the size of the element.
    height: 16rem;

    // Display the elements in a column.
    display: flex; flex-direction: column;
    justify-content: flex-start; align-items: stretch;
    gap: 0.5rem; padding: 0.5rem;
}
