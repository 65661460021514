/*!
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import the colours.
@import "css/colours";
// Import the shadows.
@import "css/shadows";

/** Set the style of the staged user info. */
.table {
    // Display the contents in a column.
    display: flex; flex-direction: column;
    justify-content: flex-start; align-items: stretch;
    list-style: none; padding: 1rem 0;
    overflow: scroll; max-height: 30rem;

    // Fill a bit more of the parent.
    width: 110%; margin: 0 0 1rem -5%;

    // Add a slight shadow.
    border-radius: 0 0 1rem 1rem;
    box-shadow: $regular-centered-box-shadow;


    // Display the rows in a row.
    &__row {
        flex: 0 0 auto;
        display: flex; flex-direction: row;
        justify-content: flex-start; align-items: flex-start;
        list-style: none; margin: 0; padding: 0;
        > * { flex: 1 1 0; }
    }
    // Display the cell contents in a row.
    &__cell {
        display: flex; flex-direction: row;
        justify-content: flex-start; align-items: flex-start;
    }

    // Set the style of the regular text.
    span { flex: 1 1 0; margin: 0 0.5rem 0 0.5rem; }
    p { flex: 1 1 0; border-bottom: solid 2px $tertiary-colour; margin: 4px 0.5rem 0 0.5rem; }

    // Set the style of the delete button.
    &__delete-button {
        height: 2rem; width: 3rem;
        border-radius: 1rem;
        margin: 0 0 0 0.25rem; padding: 0.25rem;
        display: flex; justify-content: center; align-items: center;
        img { max-height: 100%; filter: $low-colour-filter; }
        &:hover { background-color: $low-colour; img { filter: unset; }}
    }
}
