/*!
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

@use "scss/colours";


// Set the style of the container.
.container {
    // Display the elements in a column.
    display: grid; grid-template: 3fr / 1fr;
    justify-items: center; align-items: center;
    gap: 1rem; padding: 2rem 0;

    // Set the style of the text.
    font-size: 1.25rem; font-style: italic;
    text-align: center;
    @include colours.themed {
        color: colours.t("text");
        background-color: colours.t("page-background");
    }
}

// Set the style of the icon.
.icon { @include colours.themed { color: colours.t("primary"); } }
