/*!
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

@use "scss/colours";
@use "scss/shadows";


/** Set the style of the level select. */
.level-select {
    // Clear the default styling.
    all: unset;

    // Set the style of the container.
    &__container {
        position: relative; padding: 0;

        // Center in the parent.
        margin: 0.5rem auto 1rem;

        // Display the select in the center.
        display: flex; flex-direction: column;
        justify-content: center; align-items: center;

        // Set the style of the container.
        border-radius: 1rem;

        // Set the colour of the items.
        @include colours.themed {
            color: colours.t("primary");
            border: solid 1px colours.t("primary");
        }
    }

    // Center the text.
    display: flex; justify-content: center; align-items: center;

    // Set the style of the select.
    height: 2rem; cursor: pointer;
    padding: 0 4rem 0 1rem;
}

/** Set the style of the level icon. */
.level {
    pointer-events: none;
    position: absolute; top: 0.25rem; right: 1.75rem;
    height: 1.5rem; width: 1.5rem;
}

/** Set the style of the chevron. */
.chevron {
    pointer-events: none;
    position: absolute; top: 0.5rem; right: 0.5rem;
    height: 1rem; width: 1rem;
}
