/*!
 * Copyright © 2021-2022 - Zimproov.
 * All rights reserved.
 */

@use "scss/colours";


/** Set the style of the loader icon. */
.loader {
    // Fill the parent.
    height: 80%; max-height: 12rem; align-self: center;

    // Rotate the icon.
    @keyframes rotate {
        from { transform: rotateZ(180deg); }
        to   { transform: rotateZ(540deg); }
    }
    animation: rotate 1s linear infinite;

    // Set the style of the spinner.
    @include colours.themed { color: colours.t("primary"); }

    // Set the style of the container.
    &__container {
        // Display the loader in the center.
        display: flex; flex-direction: column;
        justify-content: center; align-items: center;
        gap: 1rem;

        // Add a small amount of padding.
        padding: 1rem;

        // Set the colour of the background.
        &:not(&--transparent) {
            @include colours.themed { background-color: colours.t("background")}
        }
    }

    // Set the style of the text.
    &__text {
        // Center in the parent.
        flex: 0 0 auto; align-self: center;

        // Clear the defaults.
        margin: 0; padding: 0;

        // Set the style of the text.
        font-size: 1.5rem; line-height: 1; text-align: center;
        @include colours.themed { color: colours.t("primary"); }
    }
}
