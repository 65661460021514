/*!
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import the colours.
@import "css/colours";


/** Set the style of the input. */
.input {
    position: relative;

    // Show on top of most other elements.
    z-index: 200;

    // Set the size of the overall container.
    flex: 0 0 auto;

    // Display the contents in the center.
    display: flex; flex-direction: row;
    justify-content: center; align-items: stretch;
    flex-wrap: nowrap;

    /** Set the style of the container. */
    &__container {
        // Fill the parent.
        flex: 1 1 0;

        // Display the contents in a row.
        display: flex; flex-direction: row;
        justify-content: flex-start; align-items: center;

        // Show in front of the list.
        z-index: 1;

        // Change the colour of the background.
        background-color: white;

        // Add a rounded border.
        border-radius: 2rem;
        box-shadow: 0.25rem 0.25rem 0.5rem $tertiary-colour;

        // Add some inside padding.
        padding: 0.5rem 1.5rem 0.5rem 0.75rem;

        /** Set the style of the input. */
        input {
            // Fill the parent.
            flex: 1 1 0; width: 100%;

            // Replace the default border.
            border: none;
            border-bottom: solid 1px $blue;
            border-radius: 0;

            // Remove the outline.
            outline: none; &:focus { outline: none; }
        }
    }

    /** Set the style of the magnifying glass icon. */
    &__glass {
        // Set the size of the icon.
        max-height: 100%; width: 1.5rem;

        // Add the blue filter.
        filter: $blue-filter;

        // Add a margin to the right.
        margin-right: 0.5rem;
    }
}

