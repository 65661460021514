/*!
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

// Import the colours.
@use "scss/colours";

/** Set the style of the preparation interface. */
.preparation {
    // Fill the parent.
    height: 100%; width: 100%;

    // Display the contents in the center.
    display: flex; flex-direction: column;
    justify-content: center; align-items: center;
    gap: 1rem;
}

// Set the style of the individual option.
.option {
    // Set the size of the button.
    height: 2.5rem; width: 2.5rem;

    // Display the icon in the center.
    display: flex; justify-content: center; align-items: center;

    // Set the style of the background.
    border-radius: 2rem;
    @include colours.themed {
        background-color: colours.t("background");
        border: solid 1px colours.t("primary");

        // Change the colour when active.
        &:active {
            background-color: colours.t("primary");
        }
    }

    // Set the style of the icon.
    @include colours.themed {
        color: colours.t("primary");

        // Change the colour when inactive.
        &[aria-pressed="false"] {
            color: colours.t("background-dark");
        }

        // Change the colour when active.
        &:active {
            color: colours.t("text-light");
        }
    }

    // Set the size of the active icon.
    &__icon--active { width: 1.25rem; height: 1.25rem; }
    // Set the size of the inactive icon.
    &__icon--inactive { width: 1.5rem; height: 1.5rem; }

    // Disable the icons by default.
    &__icon { display: none; }
    // Show the active icon when enabled.
    &[aria-pressed="true"] &__icon--active { display: block; }
    // Show the inactive icon when disabled.
    &[aria-pressed="false"] &__icon--inactive { display: block; }
}

// Set the style of the button.
.button {
    // Display the contents in a row.
    display: flex; flex-direction: row;
    justify-content: flex-start; align-items: center;
    gap: 0.5rem; padding: 0.25rem 1rem;

    // Set the style of the button.
    border-radius: 1.5rem;
    @include colours.themed {
        background-color: colours.t("background");
        border: solid 1px colours.t("primary");

        // Change the colour of the background when active.
        &:active { background-color: colours.t("primary"); }
    }

    // Set the style of the text.
    margin: 0; font-size: 1.25rem;
    @include colours.themed {
        color: colours.t("primary");

        // Change the colour of the background when active.
        &:active { color: colours.t("text-light"); }
    }

    /** Set the style of the icon. */
    img { height: 1.5rem; }

    // Add a transition to the colours.
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out;
}

/** Set the style of the loader. */
.loader {
    // Render the elements in white.
    @include colours.themed {
        p, svg { color: colours.t("text-light"); }
    }
}
