/*!
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import the app colours.
@use "css/colours";
// Import the app shadows.
@use "css/shadows";


/** Set the style of the page. */
.zaq-training-progress {
    /** Set the style of the title. */
    &__title {
        // Set the style of the text.
        font-size: 2rem; border-bottom: solid 2px colours.$blue;
        margin: 0; padding: 0; color: colours.$blue;
    }

    /** Set the style of the progress body. */
    &__body {
        // Display the contents in a column.
        display: flex; flex-direction: column;
        justify-content: flex-start; align-items: stretch;

        // Set the style of the container.
        background-color: white; border-radius: 0.75rem;
        padding: 0; box-shadow: 0.25rem 0.25rem 0.5rem colours.$tertiary-colour;

        /** Set the style of the title section. */
        &__title {
            // Display the contents in a row.
            display: flex; flex-direction: row;
            justify-content: flex-start; align-items: center;

            // Set the style of the box.
            padding: 0.5rem 1.5rem 0.25rem;
            box-shadow: 0 0.25rem 0.25rem -0.125rem transparentize(colours.$tertiary-colour, 0.5);

            /** Set the style of the items. */
            &__item {
                margin: 0; padding: 0;
                display: flex; flex-direction: row; justify-content: flex-start; align-items: center; gap: 0.5rem;
                &__arrow { height: 1rem; transform: scaleX(-1); margin: 0; }
            }
            /** Set the style of the text. */
            &__text { margin: 0; color: colours.$blue-dark; font-size: 1.25rem; line-height: 1; }
        }

        /** Set the style of the search bar. */
        &__search-bar {
            margin: 0.5rem 1rem;
        }

        /** Set the style of the list. */
        &__list {
            // Display the contents in a column.
            display: flex; flex-direction: column;
            justify-content: flex-start; align-items: stretch;
            overflow: hidden auto;

            // Set the style of the list.
            list-style: none; padding: 0.5rem 2rem; margin: 0;
            max-height: 50vh;

            /** Set the style of the items. */
            &__item {
                // Display the contents in a row.
                display: flex; flex-direction: row;
                justify-content: flex-start; align-items: flex-end;

                // Set the style of the box.
                border-bottom: solid 2px colours.$blue;
                padding: 0 0.25rem; margin: 0.25rem 0;
                height: 2.5rem;

                a, a:hover, a:active, a:focus {
                    font-weight: 500;
                    color: colours.$blue; text-decoration: none;
                }
            }
        }
    }

}

