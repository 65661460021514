/*!
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

@use "sass:color";
@use "scss/colours";
@use "scss/shadows";

/** Set the style of the administration button. */
.admin-button {
    // Set the style of the button.
    border-radius: 1rem;
    padding: 0.25rem 0.625rem; margin: 0;
    &__icon { width: 1.25rem; height: 1.25rem; }

    &__text {
        font-size: 0.75rem; line-height: 1;
    }

    // Display the image in the center.
    display: flex; justify-content: center; align-items: center;
    gap: 0.5rem;

    // Set the colours of the button.
    @include colours.themed {
        border: solid 1px colours.t("primary");
        background-color: colours.t("background");
        color: colours.t("primary");
    }
    &:hover {
        @include colours.themed {
            background-color: colours.t("primary");
            color: colours.t("background");
        }
    }
}

