/*!
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

@use "scss/colours";
@use "scss/shadows";


/** Set the style of the menu. */
.menu {
    // Clear the default padding.
    padding: 0; overflow: hidden;

    // Set the style of the background.
    border-radius: 0.5rem;
    @include colours.themed {
        background-color: colours.t("background");
    }
    @include shadows.distance;


    // Hide the disabled items.
    [data-disabled="true"] { display: none; }
}

/** Set the style of the dropdown buttons. */
.button {
    // Display the contents in a row.
    display: flex; flex-direction: row;
    justify-content: flex-start; align-items: center;
    gap: 0.5rem; padding: 0.125rem 0.5rem;

    // Set the style of the inner text.
    @include colours.themed {
        color: colours.t("primary");

        // Change the colours when hovered.
        &:hover {
            color: colours.t("text-light");
            background-color: colours.t("primary");
        }

        // Change the colours when active.
        &:active {
            color: colours.t("text-light");
            background-color: colours.t("secondary");
        }
    }
}

/** Override the margin of the divider. */
.divider { margin: 0.25rem 0; }
