/*!
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

@use "scss/colours";
@use "scss/shadows";


/** Set the style of the modal. */
.modal {
    &__content {
        border: none; border-radius: 1rem;
        @include shadows.distance;
    }
}

/** Set the style of the header. */
.header {
    // Display the contents in a row.
    display: grid; grid-template-columns: 1fr 1.5rem;
    padding: 0.5rem;

    // Remove the default border.
    border: none;
}

/** Set the style of the body. */
.body {
    // Display the contents in a grid.
    display: grid; grid-template-columns: auto 1fr;
    grid-gap: 0.5rem;

    @include shadows.top;
}

/** Set the style of the title. */
.title {
    // Set the style of the text.
    font-size: 1.5rem; padding-left: 1rem;
}

/** Set the style of the close button. */
.button {
    // Display the icon in the center.
    display: flex; justify-content: center; align-items: center;
    height: 1.5rem;

    // Set the style of the box.
    border-radius: 1rem; padding: 0.25rem;
    @include colours.themed {
        background-color: colours.t("background");
        color: colours.t("danger");
        border: solid 1px colours.t("danger");
        &:hover {
            background-color: colours.t("danger");
            color: colours.t("background");
        }
    }
}

/** Set the style of the canvas. */
.canvas { display: none; }
