/*!
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

@use "../../../../../../../assets/src/scss/colours";


/** Set the style of the container. */
.container {
    // Display the elements in a row.
    display: flex; flex-direction: row;
    justify-content: flex-start; align-items: stretch;
    gap: 0; overflow: auto hidden;
}

/** Set the style of the tabs. */
.tab {
    // Clear the default styling.
    all: unset;

    // Fill the parent.
    flex: 1 0 auto;

    // Set the style of the text.
    text-align: center; font-weight: 300;

    // Set the style of the box.
    @include colours.themed {
        background-color: transparent;
        color: colours.t("primary");
        border-bottom: solid 1px colours.t("background-dark");
        &:disabled {
            border-bottom-color: colours.t("secondary");
            color: colours.t("secondary");
            font-weight: 500;
        }
        &:hover:not(:disabled) {
            background-color: colours.t("primary");
            color: colours.t("background");
        }
    }

    // Add a left border to separate the tabs.
    @include colours.themed {
        &:not(:first-child) {
            border-left: solid 1px colours.t("background-dark");
        }
    }
}
