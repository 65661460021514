/*!
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

@use "scss/colours";


/** Set the style of the loader. */
.loader { height: 100%; }

/** Set the style of the error container. */
.error {
    // Fill the screen.
    height: 100%;

    // Display the contents in a column.
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr auto auto 1fr auto;
    justify-items: center; align-items: center;
    grid-template-areas: "." "icon" "title" "." "link";
    gap: 2rem;
}

/** Set the style of the icon. */
.icon {
    grid-area: icon;

    // Set the size of the icon.
    height: min(25vh, 3rem);

    // Set the style of the icon.
    @include colours.themed {
        color: colours.t("secondary");
    }
}

/** Set the style of the title. */
.title {
    grid-area: title;

    // Set the style of the text.
    margin: 0; font-size: 3rem;
    @include colours.themed {
        color: colours.t("secondary");
    }
}

/** Set the style of the powered-by link. */
.link {
    grid-area: link;

    // Align at the end of the parent.
    justify-self: end;

    // Display the contents in a row.
    display: flex; flex-direction: row;
    justify-content: center; align-items: flex-end;
    margin: .5rem 1rem; gap: .5rem;

    // Set the style of the text.
    font-size: 1.5rem; text-decoration: none;
    @include colours.themed {
        color: colours.t("text");

        &:hover, &:active { color: colours.t("primary"); }
    }
}

/** Set the style of the ZaqTiv icon. */
.zaqtiv {
    // Set the size of the icon.
    height: 2.5rem; margin-bottom: 1px;

    // Hide the regular icon by default.
    display: none;

    // Show the gray icon when not hovered.
    .link:not(:hover) &--gray { display: block; }

    // Show the coloured icon when hovered.
    .link:hover &:not(&--gray) { display: block; }
}
