/*!
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

@use "sass:color";
@use "scss/colours";


/** Set the style of the navigation bar. */
.nav {
    // Display the contents in a row.
    display: flex; flex-direction: row;
    justify-content: flex-start; align-items: center;
    flex-wrap: wrap; gap: 0.5rem 0.5rem;
}

/** Set the style of the link. */
.link {
    cursor: pointer;

    // Display the contents in a row.
    display: flex; flex-direction: row;
    justify-content: flex-start; align-items: center;

    // Remove the underline.
    text-decoration: unset;

    // Make the text not selectable.
    user-select: none;

    // Set the colour of the items.
    @include colours.themed {
        color: colours.t("primary");
    }

    /** Set the style of the icon. */
    &__chevron { height: 1.5rem; }

    /** Set the style of the text. */
    &__text {
        // Set the style of the text.
        text-align: center; font-weight: bold;
        font-size: 1.5rem; white-space: nowrap;

        // Remove the default margin.
        margin-bottom: 0;
    }

    // Change the style when hovered.
    &:hover {
        @include colours.themed {
            color: colours.t("secondary");
        }
    }

    // Override the colour of the title.
    h1 { color: inherit; }
}
