/*!
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import the colours.
@use "css/colours";

/** Set the style of the file header. */
.header {
    // Add some inside padding.
    padding: 0.5rem;

    // Render the content in a row.
    display: flex; flex-direction: row;
    justify-content: flex-start; align-items: center;
    overflow: hidden; cursor: pointer; gap: 0.25rem;

    /** Set the style of the text. */
    &__text {
        // Remove the default styling.
        &:active, &:focus, &:hover, & { background-color: unset; outline: none; box-shadow: none; border: none; }
        min-width: 0;

        // Fill the parent.
        flex: 1 1 0;

        // Use ellipses for titles that are too long.
        overflow: hidden;
        white-space: break-spaces;
        text-overflow: clip;

        // Remove the default margin.
        margin: 0 0 0 0.5rem;

        // Change the style of the font.
        color: colours.$blue-dark; font-size: 1rem;
        &--invalid { color: colours.$low-colour; }
        &::placeholder { font-style: italic; }
    }

    // Set the colour of the background.
    background-color: white;

    // Change the grabbed background colour.
    :global .draggable-source--is-dragging :local & { background-color: colours.$high-colour; }

    /** Set the style of the link header. */
    &:is(a) {
        // Set the cursor.
        cursor: pointer;

        // Remove the underline.
        text-decoration: unset;

        // Remove the default style of the button.
        border: unset;
        &:hover { border: unset; outline: unset; }
        &:focus { border: unset; outline: unset; }
        &:active { border: unset; outline: unset; }

        // Add a background colour when hovered.
        &:hover { background-color: colours.$blue-dark; }
        body:is(:global .draggable--is-dragging) :local & { background-color: colours.$secondary-colour; cursor: unset; }
        &.header--editor { background-color: colours.$secondary-colour; cursor: unset; }
    }

    /** Set the style of the icons. */
    &__grab-handle, &__chevron, &__bin, &__icon {
        // Hide the icon by default.
        display: none;
        justify-content: center; align-items: center;
        overflow: hidden;

        // Set the size of the icon.
        height: 1.5rem;

        // Change the cursor.
        cursor: pointer;
    }

    &:is(a):hover &__text { color: white; }
    &:is(a):hover &__icon { filter: unset; }

    /** Set the style of the grab handle. */
    &__grab-handle {
        // Hide by default.
        display: block; visibility: hidden;

        // Change the cursor.
        cursor: grab;

        // Rotate the element.
        transform: rotateZ(90deg);

        // Set the size of the element.
        width: 1.25rem; padding: 0.25rem 0;

        // Add a colour filter to the element.
        //filter: colours.$tertiary-colour-filter;
    }
    &--editor &__grab-handle--visible { visibility: visible; }
    &--editor:hover &__grab-handle { visibility: visible; }

    /** Set the style of the chevron. */
    &__chevron {
        width: 1rem; max-height: 1rem;
        margin: 0 0.5rem 0 0;
        display: flex;
        // Transition the rotation.
        transition: transform 0.3s;
        transform: rotateZ(0deg);
        &--hidden { visibility: hidden; pointer-events: none; }
        &--hidden { visibility: hidden; pointer-events: none; }
        color: colours.$blue-dark;
    }
    /** Set the style of the retracted chevron. */
    &--retracted &__chevron {
        transform: rotateZ(90deg);
        transition: transform 0.3s;
    }

    /** Set the style of the bin. */
    &__bin {
        // Display the image in the center.
        display: flex; justify-content: center; align-items: center;
        width: 3rem; height: 2rem;
        border-radius: 1rem; padding: 0.25rem;
        img { max-width: 100%; height: 100%; }

        &:hover {
            img { filter: brightness(0) invert(1); }
            background-color: colours.$low-colour;
        }
    }
    /** Set the style of the icon. */
    &__icon { display: flex; width: 2rem; cursor: unset; color: colours.$primary-colour; }
    &__convert-icon { margin: 0 0.5rem; }

    /** Change the style of the editable header. */
    &--editor {
        // Hide all the icons.
        & .header__grab-handle { display: flex; }
        & .header__chevron { display: flex; }
        & .header__bin { display: flex; }
    }
}

/** Set the style of the edit button. */
.edit-button {
    // Place at the top-right of the page.
    position: fixed; top: 4rem; right: 1rem;

    // Set the style of the icon.
    &__icon {
        height: 1rem; width: 1rem;
    }
}
