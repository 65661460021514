/*!
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

@use "scss/colours";
@use "scss/mixins";


/** Set the style of the title module. */
.title {
    // Fill the parent.
    height: 100%;

    // Add some padding.
    padding-left: 3rem; padding-right: 3rem;
    padding-top: 2rem;
    @media (max-height: 500px) { padding-top: 2rem; }

    // Display the contents in a column.
    display: flex; flex-direction: column;
    justify-content: space-between; align-items: stretch;
    overflow: hidden auto; gap: 1rem;

    /** Set the style of the ZaqWiki title. */
    &__zaq-wiki {
        flex: 0 0 auto;

        // Set the width of the element.
        align-self: center; width: 33vw; min-width: 12rem;

        // Hide on very narrow screens.
        @media (max-height: 400px) { display: none; }

        // Display the contents in a column.
        display: flex; flex-direction: column;
        justify-content: center; align-items: stretch;

        // Set the style of the text.
        &__text { flex: 0 0 auto; text-align: center; font-size: 1.5rem; margin-bottom: 0.5rem; }

        // Add a bar at the botton.
        &::after { content: ""; width: 100%; height: 2px; @include colours.themed { background: colours.t("text"); }}
    }

    /** Set the style of the title. */
    &__title {
        // Fit inside the parent.
        max-width: 100%; flex: 0 0 auto;

        // Set the style of the text.
        @include colours.themed {
            color: colours.t("secondary");
        }
        font-size: 2.5rem;
        text-align: center; margin: 0; text-overflow: ellipsis;
        white-space: break-spaces; overflow: hidden;

        /** Set the style of the container. */
        &__container {
            flex: 0 0 auto;

            // Display the contents in a column.
            display: flex; flex-direction: column;
            justify-content: center; align-items: center;
            gap: 0.5rem; overflow: hidden; flex-wrap: nowrap;
        }
    }

    /** Set the style of the description. */
    &__description {
        flex: 0 0 auto;

        // Set the style of the text.
        @include colours.themed { color: colours.t("text"); }
        font-size: 1.25rem;
        text-align: center; margin: 0;
    }

    /** Set the style of the icons. */
    &__icon {
        // Set the size of the icon.
        height: 2rem; width: auto; max-width: 100%;
        object-fit: contain;

        /** Set the style of the container. */
        &__container {
            // Set the size of the element.
            flex: 0 0 auto;

            // Display the elements in a row.
            display: flex; flex-direction: row;
            justify-content: space-between; align-items: flex-end;
            overflow: hidden; flex-wrap: wrap; gap: 1rem;

            // Add a bit of margin for the navigation controller.
            margin: 0 1.5rem 1rem;
        }
    }

    /** Set the style of the powered-by logo. */
    &__powered-by {
        // Display the contents in a row.
        display: flex; flex-direction: row;
        justify-content: center; align-items: flex-end;
        gap: 0 0.25rem; overflow: hidden; flex-wrap: wrap;
        flex: 0 0 200px; max-width: 100%;

        // Remove the underline.
        text-decoration: none;

        // Set the style of the icon.
        &__icon { flex: 0 0 auto; height: 2rem; filter: brightness(0) invert(1); }
        // Set the style of the text.
        &__text {
            flex: 0 0 auto; margin: 0;
            text-align: center; color: white;
            white-space: nowrap; font-size: 1.125rem;
        }

        // Change the hovered style.
        &:hover &__icon { filter: unset; }
        &:hover &__text { @include colours.themed { color: colours.t("primary"); }}
    }
}
