/*!
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

@use "./index.module.scss";
@use "scss/colours";
@use "scss/mixins";

/** Set the style of the pdf module. */
.pdf {
    @extend .module;

    &--fullscreen { z-index: 1; }

    // Make sure that the element does not grow bigger than the parent.
    position: absolute; top: 0; left: 0; right: 0; bottom: 2rem;

    // Display the contents in the center.
    display: grid; grid-template-rows: auto 1fr;
    justify-items: stretch; align-items: stretch;
    overflow: hidden auto; grid-row-gap: 1rem;
    padding: 1rem;
    pointer-events: all; cursor: pointer;

    // Set the style of the title.
    &__title {
        @extend .title; align-self: center;
    }

    // Set the style of the controller.
    &__toolbar {
        @include mixins.media-breakpoint-down(md) {
            bottom: 2.5rem;
        }
    }

    // Set the style of the document.
    &__document { overflow: auto; }
}
