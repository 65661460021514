/*!
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

@use "scss/colours";
@use "scss/shadows";

/** Set the style of the box header.  */
.box {
    // Display the contents in a column.
    display: flex; flex-direction: column;
    justify-content: flex-start; align-items: stretch;
    flex-wrap: nowrap; overflow: hidden; gap: 0;

    // Set the style of the box.
    border: none;

    // Set the background and shadows.
    @include colours.themed {
        background-color: colours.t("background");
    }

    // Set the style of the box body.
    &__body {
        // Generate an inset shadow at the top.
        @include shadows.top();
    }
}
