/*!
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

@use "scss/colours";
@use "scss/mixins";
@use "sass:color";


/** Set the style of the container. */
.container {
    // Fill the screen.
    position: fixed; inset: 0;
    z-index: 2000;

    // Display the contents in a column.
    display: flex; flex-direction: column-reverse;
    justify-content: flex-start; align-items: flex-end;
    padding: 2rem 1.25rem;

    // Change the offset on mobile.
    @include mixins.media-breakpoint-down(sm) {
        padding: 3.5rem 1.5rem;
    }

    // Disable pointer events when closed.
    pointer-events: none;

    // Add a backdrop when open.
    &--open {
        // Add a transparent backdrop.
        @include colours.themed {
            background-color: color.change(colours.t("text"), $alpha: 0.5);
        }
        backdrop-filter: blur(4px); -webkit-backdrop-filter: blur(4px);

        // Enable pointer events.
        pointer-events: all;
    }

    // Add a transition for the background effects.
    transition: background-color 0.25s ease-in-out,
    backdrop-filter 0.25s ease-in-out,
    -webkit-backdrop-filter 0.25s ease-in-out;
}

/** Set the style of the open/close button. */
.button {
    // Clear the default styling.
    all: unset;

    // Enable pointer events.
    pointer-events: all;

    // Display the icon in the center.
    display: flex; flex-direction: row;
    justify-content: center; align-items: center;
    gap: 0.5rem; padding: 0.5rem;

    // Set the style of the text.
    &__text { line-height: 1; font-size: 1.25rem; }

    // Set the style of the icon.
    &__icon { width: 1.25rem; height: 1.25rem; }

    // Set the style of the button.
    border-radius: 1.5rem;
    @include colours.themed {
        border: solid 1px colours.t("primary");
        background: colours.t("background");
        color: colours.t("primary");
    }

    // Change the style when opened.
    .container--open & {
        // Change the colour of the elements.
        @include colours.themed {
            color: colours.t("secondary");
            border-color: colours.t("secondary");
        }

        // Rotate the icon.
        &__icon { rotate: 45deg; }
    }

    // Add a transition for the colour and rotation.
    transition: border-color 0.25s linear, color 0.25s linear, box-shadow 0.25s linear;
    &__icon { transition: rotate 0.25s ease-in-out; }
}

/** Set the style of the list. */
.list {
    // Clear the default styling.
    all: unset;

    // Place at a fixed position on the screen.
    position: fixed; bottom: 5rem; right: -100%;

    // Change the offset on mobile.
    @include mixins.media-breakpoint-down(sm) {
        bottom: 6.5rem;
    }

    // Display the elements in a column.
    display: flex; flex-direction: column;
    justify-content: flex-start; align-items: stretch;
    gap: 0; overflow: hidden;

    // Set the style of the list.
    border-radius: 0.5rem;
    @include colours.themed {
        background-color: colours.t("background");
        background-image: repeating-linear-gradient(
                to bottom,
                colours.t("background") 0,
                colours.t("background") calc(2rem - .5px),
                colours.t("background-dark") calc(2rem - .5px),
                colours.t("background-dark") calc(2rem + .5px),
                colours.t("background") calc(2rem + .5px)
        );
        background-repeat: repeat-y;
        background-size: 75% 100%;
        background-position-x: 50%;
    }

    // Move in view when the container is open.
    .container--open & { right: 1rem; }

    // Add a transition to reveal the menu.
    transition: right 0.25s ease-in-out;
}

/** Set the style of the list items. */
.item {
    flex: 0 0 2rem; align-self: stretch;

    // Display the contents in a row.
    display: flex; flex-direction: row;
    justify-content: flex-start; align-items: center;
    gap: 0.5rem; padding: 0.25rem 1rem; flex-wrap: nowrap;

    // Set the style of the text.
    @include colours.themed {
        color: colours.t("primary");
    }

    // Change the active/hover styles.
    &:hover, &:active {
        @include colours.themed {
            color: colours.t("text-light");
            background-color: colours.t("primary");
        }
    }

    // Hide when disabled.
    &:disabled { display: none; }
}

/** Set the style of the loader. */
.loader { position: absolute; inset: 0; z-index: 2000; }
