/*!
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

@use "scss/colours";
@use "scss/shadows";
@use "scss/mixins";


/** Set the style of the loader. */
.loader {
    // Fill all the available space.
    flex: 1 1 0;
}

/** Set the style of the result list. */
.list {
    // Clear the default styling.
    all: unset;

    // Display the contents in a column.
    display: flex; flex-direction: column;
    justify-content: flex-start; align-items: stretch;
    gap: 0.25rem; padding: 0.5rem 0.25rem 6rem;

    @include mixins.media-breakpoint-down(sm) {
        padding: 0.5rem 0 6rem;
    }
}

/** Set the style of the item container. */
.item-container {
    // Clear the default styling.
    all: unset;

    // Take as much space as needed.
    flex: 0 0 auto;

    // Set the style of the element.
    @include colours.themed {
        background-color: colours.t("background");
    }
    @include shadows.distance($distance: 0.125rem);
}
