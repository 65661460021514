/*!
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

@use "sass:color";
@use "scss/colours";
@use "scss/shadows";

/** Set the style of the modal. */
.admin__content { border: unset; border-radius: 1rem; padding: 0; }

/** Set the style of the header. */
.header {
    // Set the style of the box.
    border: unset; padding: 0.5rem 0.5rem;

    // Display the contents in a row.
    display: flex; flex-direction: row;
    justify-content: space-between; align-items: center;
}

/** Set the style of the title. */
.title {
    // Add a bit of padding to the left.
    padding-left: 0.5rem;

    // Set the style of the text.
    font-size: 1.5rem; font-weight: normal;
    @include colours.themed {
        color: colours.t("secondary");
    }
}

/** Set the style of the button. */
.close-button {
    // Set the size of the box.
    width: 2rem; height: 2rem;

    // Display the icon in the center.
    display: flex; justify-content: center; align-items: center;
    &__icon { height: 1.25rem; }

    // Set the style of the box.
    border-radius: 100%; padding: 0;
    @include shadows.distance($distance: 0.125rem);

    // Set the colours of the box.
    @include colours.themed {
        border: solid 2px colours.t("danger");
        background: colours.t("background");
        color: colours.t("danger");
    }
    &:hover {
        @include colours.themed {
            background: colours.t("danger");
            color: colours.t("background");
        }
    }
    &:focus {
        @include colours.themed {
            border-color: color.scale(colours.t("danger"), $lightness: -50%);
        }
    }
    &:active {
        @include colours.themed {
            background-color: color.scale(colours.t("danger"), $lightness: -50%);
        }
    }
}

/** Set the style of the body. */
.body {
    // Display the elements in a column.
    display: flex; flex-direction: column;
    justify-content: flex-start; align-items: stretch;
    gap: 1rem;

    @include shadows.top($distance: 0.5rem);

    // Set the margin/padding.
    margin: 0;
    padding: 0.5rem 1rem;
}
