/*!
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

@use "scss/colours";
@use "scss/mixins";


/** Set the style of the button. */
.button {
    // Take as much space as needed and not any more.
    flex: 0 0 auto;

    // Display the contents in a row.
    display: flex; flex-direction: row;
    justify-content: flex-start; align-items: center;
    gap: 0.5rem; padding: 0.5rem;

    // Add additional padding when the text is visible.
    @include mixins.media-breakpoint-up(sm) {
        padding: 0.25rem 0.5rem;
    }

    // Set the style of the background.
    border-radius: 1rem;
    @include colours.themed {
        color: colours.t("primary");
        border: solid 1px colours.t("primary");

    }
    // Change the colours when open.
    :global(.show) & {
        @include colours.themed {
            color: colours.t("text-light");
            background-color: colours.t("primary");
        }
    }

    // Remove the down arrow.
    &::after { content: unset; }

    // Disable user selection.
    user-select: none; cursor: pointer;
}

/** Set the style of the username. */
.username {
    // Remove all margins.
    padding: 0; margin: 0;

    // Set the style of the text.
    font-size: 1rem;

    // Hide on very small devices.
    @include mixins.media-breakpoint-down(sm) { display: none; }
}

/** Set the style of the icon. */
.icon {
    // Set the size of the icon.
    height: 1rem; width: 1rem;

    // Disable text selection on the icon.
    user-select: none; -webkit-user-select: none;
}
