/*!
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

@use "scss/mixins";


/** Map of all the available sizes. */
$sizes: (
    /** Default sizes. */
    default: (
        /** Size allocated to the title column. */
        title: 1fr,
        /** Size allocated to the due-date column. */
        due-date: 8rem,
        /** Size allocated to the expected level column. */
        expected-level: 6rem,
        /** Size allocated to the achieved level column. */
        achieved-level: 6rem,
        /** Size allocated to the status column. */
        status: 3rem,
    ),
    /** Sizes for a small display. */
    sm: (
        /** Size allocated to the title column. */
        title: 1fr,
        /** Size allocated to the due-date column. */
        due-date: 3.5rem,
        /** Size allocated to the expected level column. */
        expected-level: 2.5rem,
        /** Size allocated to the achieved level column. */
        achieved-level: 2.5rem,
        /** Size allocated to the status column. */
        status: 2rem,
    )
);

@function sizeof($item, $size: "default") {
    @return map-get(map-get($sizes, $size), $item);
}
