/*!
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import the colours.
@import "../../../../../assets/src/css/colours";

/** Set the style of the notice category. */
.category {
    // Add a top separator.
    &:not(:first-of-type) { border-top: solid 1px $tertiary-colour; }

    /** Set the style of the title. */
    &__title {
        // Display the contents in a row.
        display: flex; flex-direction: row;
        justify-content: flex-start; align-items: center;
        overflow: hidden; flex-wrap: nowrap;

        /** Set the style of the text. */
        p {
            // Align the text at the start.
            text-align: start;

            // Add some start padding.
            padding-left: 0.5rem;

            // Use ellipses if the text is too long.
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;

            // Fill the parent.
            flex: 1 1 0;

            // Set the style of the font.
            color: $secondary-colour;
            font-size: 1.25rem;
            margin: 0;
        }
    }

    /** Set the style of the button. */
    &__expand {
        // Fill the parent.
        flex: 1 1 0;

        // Remove the default styling.
        &:hover, &:active, &:focus, & { outline: unset; border: none; box-shadow: none; }
        background-color: unset;

        // Display the contents in a row.
        display: flex; flex-direction: row;
        justify-content: flex-start; align-items: center;
        overflow: hidden; flex-wrap: nowrap;

        // Set the size of the button.
        img { width: 1rem; height: 1rem; }

        // Set the filter of the image.
        img { filter: $primary-colour-filter; transform: rotate(-90deg); }

        // Change the style when hovered.
        &:hover {
            background-color: $primary-colour;
            p { color: white; }
            img { filter: unset; }
        }

        /** Set the style of the expanded list. */
        &--expanded img { transform: rotate(90deg); }
    }

    /** Set the style of the lists. */
    &__list {
        // Set the margin/padding.
        margin: 0; padding: 0;

        // Remove the list styling.
        list-style: none;

        // Set the style of the text.
        font-size: 1rem; color: $primary-colour;

        /** Set the style of the items. */
        &__item {
            // Add some inside padding.
            padding: 0.125rem 0.25rem 0.125rem 1rem;

            // Define the cursor type.
            cursor: pointer;
            // Change the hovered style.
            &:hover { background-color: $blue; color: white; }

            // Change the selected style.
            &--selected { background-color: $blue-dark; color: white; }
        }
    }
}
