/*!
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

@use "scss/colours";
@use "scss/shadows";
@use "scss/mixins";


/** Set the style of the widget. */
.widget { flex: 0 0 100%; }

/** Set the style of the list. */
.list {
    // Clear the default styling.
    all: unset;

    // Display the contents in a grid.
    display: grid; grid-template: auto / repeat(3, 1fr);
    align-items: stretch; justify-items: stretch;
    gap: 0.25rem; padding: 0.5rem 0.25rem;

    // Change the arrangement on smaller devices.
    @include mixins.media-breakpoint-down(xl) { grid-template: auto / repeat(2, 1fr); }
    @include mixins.media-breakpoint-down(lg) { grid-template: auto / 1fr; }
}


/** Set the style of the item. */
.item {
    // Clear the default styling.
    all: unset;

    // Set the style of the element.
    @include colours.themed {
        background-color: colours.t("background");
    }

    // Hide when empty.
    &:empty { display: none; }
}
