/*!
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

@use "scss/colours";
@use "../../../table/helpers/common.module";


/** Set the style of the step header cells. */
.step-cell {
    @extend .head;

    // Set the style of the locked cell
    &--locked {
        @include colours.themed {
            color: colours.t("background-dark");
        }
    }
}

/** Set the style of the not applicable message. */
.not-applicable-message {
    // Add some margins.
    margin: 1rem auto;

    // Set the style of the text.
    text-align: center; font-size: 1.5rem;
    font-style: italic; text-decoration: underline;
    @include colours.themed {
        color: colours.t("danger");
    }
}
