/*!
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import the app colours.
@import "css/colours";

/** Set the style of the button. */
.user-creator {
    // Roundify the element.
    border-radius: 0.75rem;

    // Set the colour of the background.
    background-color: white;

    // Add a slight drop shadow.
    box-shadow: 0.25rem 0.25rem 0.5rem $tertiary-colour;

    // Display the contents in a column.
    display: flex; flex-direction: column;
    justify-content: space-between; align-items: stretch;
    overflow: hidden; flex-wrap: nowrap;

    /** Add a 20% margin around the body. */
    &__body { margin: 1rem auto; width: 80%; }
}
