/*!
 * Copyright © 2021-2022 - Zimproov.
 * All rights reserved.
 */

@use "scss/colours";

/** Set the style of the progress bar. */
.progress {
    // Fill the parent.
    flex: 0 0 20rem; height: 0.5rem; max-width: 100%;

    // Set the style of the bar.
    border-radius: 0.25rem;
    border: solid 1px;
    @include colours.themed { border-color: colours.t("primary"); }

    // Set the style of the container.
    &__container {
        // Fill the parent.
        flex: 0 0 auto; align-self: center; width: 100%; max-width: 29rem;

        // Display the elements in a row.
        display: flex; flex-direction: row;
        justify-content: center; align-items: center;
        gap: 0.5rem; flex-wrap: wrap;
    }

    // Set the style of the value.
    &__value {
        // Fill the parent.
        height: 100%;

        // Set the style of the box.
        border-radius: 0.25rem;
        @include colours.themed { background-color: colours.t("primary"); }
    }

    // Set the style of the progress text.
    &__text {
        // Take the space that is needed to render the text.
        flex: 0 0 auto;

        // Remove the default margins.
        margin: 0;

        // Set the style of the text.
        font-weight: bold; font-size: 0.75rem; text-align: center;
        @include colours.themed { color: colours.t("primary"); }

        // Hide the text used for spacing.
        &--spacer { visibility: hidden; }
    }
}
