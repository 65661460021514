/*!
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

@use "./colours";


@mixin with-scrollbar {
    // Set the style of the scrollbar.
    &::-webkit-scrollbar {
        // Set the width of the scrollbar background.
        border-radius: 0.5rem; width: 0.5rem;

        // Set the colour of the background.
        @include colours.themed {
            background-color: colours.t("page-background");
        }
    }

    // Set the style of the scrollbar thumb.
    &::-webkit-scrollbar-thumb {
        // Set the width of the scrollbar background.
        border-radius: 0.5rem;

        @include colours.themed {
            background-color: colours.t("background-dark");
            border: solid 1px colours.t("page-background");
        }
    }
}
