/*!
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

@use "scss/colours";


/** Set the style of the header. */
.header {
    // Override the default padding.
    padding: 0 1rem;

    // Set the style of the text.
    @include colours.themed { color: colours.t("text"); }
}

/** Set the style of the items. */
.item {
    // Display the elements in a row.
    display: flex; flex-direction: row;
    justify-content: flex-start; align-items: center;
    gap: 0.5rem; padding: 0 0.5rem;

    // Disable user selection.
    user-select: none; -webkit-user-select: none;
    cursor: pointer;

    // Set the style of the button.
    @include colours.themed {
        color: colours.t("primary");

        // Change the colours when hovered.
        &:hover, &[aria-disabled="true"] {
            color: colours.t("text-light");
            background-color: colours.t("primary");
        }

        // Change the colours when active.
        &:active:not(:disabled) {
            color: colours.t("text-light");
            background-color: colours.t("secondary");
        }
    }

    // Set the style of the icon.
    &__icon {
        // Set the size of the icon.
        height: 1rem; width: 1rem;

        // Hide by default.
        visibility: hidden;

        // Show when the item is disabled.
        .item[aria-disabled="true"] & { visibility: visible; }
    }

    // Remove the margin from the text.
    &__name { margin: 0; }
}
