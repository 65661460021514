/*!
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

@use "scss/colours";
@use "scss/shadows";
@use "scss/mixins";

@import "css/colours";
@import "css/shadows";


/** Set the style of the header. */
.header {
    // Fill the parent.
    flex: 1 1 0;
    border-bottom: solid 2px $blue;

    // Display the contents in a row.
    display: flex; flex-direction: row;
    justify-content: flex-start; align-items: center;
}

/** Set the style of the input. */
.input {
    // Clear the default styling.
    all: unset;

    // Fill the parent.
    flex: 1 1 0; width: 0;

    // Set the style of the text.
    font-size: 2rem; line-height: 1; font-weight: normal;
    @include colours.themed {
        color: colours.t("primary");
    }

    // Change the style of the text when active.
    &:focus, &:active {
        font-style: italic;
        @include colours.themed {
            border: solid 2px colours.t("secondary");
        }
    }

    // Change the style of the placeholder.
    &::placeholder {
        font-style: normal; font-weight: normal;
        @include colours.themed { color: colours.t("danger"); }
    }
}

/** Set the style of the edit button. */
.edit {
    // Set the position of the button.
    position: fixed; top: 6rem; right: 1rem;
    @include mixins.media-breakpoint-down(sm) { top: 3.5rem; right: 3.5rem; }

    // Set the size of the button.
    height: 2rem; width: 2rem;

    // Set the style of the background.
    border-radius: 1rem;
    @include colours.themed {
        background-color: colours.t("background-dark");
        color: colours.t("text");
    }
    @include shadows.distance;

    // Change the style when clicked.
    &:active, &--active {
        @include colours.themed {
            background-color: colours.t("primary");
            color: colours.t("text-light");
        }
    }
}

/** Set the style of the icon modal. */
.icon-modal {
    // Set the style of the content.
    &__content {
        // Clear the default border.
        border: none; border-radius: 1rem;

        // Add a slight backdrop shadow.
        @include shadows.distance;
    }

    // Set the style of the header.
    &__header {
        // Display the contents in a row.
        display: flex; flex-direction: row;
        justify-content: space-between; align-items: flex-start;
        padding: 0.5rem;
    }

    // Set the style of the title.
    &__title { font-size: 1.5rem; }

    // Set the style of the close button.
    &__close {
        // Clear the default stylings.
        all: unset;

        // Display the icon in the center.
        display: flex; flex-direction: column;
        justify-content: center; align-items: center;
        padding: 0.125rem;

        // Set the size of the icon.
        &__icon { height: 1.25rem; width: 1.25rem; }

        // Set the style of the button.
        border-radius: 100%;
        @include colours.themed {
            background-color: colours.t("danger");
            color: colours.t("text-light");
            border: solid 2px colours.t("danger");
        }

        // Change the style when active.
        &:active, &:hover {
            @include colours.themed {
                background-color: colours.t("background");
                color: colours.t("danger");
            }
        }
    }

    // Set the style of the body.
    &__body {
        display: grid;
        grid-template-rows: auto auto;
        grid-template-columns: 1fr;
        justify-items: center; align-items: center;
        justify-content: center; align-content: center;
        gap: 2rem;
    }

    // Set the style of the image container.
    &__container {
        // Set the height of the container.
        height: 12rem; aspect-ratio: 1;

        // Display the icon in the center.
        display: flex; flex-direction: column;
        justify-content: center; align-items: stretch;
        overflow: hidden;

        // Set the style of the container.
        border-radius: 1rem;
        @include shadows.distance;
        cursor: pointer;
    }

    // Set the style of the icon.
    &__icon { height: 100%; width: 100%; object-fit: contain; }

    // Set the style of the button.
    &__button {
        // Display the contents in a row.
        display: flex; flex-direction: row;
        justify-content: space-between; align-items: center;
        padding: 0.25rem 1rem;

        // Set the style of the background.
        border-radius: 1rem;
        @include colours.themed {
            background-color: colours.t("primary");
            color: colours.t("text-light");
        }
        @include shadows.distance;

        // Set the style of the text.
        &__text { margin: 0; }

        // Hide the input.
        &__input { display: none; }
    }
}
