/*!
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

@use "scss/colours";


/** Set the style of the flags. */
.flag {
    // Offset from the parent.
    position: absolute; left: var(--offset);

    // Set the size of the element.
    height: 1.5rem;

    // Set the style of the stroke.
    stroke-linecap: round;

    // Set the colour of the stroke and fill.
    @include colours.themed {
        stroke: colours.t("primary");
        fill: colours.t("primary");
    }

    // Animate the entry of the flag.
    @keyframes enter {
        from { bottom: 1rem; opacity: 0; }
        to { bottom: -0.25rem; opacity: 1; }
    }
    animation-name: enter;
    animation-duration: 500ms;
    animation-delay: calc(1500ms + var(--delay));
    animation-timing-function: ease-in-out;
    animation-fill-mode: both;
}
