/*!
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

@use "sass:color";
@use "scss/colours";
@use "scss/shadows";
@use "scss/scrollbar";


/** Set the style of the user list. */
.user-list {
    // Clear the default styling.
    all: unset;
    // Display the elements in a column.
    display: grid; grid-template-columns: 1fr;
    grid-auto-rows: 2rem; gap: 0.5rem;
    overflow: hidden auto;

    // Fill the parent.
    flex: 1 1 0;

    // Darken the background and add a bottom separator.
    @include colours.themed {
        background-color: colours.t("page-background");
    }
    @include scrollbar.with-scrollbar;
}

/** Set the style of the user item. */
.user {
    // Display the elements in a row.
    display: grid; grid-template-columns: 0.5rem auto 1fr 0.5rem;
    grid-column-gap: 1rem; padding: 0.5rem 0.5rem;
    justify-items: stretch; align-items: center;

    // Set the style of the text.
    &__name {
        white-space: nowrap; text-overflow: ellipsis;
        overflow: hidden;
        @include colours.themed {
            color: colours.t("primary");
        }
    }
}

/** Set the style of the delete button. */
.delete-button {
    // Remove the default style.
    all: unset;
    grid-column-start: 2;

    // Display the icon in the center.
    display: flex; justify-content: center; align-items: center;
    padding: 0.375rem;
    &__icon { width: 0.75rem; height: 0.75rem; }

    // Set the style of the box.
    border-radius: 1rem;
    @include colours.themed {
        background-color: colours.t("background");
        color: colours.t("danger");
        border: solid 1px colours.t("danger");
        &:hover {
            background-color: colours.t("danger");
            color: colours.t("background");
        }
    }
}
