/*!
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

@use "scss/colours";
@use "scss/shadows";

/** Set the style of the box header.  */
.header {
    // Display the contents in a row.
    display: flex; flex-direction: row;
    justify-content: flex-start; align-items: center;
    overflow: hidden; flex-wrap: nowrap; gap: 0.5rem;

    // Set the style of the box.
    min-height: 2.5rem;
    border: none;
    border-radius: 1.25rem 1.25rem 0 0;

    padding: 0.25rem 1rem;

    // Set the style of the cursor.
    pointer-events: all;

    * { cursor: pointer; pointer-events: all; }

    &--not-interactable * { cursor: default; }

    // Set the colours of the button.
    @include colours.themed {
        color: colours.t("secondary");
        background-color: colours.t("background");
    }

    // Set the style of the chevron.
    &__chevron {
        // Set the size of the element.
        height: 1rem;

        // Set the rotation of the chevron.
        transition: transform 0.1s ease-in-out;
        transform: rotateZ(0deg);
        .header--open & { transform: rotateZ(90deg); }

        // Hide the chevron when locked.
        &--locked { display: none; }
    }

    // Set the style of the icon container.
    &__icon-container {
        // Take as much space as is needed.
        flex: 0 0 auto; height: 1.5rem;

        // Display the elements in a row.
        display: flex; flex-direction: row;
        justify-content: flex-end; align-items: center;
        flex-wrap: nowrap;

        img { height: 100%; width: auto; object-fit: contain; }
    }

    // Set the style of the control container.
    &__control-container {
        // Take as much space as is needed.
        display: flex; align-items: center;

        // Display the elements in a row.
        flex: 0 0 auto;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: flex-end;
        height: 1.5rem;

        img {
            width: auto;
            height: 100%;
            object-fit: contain;
        }

        gap: 0.5rem;
    }
}
