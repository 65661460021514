/*!
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

@use "css/colours";
@use "css/shadows";

/** Set the style of the fatal error. */
.fatal-error {
    // Display the contents in a column.
    display: flex; flex-direction: column;
    justify-content: flex-start; align-items: center;
    gap: 0;
    height: 100%;

    // Set the inside padding.
    padding: 0 1rem 2rem;

    /** Set the style of the icon. */
    &__icon { max-height: 33vh; width: 100vw; height: 100vh; object-fit: contain; }

    /** Set the style of the description. */
    &__description {
        font-size: 1.5rem; text-align: center;
        em { font-size: 2rem; font-style: normal; font-weight: bold; }
        small { font-size: 1rem; font-style: italic; }
    }

    /** Set the style of the error list. */
    &__error-list {
        // Fill the parent.
        flex-basis: auto; align-self: stretch;

        // Display the contents in a column.
        display: flex; flex-direction: column;
        justify-content: flex-start; align-items: stretch;
        gap: 1rem;

        // Set the style of the box.
        list-style: none; margin: 0; padding: 0.5rem 1rem 1rem;
        border-radius: 0.25rem;
    }

    /** Set the style of the menu button. */
    &__button {
        background-color: colours.$blue-dark;
        padding: 0.5rem 1rem; margin: 2rem 0 0;
        border-radius: 1rem;
        color: white;
        box-shadow: shadows.box-shadow($light: false);
    }
}

/** Set the style of the errors. */
.error {
    // Set the style of the box.
    border-radius: 1rem;
    margin: 0; padding: 0;

    // Render the items in a column.
    display: flex; flex-direction: column;
    justify-content: flex-start; align-items: stretch;

    // Set the style of the header.
    &__header {
        // Set the style of the box.
        //box-shadow: 0 0.25rem 0.25rem -0.25rem colours.$tertiary-colour;
        border-bottom: solid 1px transparentize(colours.$tertiary-colour, 0.5);
        padding: 0.25rem 1rem 0; margin: 0;

        // Set the style of the text.
        font-size: 1.25rem; font-style: italic;
        em { font-style: normal; font-weight: bold; width: 1.5rem; display: inline-block; }
    }

    // Set the style of the body.
    &__body {
        padding: 0.25rem 1rem 0;

        // Set the style of the description.
        &__description { margin: 0; font-size: 1rem; }

        // Set the style of the details text.
        &__details { margin: 0.5rem 0 0; font-size: 0.75rem; font-style: italic; }

        // Set the style of the encoded details.
        &__code {
            // Set the style of the box.
            background-color: colours.$dark-text-colour;
            margin: 0; padding: 0.25rem 0.5rem;
            border-radius: 0.5rem;

            // Set the style of the font.
            font-size: 0.75rem; color: colours.$background-colour;
        }
    }

    // Set the style of the report button.
    &__button {
        // Display the contents in a row.
        display: flex; flex-direction: row;
        justify-content: flex-start; align-items: center;
        overflow: hidden; gap: 0.5rem;

        // Align to the end.
        align-self: end;
        margin: 0.25rem 0 0; padding: 0.125rem 1rem 0.125rem 0.5rem;

        // Set the style of the box.
        background-color: colours.$blue;
        border-radius: 0.75rem;
        box-shadow: shadows.box-shadow($light: true);

        // Set the style of the text.
        color: white; font-size: 0.75rem;
        white-space: nowrap;
        // Set the style of the image.
        img { height: 1.25rem; width: auto; }

        // Set the style of the custom buttons.
        &--unreported { padding-left: 1rem; }
    }
}
