/*!
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

@use "scss/colours";
@use "scss/mixins";


/** Set the style of the home link. */
.home {
    // Display the elements in a row.
    display: flex; flex-direction: row;
    justify-content: flex-start; align-items: center;
    gap: 0.5rem; padding: 0.25rem 0.5rem;

    // Set the style of the background.
    border-radius: 1rem;
    @include colours.themed {
        color: colours.t("primary");
        border: solid 1px colours.t("primary");

        // Change the colours when active.
        &:active {
            color: colours.t("text-light");
            background-color: colours.t("primary");
        }
    }

    // Add a transition.
    transition: background-color 0.15s ease-in-out, color 0.15s ease-in-out;

    // Disable user selection.
    user-select: none; cursor: pointer;
}

/** Set the style of the home icon. */
.icon {
    // Set the size of the icon.
    height: 1.5rem; width: 1.5rem;

    // Hide on smaller screens.
    @include mixins.media-breakpoint-down(sm) { display: none; }
}

/** Set the style of the logo. */
.logo {
    // Set the style of the text.
    padding: 0; margin: 0; line-height: 1;
    font-size: 1.5rem;

    // Change the style of the image.
    &:is(img) {
        // Set the size of the logo.
        height: 1.5rem;

        // Apply the blue filter to the icon.
        filter: brightness(0) saturate(100%) invert(56%) sepia(99%) saturate(1118%) hue-rotate(170deg) brightness(87%) contrast(86%);
        transition: filter 0.15s ease-in-out;

        // Disable text selection on the icon.
        pointer-events: none;
        user-select: none; -webkit-user-select: none;
    }
}

// Remove the filter when the button is hovered.
.home:active {
    .logo:is(img) { filter: unset; }
}
