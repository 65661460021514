/*!
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import the colours.
@import "css/colours";

/** Set the style of the header. */
.table-header {
    // Set the colour of the background on all cells.
    td { background-color: white; }
    // Add a border around the first and last cells.
    td:first-of-type { border-top-left-radius: 1rem; }
    td:last-of-type { border-top-right-radius: 1rem; }

    // Add a max width to the column.
    td { max-width: 12rem; overflow: hidden; text-overflow: ellipsis; }

    // Align the titles in the center.
    td { text-align: center; font-weight: normal; font-size: 1rem; padding: 0.25rem 0.5rem; }
}
