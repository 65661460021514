/*!
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

@use "scss/colours";
@use "scss/shadows";


/** Set the style of the modal. */
.modal {
    // Set the style of the contents.
    &__content {
        // Remove the default border.
        border: none; border-radius: 0.5rem;
    }
}

/** Set the style of the header. */
.header {
    // Remove the default border.
    border: none; border-radius: 1rem 1rem 0 0;

    // Display the elements in a row.
    display: flex; flex-direction: row;
    justify-content: flex-start; align-items: center;
    gap: 1rem; padding: 0.5rem 1rem;

    // Set the style of the background.
    @include colours.themed {
        color: colours.t("warning");
        background-color: colours.t("background");
    }
}

/** Set the style of the icon. */
.icon { font-size: 1.25rem; }

/** Set the style of the title. */
.title { font-size: 1.5rem; @include colours.themed { color: inherit; } }

/** Set the style of the body. */
.body {
    // Display the elements in a column.
    display: flex; flex-direction: column;
    justify-content: flex-start; align-items: stretch;
    gap: 1rem; padding: 0.5rem;

    // Add a top shadow.
    @include shadows.top;
}

/** Set the style of the text. */
.text { text-align: justify; margin: 0 1rem; }

/** Set the style of the button. */
.button {
    // Clear the default styling.
    all: unset;

    // Display the element in a row.
    display: flex; flex-direction: row;
    justify-content: center; align-items: center;
    gap: 1rem; padding: 0.25rem 0.5rem;

    // Set the style of the background.
    border-radius: 1rem;
    @include colours.themed {
        color: colours.t("primary");
        background-color: colours.t("background");
        border: solid 1px colours.t("primary");

        // Change the style when hovered.
        &:hover {
            color: colours.t("text-light");
            background-color: colours.t("primary");
        }

        // Change the style when active.
        &:active {
            color: colours.t("text-light");
            background-color: colours.t("secondary");
        }
    }
}
