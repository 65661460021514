/*!
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

@use "scss/colours";

/** Set the colour of the paths. */
.path {
    // Fill with the text colour by default.
    fill: currentColor;

    // Set the style of the primary colour.
    .zaqtiv--primary & {
        @include colours.themed { fill: colours.t("primary"); }
    }

    // Set the style of the secondary colour.
    .zaqtiv--secondary & {
        @include colours.themed { fill: colours.t("secondary"); }
    }

    // Set the style of the white colour.
    .zaqtiv--white & {
        @include colours.themed { fill: colours.t("background"); }
    }
}

/** Set the colour of the box. */
.box {
    @include colours.themed {
        fill: colours.t("background-dark");
    }
}
