/*!
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

@use "scss/colours";
@use "scss/mixins";

/** Set the style of the main container. */
.container {
    // Fill the parent.
    height: 100%; max-height: 100%;
    width: 100%; max-width: 100%;

    // Add a slight padding.
    padding: 0.5rem 0.5rem max(env(safe-area-inset-bottom), 0.5rem);

    // Display the elements in a flexbox container.
    display: flex; justify-content: flex-start; align-items: flex-start;
    flex-wrap: nowrap; gap: 0.5rem; overflow: hidden;

    // Change the orientation on small devices.
    @media (orientation: landscape) { flex-direction: row; }
    @media (orientation: portrait) { flex-direction: column; }

    @include colours.themed { background-color: colours.t("page-background"); }
}

/** Set the style of the card container. */
.card-container {
    // Fill the parent.
    align-self: stretch; flex: 1 1 0;
}

/** Set the style of the buttons. */
.button {
    // Show on top of the page.
    z-index: 1;

    // Set the style of the icon.
    &__icon { height: 1rem; }
    // Set the style of the text.
    &__text {
        flex: 1 1 0;
        line-height: 1; margin: 0 0.5rem 0 0; padding: 0; overflow: hidden;
        font-size: 1rem; font-weight: bold; white-space: nowrap; text-overflow: ellipsis;
    }

    // Hide the button when disabled.
    &:disabled { visibility: hidden; pointer-events: none; }
    // Remove the link style.
    > a {
        height: 100%; width: 100%; display: flex; justify-content: center; align-items: center;
        &:active, &:hover, &:focus, &:visited, & {
            color: unset; text-decoration: unset;
        }
    }

    // Set the style of the container.
    &__container {
        // Fill the parent.
        align-self: stretch;

        // Add a small amount of padding on larger screens.
        @include mixins.media-breakpoint-up(md) { padding: 0.5rem; }

        // Display the elements in a flexbox.
        display: flex; justify-content: flex-start; align-items: flex-start;
        &--vertical { flex-direction: column; flex: 1 1 0; }
        &--horizontal { flex-direction: row; }
        &--top &--toolbar { flex: 0 0 50vw; justify-content: flex-end; }
        gap: 0.5rem; flex-wrap: nowrap;

        // Hide the top toolbar on larger screens.
        &--top &--toolbar { @media (orientation: landscape) { display: none; }}
        // Hide the right toolbar on smaller screens.
        &--right &--toolbar { @media (orientation: portrait) { display: none; }}
    }

    // Hide the large button on large screens.
    @media (orientation: landscape) { &--large { display: none; } }
    @media (orientation: portrait)  {
        @media (max-width: 399.99px) {
            &--large { display: none; }
        }
        @media (min-width: 400px) {
            &--small { display: none; }
        }
    }
}

