/*!
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

@use "scss/colours";
@use "scss/shadows";

/** Set the style of the expiration label. */
.expiration {
    // Display the contents in a row.
    display: flex; flex-direction: row;
    justify-content: flex-start; align-items: center;
    gap: 2rem;

    // Hide when the link is inactive.
    :global(.share-link--inactive) & { display: none; }
}

/** Set the style of the label. */
.label {
    // Set the size of the label.
    flex: 0 0 2.5rem;

    // Set the style of the text.
    font-size: 0.8rem;
    @include colours.themed {
        color: colours.t("text");
    }

    // Change the colour when the link is expired.
    :global(.share-link--expired) & {
        @include colours.themed {
            color: colours.t("background-dark");
        }
    }
}

/** Set the style of the date input. */
.date {
    // Set the style of the element.
    @include colours.themed {
        :global(.react-date-picker__wrapper) {
            border-radius: 1rem;
            padding: 0 0.25rem;
            font-size: 0.75rem;
            color: colours.t("text");
            border-color: colours.t("primary");

            :global(.react-date-picker__inputGroup__input) {
                margin-left: 0;
                padding: 0;
            }
        }
    }

    // Change the colour when the link is expired.
    :global(.share-link--expired) & :global(.react-date-picker__wrapper) {
        @include colours.themed {
            color: colours.t("background-dark");
            border-color: colours.t("background-dark");
        }
    }

}

// Set the style of the icons.
.icon {
    // Set the colour of the icon.
    @include colours.themed {
        color: colours.t("primary");
    }

    // Change the colour of the icon when the link is expired.
    :global(.share-link--expired) & {
        @include colours.themed {
            color: colours.t("text");
        }
    }
}

/** Set the style of the opened calendar. */
.calendar {
    // Set the style of the background.
    border-radius: 0.25rem; padding: 0.25rem;
    @include colours.themed {
        background-color: colours.t("background");
    }
    @include shadows.distance;

    /** Set the style of the navigation. */
    :global(.react-calendar__navigation) {
        display: flex; flex-direction: row;
        justify-content: center; align-items: center;

        // Set the colour of the arrows.
        :global(.react-calendar__navigation__arrow) {
            font-size: 1.25rem;
            @include colours.themed { color: colours.t("primary"); }
        }

        // Set the colour of the title.
        :global(.react-calendar__navigation__label) {
            font-size: 1.25rem;
            @include colours.themed { color: colours.t("secondary"); }
        }
    }
}

