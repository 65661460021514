/*!
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import the colours.
@use "css/colours";
@use "css/mixins";

/** Set the style of the tag reader. */
.tag-container {
    flex: 1 1 0;

    // Clear the start padding.
    padding-left: 0;

    // Display the contents in a row.
    display: flex; flex-direction: row;
    justify-content: flex-start; align-items: center;
    flex-wrap: wrap;

    /** Set the style of the title. */
    &__title {
        // Make sure the text is centered.
        flex: 0 0 auto; width: auto;
        display: inline; text-align: center;

        // Clear the end padding.
        padding-right: 0;

        // Make the text not selectable.
        user-select: none;

        // Set the style of the font.
        color: colours.$dark-text-colour;
        font-weight: normal;
        font-size: 1rem;

        // Remove the default margin.
        margin: 0.5rem 0.5rem 0 0;
    }

    /** Set the style of the search bar. */
    &__search-bar { flex: 1 1 0; }
}

/** Set the style of the tags. */
.tag {
    // Display the elements in a row.
    display: flex; flex-direction: row;
    justify-content: center; align-items: center;
    flex-wrap: nowrap;

    // Add some margins/padding.
    margin: 0.25rem;
    padding: 0 0.25rem;

    // Add a rounded border.
    border-radius: 1rem; background-color: white;
    box-shadow: 0.125rem 0.125rem 0.25rem colours.$tertiary-colour;

    // Make the text not selectable.
    user-select: none;

    // Set the style of the text.
    p {
        text-align: start; font-size: 1rem;
        color: colours.$blue-dark;
        flex: 1 1 0; margin: 0 0.25rem;
    }
    p > em { font-style: normal; font-weight: normal; margin-right: 0.125rem; }
    /** Set the style of the image. */
    img { display: none; height: 1rem; width: 1.5rem; margin: 0 0 0 0; }

    // Set the hovered style.
    cursor: pointer;

    /** Set the style of the editable element. */
    &--editable {
        // Show the image.
        img { display: block; }

        // Change the padding.
        padding-left: 0.25rem;
    }

    /** Set the style of the openable button. */
    &__open:hover { background-color: colours.$blue-dark; p { color: white; } }

    /** Set the style of the remove tag button. */
    &__remove img { filter: colours.$low-colour-filter; }
    &__remove:hover { img { filter: none; }; background-color: colours.$low-colour; p { color: white; } }

    /** Set the style of the add tag button. */
    &__add { p { color: colours.$dark-text-colour; } }
    &__add img { filter: colours.$high-colour-filter; }
    &__add:hover { img { filter: none; }; background-color: colours.$high-colour; p { color: white; } }
}

/** Set the style of the add elements. */
.tag-add {
    /** Set the style of the button. */
    &__button {
        // Remove the default styling.
        &:active, &:focus, &:hover, & { background-color: white; outline: none; box-shadow: none; border: none; }

        // Display the image in the center.
        height: 2.5rem; width: 2.5rem;
        display: flex; justify-content: center; align-items: center;
        img { width: 100%; height: 100%; filter: colours.$high-colour-filter; }
        &:hover img { filter: colours.$primary-colour-filter; }

        // Set the margin-padding.
        margin: 0; padding: 0;

        // Roundify the element.
        border-radius: 100%;
    }

    /** Set the style of the form. */
    &__form {
        // Set the size of the element.
        flex: 0 0 10rem; height: 1.5rem;
        margin: 0; padding: 0 0.125rem 0 0.5rem;

        // Display the contents in a row.
        display: flex; flex-direction: row;
        justify-content: flex-start; align-items: center;

        // Set the style of the box.
        background-color: white;
        border-radius: 1rem;
        color: colours.$blue-dark;
        box-shadow: 0.125rem 0.125rem 0.25rem colours.$tertiary-colour;

        // Render the invalid text.
        &:invalid {
            input {
                color: colours.$low-colour;
                border-bottom-color: colours.$low-colour;
            }
            color: colours.$low-colour;
        }
    }
    /** Set the style of the input. */
    &__input {
        // Set the size of the element.
        flex: 1 1 0; height: auto; margin-left: 0.25rem;
        padding: 0 0.25rem 0.125rem; margin-right: 0.5rem;

        // Set the style of the box.
        border: { top: none; right: none; left: none; bottom: solid 1px colours.$blue-dark; }

        // Set the style of the text.
        line-height: 1;
        color: colours.$blue-dark; font-size: 1rem; font-style: italic;
        &, &:hover, &:focus, &:active { outline: none; box-shadow: none; }
    }
    /** Set the style of the checkmark. */
    &__checkmark {
        // Set the style of the box.
        width: 1.25rem; height: 1.25rem;
        padding: 0; margin-left: 0.5rem;

        // Display the image in the center.
        display: flex; justify-content: center; align-items: center;
        img { width: 100%; height: 100%; object-fit: contain; }
    }
    /** Set the style of the popover. */
    &__invalid {
        background-color: colours.$low-colour;
        border-color: white; color: white;
        padding: 0.125rem 0.5rem;
        border-radius: 0.5rem;
        :global(.popover-arrow)::before { border-top-color: white; }
        :global(.popover-arrow)::after { border-top-color: colours.$low-colour; }
    }
}
