/*!
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import the colours.
@import "css/colours";
// Import the mixins.
@import "css/mixins";

/** Set the style of the user info modal. */
.user-info {
    // Display the contents in a column.
    display: flex; flex-direction: column;
    justify-content: space-between; align-items: stretch;
    overflow: hidden; flex-wrap: nowrap;

    // Add some inside padding.
    padding: 0.5rem 1rem;

    /** Set the style of the user information. */
    &__info {
        // Display the contents in a row.
        display: flex; flex-direction: row;
        justify-content: flex-start; align-items: center;
        overflow: hidden; flex-wrap: nowrap;

        /** Set the style of the person icon. */
        &__person {
            // Hide on smaller screens.
            @include media-breakpoint-down(md) { display: none; }
            // Set the size of the element.
            flex: 0 0 auto; height: 5rem;

            // Set the filter.
            filter: $secondary-colour-filter;
        }

        /** Set the style of the form. */
        &__form {
            // Fill the parent.
            flex: 1 1 0;

            // Add some padding.
            @include media-breakpoint-up(md) { padding: 0 2rem; }

            // Display the contents in a column.
            display: flex; flex-direction: column;
            justify-content: flex-start; align-items: stretch;
            overflow: hidden; flex-wrap: nowrap;

            /** Set the style of the labels. */
            &__label {
                // Display the contents in a row.
                display: flex; flex-direction: row;
                justify-content: flex-start; align-items: flex-end;
                overflow: hidden; flex-wrap: wrap;
                // Change the cursor.
                cursor: pointer;

                // Set the style of the font.
                > p { width: 15%; font-weight: bold; margin: 0 1rem 0 0; }

                // Set the style of the contents.
                &__content {
                    // Fill the parent.
                    width: 100%; max-width: 24rem;

                    // Display the contents in a column.
                    display: flex; flex-direction: column-reverse;
                    justify-content: flex-start; align-items: center;
                    overflow: hidden; flex-wrap: nowrap;
                    > :global(.invalid-feedback) {
                        color: $low-colour;
                        padding: 0 0.5rem;
                    }
                }

                // Set the style of the inputs.
                input {
                    // Remove the outline.
                    &:focus, &:active, &:hover, & { outline: none; box-shadow: none !important; }

                    // Fill the parent.
                    width: 100%;

                    // Set the margins and padding.
                    margin: 0; padding: 0 0.25rem 0 0.5rem;

                    // Remove the default border.
                    border-radius: 0;
                    border: {
                        top: unset; left: unset; right: unset;
                        bottom: solid 2px transparent;
                    };
                    &:focus { border-bottom-width: 2px; }

                    // Set the colours of the borders.
                    &:valid {
                        border-color: $high-colour !important;
                        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23c7d882' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") !important;
                    }
                    &:invalid {
                        border-color: $low-colour !important;
                        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23ff8e7c'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e") !important;
                    }
                }
            }
        }

        /** Set the style of the bin. */
        &__bin {
            // Remove the default styling.
            background: unset; border: unset; outline: unset;

            // Force the size of the element.
            align-self: flex-start;
            flex: 0 0 4rem; height: 3.5rem;

            // Display the image at the bottom left.
            display: flex; flex-direction: column;
            justify-content: flex-end; align-items: flex-start;
            overflow: hidden; flex-wrap: nowrap;

            // Set the style of the image.
            img { width: 2.5rem; height: 2.5rem; filter: $low-colour-filter; }

            // Hide the image if the button is disabled.
            &:disabled img { visibility: hidden; }
        }
    }

    /** Set the style of the role. */
    &__role {
        // Display the contents in a row.
        display: flex; flex-direction: row;
        justify-content: flex-start; align-items: center;
        overflow: hidden; flex-wrap: nowrap;

        // Add a top margin.
        margin: 1rem 0;

        // Set the style of the text.
        &__label { margin: 0 1rem 0 0; font-weight: bold; }
    }

    /** Set the style of the toggle. */
    &__toggle {
        // Align at the start.
        align-self: flex-start;
        display: flex; flex-direction: row;
        justify-content: flex-start; align-items: center;

        // Hide the input.
        input { display: none; }

        // Set the style of the label text.
        &__label { margin: 0 0.5rem 0 0; font-weight: bold; }

        // Display the contents in a row.
        label {
            display: flex; flex-direction: row;
            justify-content: flex-start; align-items: center;
            overflow: hidden; flex-wrap: nowrap;
            margin: 0;
        }

        /** Set the style of the container. */
        &__container {
            // Display the contents in a row.
            display: flex; flex-direction: row;
            justify-content: flex-start; align-items: center;
            overflow: hidden; flex-wrap: nowrap;
        }

        &__disabled-text, &__enabled-text { margin: 0 0.5rem; }
        /** Set the style of the disabled text. */
        &--disabled &__disabled-text { color: $dark-text-colour; transition: color 0.1s linear; }
        &--enabled &__disabled-text { font-weight: normal; color: $tertiary-colour; }
        /** Set the style of the enabled text. */
        &--enabled &__enabled-text { color: $blue; transition: color 0.1s linear; }
        &--disabled &__enabled-text { color: $tertiary-colour; }
    }
}
