/*!
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

@use "../../../../../../assets/src/scss/colours";
@use "../../../../../../assets/src/scss/shadows";


/** Set the style of the selector. */
.selector {
    // Clear the default style.
    all: unset;

    // Fill the parent.
    flex: 1 0 auto; align-self: stretch;

    // Add some padding.
    padding: 0 1.5rem 0 0.5rem;

    // Set the style of the text.
    font-size: 1rem;

    /** Set the style of the container. */
    &__container {
        // Display the button in the center.
        display: flex; flex-direction: row;
        justify-content: center; align-items: center;
        position: relative;

        // Set the style of the box.
        @include shadows.distance(0.125rem);
        @include colours.themed {
            background-color: colours.t("background");
        }
        border-radius: 0.5rem;
        cursor: pointer;

        // Set the colour of the text.
        @include colours.themed {
            color: colours.t("secondary");
        }
    }

    /** Set the style of the checkmark. */
    &__chevron {
        // Place at the right of the parent.
        position: absolute; top: 50%; right: 0.25rem;
        transform: translateY(-50%); pointer-events: none;
    }
}
