/*!
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

/** Set the style of the loader. */
.loader { height: 80vh; }

/** Set the style of the container. */
.container {
    // Add some inside padding.
    padding: 1rem 0 6rem;

    // Display the elements in a column.
    display: flex; flex-direction: column;
    justify-items: flex-start; align-items: stretch;
    gap: 1rem;
}
