/*!
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

@use "scss/colours";
@use "scss/shadows";


/** Set the style of the search items. */
.item {
    // Clear the default style.
    all: unset;

    // Fill the parent.
    flex: 1 1 0; align-self: stretch;
    text-align: start;
}

/** Set the style of the add button. */
.add-button {
    // Clear the default style.
    all: unset;

    // Display the items in the center.
    display: flex; flex-direction: row;
    justify-content: center; align-items: center;
    gap: 0.5rem; align-self: center;

    // Set the style of the button.
    border-radius: 1rem; padding: 0.25rem 1rem 0.25rem 0.5rem;
    cursor: pointer;
    @include colours.themed {
        background-color: colours.t("background");
        color: colours.t("primary");
        border: solid 1px colours.t("primary");
        &:hover {
            color: colours.t("background");
            background-color: colours.t("primary");
        }
    }

    // Set the style of the icon.
    &__icon {height: 1rem; width: 1rem; }
}

