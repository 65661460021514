/*!
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

.h1 { font-size: 2.00rem; text-decoration: underline; font-weight:   bold; text-align: start; margin: 0 0 0.50rem; }
.h2 { font-size: 2.00rem; text-decoration:      none; font-weight:   bold; text-align: start; margin: 0 0 0.50rem; }
.h3 { font-size: 2.00rem; text-decoration:      none; font-weight: normal; text-align: start; margin: 0 0 0.50rem; }
.h4 { font-size: 1.50rem; text-decoration:      none; font-weight:   bold; text-align: start; margin: 0 0 0.25rem; }
.h5 { font-size: 1.50rem; text-decoration:      none; font-weight: normal; text-align: start; margin: 0 0 0.25rem; }
.h6 { font-size: 1.25rem; text-decoration:      none; font-weight:   bold; text-align: start; margin: 0 0 0.25rem; }
.p  { font-size: 1.25rem; text-decoration:      none; font-weight: normal; text-align: start; margin: 0 0 0.25rem; }
.bold { font-style: inherit; font-weight: bold; text-decoration: inherit; }
.italic { font-weight: inherit; font-style: italic; text-decoration: inherit; }
.underline { font-weight: inherit; font-style: inherit; text-decoration: underline; }

// Use empty paragraphs to preserve white space.
.p:empty { flex: 0 0 1.25rem; display: block; }

// Override the "pre" style.
.pre { overflow: unset; max-width: 100%; }
