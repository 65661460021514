/*!
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

@use "css/colours";
@use "css/shadows";
@use "sass:math";

/** Set the style of the save indicator. */
.save-indicator {
    z-index: 1000000;

    // Display the contents in a row.
    display: flex; flex-direction: row;
    justify-content: center; align-items: center;

    // Set the colour of the background.
    background-color: white; border-radius: 1rem; box-shadow: shadows.box-shadow();

    // Set the size of the image.
    &__loader { height: 2rem; width: 2rem; margin-right: 0.25rem; }
    // Set the style of the text.
    p { font-size: 1rem; color: colours.$blue; font-style: italic; margin: 0 1rem 0 0; }

    // Place at the correct location.
    position: fixed; $distance: 3.5rem;
    &--top-left     { top   : $distance; left : math.div($distance, 4); }
    &--top-right    { top   : $distance; right: math.div($distance, 4); }
    &--bottom-left  { bottom: $distance; left : math.div($distance, 4); }
    &--bottom-right { bottom: $distance; right: math.div($distance, 4); }
}
