/*!
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

@use "scss/colours";
@use "scss/mixins";


/** Set the style of the container. */
.container {
    // Display the elements in a column.
    display: flex; flex-direction: column;
    justify-content: flex-start; align-items: flex-start;
}

/** Set the style of the title. */
.title {
    // Clear the default margin.
    margin: 0;

    // Set the style of the text.
    text-decoration: underline; font-size: 1.25rem;
    @include colours.themed {
        color: colours.t("primary");
    }

    // Change the style of the text on smaller devices.
    @include mixins.media-breakpoint-down(sm) {
        font-size: 1rem;
    }

    // Set the style of the play icon.
    &__play {
        // Set the size of the icon.
        height: .5rem;

        // Offset the icon slightly to align with the parent's line.
        transform: translate(50%, 50%);
    }
}

/** Set the style of the description. */
.description {
    // Fill the available space.
    display: block; flex: 1 0 auto;

    // Clear the default margin.
    margin: 0;

    // Set the style of the text.
    font-size: 1rem;
    @include colours.themed {
        color: colours.t("text");
    }

    // Change the style of the text on smaller devices.
    @include mixins.media-breakpoint-down(sm) {
        font-size: 0.75rem;
    }
}

/** Set the style of the tag container. */
.tag-container {
    // Clear the default styling.
    all: unset;

    // Display the elements in a wrapping row.
    display: flex; flex-direction: row;
    justify-content: flex-start; align-items: flex-start;
    gap: 0.25rem 1rem; flex-wrap: wrap;
}

/** Set the style of the tags. */
.tag {
    // Clear the default styling.
    all: unset;

    // Add a top margin to detach from the description.
    margin-top: 0.5rem;

    // Set the style of the text.
    font-size: 0.75rem;
    @include colours.themed { color: colours.t("text"); }


    // Change the style of the text on smaller devices.
    @include mixins.media-breakpoint-down(sm) {
        font-size: 0.75rem;
    }

    // Add a pound sign in front.
    &::before { content: "#"; }
}
