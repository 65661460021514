/*!
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

@use "scss/colours";
@use "scss/shadows";


/** Set the style of the container. */
.container {
    // Fill the parent.
    flex: 1 1 0;

    // Display the contents in the center.
    display: flex; flex-direction: column;
    justify-content: flex-start; align-items: stretch;

    // Add some padding to the component.
    padding: 1rem 0.5rem;
}

/** Set the style of the menu. */
.menu {
    // Display the contents in a column.
    display: flex; flex-direction: column;
    justify-content: center; align-items: stretch;

    // Set the style of the box.
    @include colours.themed {
        background-color: colours.t("background");
        border: solid 1px colours.t("background-dark");
    }
    border-radius: 0.125rem; overflow: hidden;
}
