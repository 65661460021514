/*!
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

@use "scss/colours";


/** Set the style of the favourite button. */
.button {
    // Clear the default styling.
    all: unset;

    // Set the style of the default button.
    @include colours.themed {
        color: colours.t("primary");
    }

    // Set the style of the activated button.
    &--active {
        // Change the colour of the star.
        @include colours.themed {
            color: colours.t("warning");
        }
    }
}

/** Set the style of the icon. */
.icon { height: 1.5rem; width: 1.5rem; }
