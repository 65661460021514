/*!
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import the colours.
@import "../../../../assets/src/css/colours";
// Import the mixins.
@import "../../../../assets/src/css/mixins";

/** Set the style of the icons. */
.icon {
    position: relative;
    user-select: none;

    // Add some margins.
    margin: 0.5rem; padding: 0.25rem;
    @include media-breakpoint-down(md) { margin: 0.25rem; }

    // Remove the default styling.
    &:active, &:hover, &:focus, & { background-color: unset; outline: unset; border: none; }
    &:hover { background-color: $blue-dark; } border-radius: 1rem;

    // Hide any input element.
    input { display: none; }

    /** Set the style of the name. */
    &__name { color: $dark-text-colour; font-size: 1rem; margin: 0.5rem 0 0; white-space: pre; line-height: 1; }

    /** Set the style of the icon. */
    &__icon {
        height: 3rem;
        filter: $blue-filter;
        &__small {
            width: 5rem;
            filter: $blue-filter;
        }

        &--no-filter { filter: unset; color: $blue; }
    }
    &:hover &__icon { filter: unset; }
    &:hover &__icon__small { filter: unset; }
    &:hover &__name { color: white; }

    /** Set the style of the add icon. */
    &__add {
        // Set the position of the icon.
        position: absolute; height: 2rem;
        top: -0.5rem; right: -0.5rem;

        // Set the background of the icon.
        background-color: $high-colour;
        border-radius: 100%;
        padding: 0.25rem;
    }
    /** Set the style of the extra icon. */
    &__extra {
        // Set the position of the icon.
        position: absolute; height: 2rem;
        top: 33%; left: 0.75rem;
    }

    /** Set the style of the active element. */
    &--active { background-color: $primary-colour; }
}
