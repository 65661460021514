/*!
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import the colours.
@import "css/colours";

/** Set the style of the manual user creation. */
.manual {
    // Display the contents in a column.
    display: flex; flex-direction: column; align-items: stretch;

    // Set the style of the add button.
    &__add { height: 3rem; padding: 0.25rem; }
    &__add img { max-height: 100%; filter: $high-colour-filter; }

    // Set the style of the heading.
    h4 { font-size: 1.25rem; color: $dark-text-colour; font-weight: normal; }
}
