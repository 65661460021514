/*!
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

@use "scss/mixins";
@use "scss/colours";
@use "./index.module";

/** Set the style of the module. */
.tuto {
    // Fill the parent.
    height: 100%; overflow: hidden;

    // Display the contents in the center.
    display: flex; justify-content: center; align-items: stretch;

    // Set the style of the frame.
    &__frame {
        // Set the style of the box.
        border-radius: 1rem;
    }
}
