/*!
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

@use "scss/colours";
@use "scss/shadows";


/** Set the style of the container. */
.container {
    // Display the contents in a row.
    display: flex; flex-direction: row;
    justify-content: flex-start; align-items: center;
    overflow: hidden; flex-wrap: wrap;
    gap: 0.5rem; padding: 0 1rem 1rem 1rem;
}

/** Set the style of the separator. */
.separator { width: 1rem; }

/** Set the style of the title. */
.title {
    font-weight: normal;
    @include colours.themed {
        color: colours.t("secondary");
    }
}

/** Set the style of the selector. */
.selector {
    // Clear the default styling.
    all: unset;

    // Set the style of the box.
    cursor: pointer; max-width: 100%;
    //padding: 0.25rem 2rem 0.25rem 1rem;
    @include colours.themed {
        color: colours.t("primary");
    }

    /** Set the style of the container. */
    &__container {
        position: relative; justify-self: start;

        // Set the style of the box.
        border-radius: 1rem; padding: 0.25rem 2rem 0.25rem 1rem; overflow: hidden;
        @include colours.themed {
            background-color: colours.t("background");
            border: solid 1px colours.t("primary");
        }
    }

    /** Set the style of the icon. */
    &__icon {
        // Place at the right of the parent.
        position: absolute; top: 0.5rem; right: 0.5rem;
        height: 1rem; width: 1rem;

        // Set the colour of the icon.
        @include colours.themed {
            color: colours.t("primary");
        }

        // Ignore pointer events.
        pointer-events: none;
    }
}
