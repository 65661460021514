/*!
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

@use "scss/colours";
@use "scss/mixins";

/** Set the style of the due date. */
.due-date {
    // Set the style of the text.
    font-size: 0.75rem;
    @include colours.themed { color: colours.t("text"); }

    // Change the style if the item is past due.
    :global(.zaq-training__training-status--past-due) & {
        @include colours.themed { color: colours.t("danger"); }
    }

    // Change the size on smaller displays.
    @include mixins.media-breakpoint-down(sm) {
        font-size: 0.625rem;
    }
}
