/*!
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

@use "scss/colours";
@use "scss/shadows";


/** Set the style of the overflow helper. */
.overflow-container {
    // Display the container in the middle.
    display: grid; justify-items: center; align-items: center;
    overflow: auto hidden;
    padding: 1rem;
}

/** Set the style of the table. */
.table {
    position: relative;

    // Set the column variables.
    --column-count: 1;
    --column-size: auto;
    --row-size: 2.5rem;
    --header-column-size: auto;

    // Display the contents in a grid.
    display: grid; gap: 0;
    grid-template-columns: var(--header-column-size) repeat(max(0, calc(var(--column-count) - 1)), var(--column-size));
    grid-auto-rows: var(--row-size);
    justify-items: stretch; align-items: stretch;

    // Apply the filter transform.
    //@include shadows.distance-filter;
}

/** Set the style of the cells. */
.cell {
    // Display the contents in the center.
    display: flex; flex-direction: row;
    align-items: center; justify-content: center;
     padding: 0;

    // Set the style of the cell.
    @include colours.themed {
        background-color: colours.t("background");
    }

    // Set the border radii.
    //&--rounded {
    //    &-top-left { border-top-left-radius: 1rem; }
    //    &-top-right { border-top-right-radius: 1rem; }
    //    &-bottom-left { border-bottom-left-radius: 1rem; }
    //    &-bottom-right { border-bottom-right-radius: 1rem; }
    //}

    // Set the borders.
    @include colours.themed {
        border-right: solid 1px colours.t("background-dark");
        border-bottom: solid 1px colours.t("background-dark");

        // Remove the border at the bottom and right of the table.
        &--no-bottom-border { border-bottom: unset; }
        &--no-right-border { border-right: unset; }
    }

    // "Hide" the first cell.
    &--hidden {
        @include colours.themed {
            background-color: unset;
            border-right: unset;
            border-bottom: unset;
        }
    }
}
