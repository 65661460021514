/*!
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import the colours and shadows.
@use "scss/colours";
@use "scss/shadows";


/** Set the style of the box header.  */
.button {
    // Set the size of the button.
    height: 2rem; min-width: 2rem;

    // Display the contents in a centered row.
    display: flex; flex-direction: row;
    justify-content: center; align-items: center;
    gap: 0.5rem;

    // Set the colours of the button.
    @include colours.themed {
        &--primary {
            background-color: colours.t("primary");
            color: colours.t("text-light");
        }
        &--secondary {
            background-color: colours.t("secondary");
            color: colours.t("text-light");
        }
        &--danger {
            background-color: colours.t("danger");
            color: colours.t("text-light");
        }
        &--success {
            background-color: colours.t("success");
            color: colours.t("text-light");
        }
        background-color: colours.t("background-dark");
        color: colours.t("text");
    }
    @include shadows.distance;

    // Set the style of the box.
    border-radius: 1rem; padding: 0 0.5rem; border: none;

    // Change the colour when the button is active.
    &:active {
        @include colours.themed {
            background-color: colours.t("primary");
            color: colours.t("text-light");
        }
    }
}


