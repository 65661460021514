/*!
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

@use "scss/colours";


/** Set the style of the favourites list. */
.list {
    // Clear the default styling.
    all: unset;

    // Display the items in a column.
    display: flex; flex-direction: column;
    justify-content: flex-start; align-items: stretch;
    flex-wrap: nowrap; gap: 0.25rem; padding: 0.5rem 0.25rem 6rem;
}

/** Set the style of the items. */
.item {
    // Clear the default styling.
    all: unset;

    // Set the style of the container.
    @include colours.themed {
        background-color: colours.t("background");
    }
}
