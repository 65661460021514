/*!
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

@use "./index.module";
@use "scss/colours";
@use "scss/mixins";


/** Set the style of the audio module. */
.audio {
    @extend .module;

    // Display the contents in the center.
    display: flex; justify-content: center; align-items: center;
    flex-wrap: nowrap; gap: 2rem;
    @include mixins.media-breakpoint-up(md) { flex-direction: row; }
    @include mixins.media-breakpoint-down(md) { flex-direction: column; }

    // Add a slight padding.
    padding: 0 1rem;

    // Increase the gap on larger screens.
    @include mixins.media-breakpoint-up(lg) { gap: 5rem; }


    /** Set the style of the icon. */
    &__icon {
        // Set the size of the icon.
        height: 25vh; max-height: 15rem;
        width: auto;

        // Apply a slight rotation to the icon.
        transform: rotate(2deg);

        // Hide on screens that are too small.
        @include mixins.media-breakpoint-down(md) { display: none; }
    }

    /** Set the style of the inner container. */
    &__container {
        // Display the element in a column.
        display: flex; flex-direction: column;
        justify-content: center; align-items: stretch;
        gap: 1rem; overflow: hidden; flex-wrap: nowrap;
    }

    /** Set the style of the title. */
    &__title { @extend .title; }

    /** Set the style of the progress bar. */
    &__progressbar {
        // Set the height of the bar.
        height: 0.5rem;

        // Set the style of the box.
        @include colours.themed {
            background-color: colours.t("text");
        }
        border-radius: 1rem;

        // Accept pointer events.
        pointer-events: all; cursor: pointer;

        /** Set the style of the value. */
        &__value {
            // Make empty by default.
            height: 100%; width: 0;

            // Set the style of the box.
            @include colours.themed {
                background-color: colours.t("primary");
            }
            border-radius: 1rem;
            cursor: pointer;

            // Add an animation on the progress bar.
            transition: width 33ms ease-in-out;
        }

        /** Set the style of the text. */
        &__text {
            // Spread the contents.
            display: flex; flex-direction: row;
            justify-content: space-between; align-items: center;
            overflow: hidden; flex-wrap: nowrap;
        }
    }

    /** Set the style of the controls. */
    &__controls {
        // Display the controls in a row.
        display: flex; flex-direction: row;
        justify-content: center; align-items: center;
        flex-wrap: nowrap; overflow: hidden; gap: 1rem;

        /** Set the style of the icons. */
        &__button {
            // Use the position as an anchor.
            position: relative;

            // Set the size of the button.
            height: 4rem; width: 4rem;

            // Set the colour of the icon.
            @include colours.themed {
                color: colours.t("text");
            }

            /** Set the style of the icon. */
            &__icon { height: 100%; width: 100%; }

            /** Set the style of the text. */
            &__time {
                // Place in the center of the parent.
                position: absolute; top: 50%; left: 50%;

                // Set the style of the text.
                margin: 0; padding: 0;
                font-size: 0.75rem; font-weight: bold;
            }
            // Adjust the transformation of the back and forward texts.
            &--back &__time { transform: translate(-30%, -50%); }
            &--forward &__time { transform: translate(-50%, -50%); }
        }
    }

    /** Set the style of the audio element. */
    &__audio { display: none; }
}
