/*!
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

@use "scss/mixins";
@use "scss/colours";
@use "./index.module";

/** Set the style of the module. */
.link {
    // Fill the parent.
    flex: 1 1 0; align-self: stretch;

    // Display the contents in a row.
    display: flex; flex-direction: row;
    justify-content: center; align-items: center;
    gap: 2rem; overflow: hidden auto;
    pointer-events: all;

    // Display in a column on small displays.
    @include mixins.media-breakpoint-down(md) {
        flex-direction: column;
    }

    // Set the style of the text container.
    &__container {
        // Display the contents in a column.
        display: flex; flex-direction: column;
        justify-content: center; align-items: center;
        gap: 0.5rem; overflow: hidden; padding: 0 1rem;
    }

    // Set the style of the qr code.
    &__qr {
        max-height: min(12rem, 50vh); max-width: min(12rem, 50vw);

        // Hide on screens that are too small.
        @include mixins.media-breakpoint-down(md) { display: none; }
    }

    // Set the style of the title.
    &__title { @extend .title; }

    // Set the style of the link.
    &__link {
        line-break: anywhere;
        text-align: center;
        @include colours.themed {
            color: colours.t("primary");
            &:hover { color: colours.t("secondary"); }
        }
    }

    // Set the style of the button.
    &__button {
        // Set the size of the button.
        height: 1.5rem; width: auto;

        // Display the contents in a row.
        display: flex; flex-direction: row;
        justify-content: space-between; align-items: center;
        gap: 1rem;

        // Set the style of the box.
        padding: 0 1rem; border-radius: 0.75rem;
        @include colours.themed {
            background-color: colours.t("primary"); color: colours.t("text-light");
            &:hover { background-color: colours.t("secondary"); color: colours.t("text-light"); }
        }

        // Remove the underline.
        text-decoration: none;

        // Set the style of the text.
        &__text { margin: 0; font-size: 1rem; line-height: 1; }

        // Set the style of the icon.
        &__icon { height: 1rem; }
    }
}
