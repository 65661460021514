/*!
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

/** Set the style of the training list. */
.training-list {
    // Fill the parent.
    height: 100%;

    display: flex; flex-direction: column;
    justify-content: flex-start; align-items: stretch;
}

/** Set the style of the header. */
.header {
    // Display in a column.
    display: flex; flex-direction: column;
    justify-content: flex-start; align-items: stretch;
    z-index: 1;

    // Fix at the top of the page.
    position: sticky; left: 0; top: 4.125rem; right: 0;

    // Set the style of the background.
    background-color: white;
}

/** Set the style of the selector container. */
.selector-container {
    // Display the button in the center.
    display: flex; flex-direction: column;
    justify-content: flex-start; align-items: flex-start;
    padding: 0.25rem 0.25rem 0;
}

/** Set the style of the footer. */
.footer {
    // Fix at the bottom of the page.
    position: fixed; left: 0; bottom: 0; right: 0;
}
