/*!
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */


/** Set the style of the progress container. */
.container {
    // Display the elements in a column.
    display: flex; flex-direction: column;
    justify-content: center; align-items: stretch;
}
