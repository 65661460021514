/*!
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

@use "scss/colours";
@use "scss/shadows";
@use "scss/mixins";


/** Set the style of the widget. */
.widget {
    // Fill the parent.
    position: relative; flex: 0 0 100%;

    // Change the width of the half-size widgets.
    &--half-size { flex: 0 0 calc(50% - 0.5rem); }

    // Display the contents in a column.
    display: flex; flex-direction: column;
    justify-content: flex-start; align-items: stretch;

    // Set the style of the background.
    @include colours.themed {
        background-color: colours.t("background");
    }
}

/** Set the style of the body. */
.body {
    // Add a max height on larger screens.
    max-height: 24rem; overflow: hidden;
    // Fill the parent.
    flex: 1 1 auto;

    // Allow scrolling.
    .widget--scrolling & { overflow: hidden auto; }

    // Display below the header.
    z-index: 0;

    // Display the contents in a column.
    display: flex; flex-direction: column;
    justify-content: flex-start; align-items: stretch;

    // Set the style of the background.
    @include colours.themed {
        background-color: colours.t("page-background");
    }

    // Set the style of the scrollbar.
    &::-webkit-scrollbar {
        // Set the width of the scrollbar background.
        border-radius: 0.5rem; width: 0.5rem;

        // Set the colour of the background.
        @include colours.themed {
            background-color: colours.t("text");
            box-shadow: inset 0 0 0.125rem transparentize(colours.t("text-light"), 0.5);
        }
    }

    // Set the style of the scrollbar thumb.
    &::-webkit-scrollbar-thumb {
        // Set the width of the scrollbar background.
        border-radius: 0.5rem;

        @include colours.themed {
            background-color: colours.t("primary");
            box-shadow: inset 0 0 0.125rem transparentize(colours.t("text-light"), 0.75);
        }
    }
}
