/*!
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

@use "scss/mixins";


/** Set the style of the icons. */
.level {
    // Set the size of the icon.
    height: 2rem; width: 2rem;

    // Change the size on smaller displays.
    @include mixins.media-breakpoint-down(sm) {
        height: 1.5rem; width: 1.5rem;
    }
}
