/*!
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

/** Set the style of the container. */
.container {
    // Fill the parent.
    position: relative;

    // Display the cards in the center.
    display: flex; flex-direction: column;
    justify-content: center; align-items: center;
    gap: 0.5rem; overflow: visible;
}
