/*!
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

@use "scss/colours";


/** Set the style of the row header. */
.row-header {
    // Add some inside padding.
    padding: 0 1rem; border-radius: inherit;

    // Set the style of the text.
    font-style: italic; white-space: nowrap;
    font-size: 0.875rem;

    // Set the style of the links.
    &:is(a) {
        @include colours.themed {
            color: colours.t("primary");
            &:hover { color: colours.t("secondary"); }
        }
    }
}

/** Set the style of the header cells. */
.head {
    // Fill the parent.
    flex: 1 1 0; align-self: stretch;
    border-radius: inherit; min-width: 8rem;

    // Display the contents in a row.
    display: flex; flex-direction: row;
    justify-content: center; align-items: center;
    gap: 0.5rem; padding: 0 1rem;

    // Set the style of the text.
    white-space: nowrap;
    @include colours.themed {
        color: colours.t("secondary");
    }
}

/** Set the style of the item cells. */
.cell {
    // Fill the parent.
    flex: 1 1 0; align-self: stretch;
    border-radius: inherit;

    // Display the contents in a row.
    display: flex; flex-direction: row;
    justify-content: space-between; align-items: center;
    gap: 0.5rem; padding: 0 1rem;

    // Set the style of the N/A cells.
    &--not-applicable {
        @include colours.themed {
            background-color: colours.t("background-dark");
        }
    }
}

/** Set the style of the lock. */
.lock {
    // Set the size of the element.
    height: 1rem; width: 1rem;

    // Set the colour of the icon.
    @include colours.themed {
        color: colours.t("background-dark");
    }
}


/** Set the style of the checkmarks. */
.checkmark {
    // Hide by default.
    display: none;

    // Set the size of the element.
    height: 1rem; width: 1rem;

    // Set the style of the element.
    border-radius: 100%; padding: 0.25rem;

    /** Set the style of the objective met icon. */
    &--objective-met {
        display: block;
        @include colours.themed {
            color: colours.t("success");
            border: solid 2px colours.t("success");
        }
    }

    /** Set the style of the validated icon. */
    &--validated {
        display: block;
        @include colours.themed {
            color: colours.t("text-light");
            border: solid 2px colours.t("success");
            background-color: colours.t("success");
        }
    }
}

/** Set the style of the level. */
.level {
    // Set the size of the element.
    height: 2rem; width: 2rem;

    .cell--complete & {
        @include colours.themed {
            color: colours.t("background");
        }
    }
}
