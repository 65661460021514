/*!
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import the app colours.
@use "css/colours";

/** Set the style of the title. */
.title {
    text-align: start; font-size: 2.5rem; font-weight: 700;
    color: colours.$blue-dark;
    white-space: break-spaces;
}
