/*!
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import the math module.
@use "sass:math";
// Import the color module.
@use "sass:color";

// Import the colours.
@use "css/colours";
// Import the shadows.
@use "css/shadows";

// Define the properties of the loading gradient.
$gradient: (rotation: 135deg, width: 1rem, alternate-rotation: 45deg);
// Define the properties of the gradient's background image.
$gradient-image: (width: 8 * map-get($gradient, "width"), height: 1rem);
// Interval used to smooth out the gradient repeat interval.
$gradient-repeat-interval: math.div(map-get($gradient-image, "width"), math.sin(map-get($gradient, "rotation")));


/** Helper method used to build the linear gradients. */
@function make-linear-gradient($foreground: black, $background: white, $alternate: false) {
    $rotation: map-get($gradient, "rotation");
    @if ($alternate) { $rotation: map-get($gradient, "alternate-rotation"); }

    @return repeating-linear-gradient(
        $rotation,
        $background 0,
        $background math.div(13 * map-get($gradient, "width"), 24),
        $foreground math.div(13 * map-get($gradient, "width"), 24),
        $foreground map-get($gradient, "width")
    );
}

/** Set the style of the loading bar. */
.loading-bar {
    // Display the bar at the start of the container.
    display: flex; flex-direction: row;
    justify-content: flex-start; align-items: stretch;
    overflow: hidden;
    &--downloading { justify-content: flex-end; }

    // Set the size of the bar.
    width: map-get($gradient-image, "width"); height: map-get($gradient-image, "height");

    // Set the style of the box.
    background-color: white; border-radius: 0.5rem;
    box-shadow: shadows.box-shadow();

    // Set the style of the unknown bar.
    &--unknown &__bar { background-color: colours.$tertiary-colour; }

    // Set the style of the waiting bar.
    &--preparing &__bar {
        background-image: make-linear-gradient(
            color.scale(colours.$dark-text-colour, $lightness: 25%),
            color.scale(colours.$dark-text-colour, $lightness: 50%)
        );
        animation: unset;
    }

    // Set the style of the queued bar.
    &--queued &__bar {
        background-image: make-linear-gradient(
            color.scale(colours.$dark-text-colour, $lightness: 70%),
            color.scale(colours.$dark-text-colour, $lightness: 85%)
        );
    }

    // Set the style of the uploading bar.
    &--uploading &__bar, &--converting &__bar {
        background-image: make-linear-gradient(
            colours.$blue,
            color.scale(colours.$blue, $lightness: 33%)
        );
    }

    // Set the style of the downloading bar.
    &--downloading &__bar {
        background-image: make-linear-gradient(
            colours.$high-colour,
            color.scale(colours.$high-colour, $lightness: 33%),
            true
        );
        animation-direction: reverse;
    }

    // Set the style of the downloading bar.
    &--storing &__bar {
        background-image: make-linear-gradient(
            colours.$high-colour,
            color.scale(colours.$high-colour, $lightness: 33%),
            true
        );
        animation-direction: reverse;
    }

    // Set the style of the done bar.
    &--done &__bar {
        background-image: make-linear-gradient(
            colours.$high-colour,
            color.scale(colours.$high-colour, $lightness: 33%),
            true
        );
        animation: unset;
    }

    // Animate the bar.
    &__bar {
        @keyframes background-movement {
            from { background-position-x: 0; }
            to { background-position-x: $gradient-repeat-interval; }
        }
        animation: background-movement linear 4s infinite;
        background-size: $gradient-repeat-interval $gradient-repeat-interval;
        border-radius: 0.5rem; border: solid 1px colours.$dark-text-colour;
    }
}
