/*!
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

/** Set the style of the loader. */
.loader {
    // Set the size of the loader.
    height: 1rem; width: 1rem;

    // Clear the padding of the container.
    &__container { padding: 0; }
}
