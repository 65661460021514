/*!
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

@use "scss/colours";


/** Set the style of the progress bar. */
.progress-bar {
    // Set the size of the bar.
    height: 1rem; width: 50vw; max-width: 10rem;

    // Set the style of the box.
    @include colours.themed {
        background: colours.t("text");
    }
    border-radius: 0.5rem;
    padding: 0.25rem;

    /** Set the style of the progress bar state.*/
    &__state {
        // Set the size of the state.
        height: 0.5rem; min-width: 0.5rem;

        // Set the style of the box.
        background-color: #FFFFFF;
        border-radius: 0.25rem;

        // Add a transition to the width.
        transition: width ease-in-out 250ms;
    }
}
