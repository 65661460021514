/*!
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import the colours.
@import "../../../../assets/src/css/colours";

/** Set the style of the header. */
.header {
    /** Set the style of the link. */
    &__link {
        // Display the contents in a row.
        display: flex; flex-direction: row;
        justify-content: flex-start; align-items: center;

        // Remove the width override.
        width: auto; height: 2rem;

        // Remove the underline.
        text-decoration: unset;

        // Make the text not selectable.
        user-select: none;

        /** Set the style of the icon. */
        &__chevron { height: 1.5rem; filter: $blue-dark-filter; margin-right: 0.5rem; }
        &:hover &__chevron { filter: $blue-filter; }

        /** Set the style of the text. */
        &__text {
            // Set the style of the text.
            color: $primary-colour !important;
            min-height: 0 !important;
            text-align: center; font-weight: bold;
            font-size: 1.5rem;

            // Remove the default margin.
            margin-bottom: 0;
        }
        &:hover &__text { color: $secondary-colour !important; }
    }

    /** Set the style of the switch. */
    &__switch {
        // Change the background.
        background-color: white;
        border-radius: 2rem; padding: 0.25rem 0.25rem 0.25rem 0.5rem;
        box-shadow: 0.25rem 0.25rem 0.5rem $tertiary-colour;

        // Display on top of the rest.
        z-index: 1000; position: fixed;
        top: 4rem; right: 1rem;
    }

    /** Set the style of the deletion button. */
    &__bin {
        // Remove the default styling.
        &:active, &:focus, &:hover, & { background-color: unset; outline: none; box-shadow: none; border: none; }

        // Set the padding/margin;
        margin-left: 0.5rem; padding: 0.25rem 0.5rem;
        width: 3rem; height: 2rem;

        // Roundify the border.
        border-radius: 1rem;

        // Display the image in the center.
        display: flex; justify-content: center; align-items: center;

        // Change the background when hovered.
        &:hover { background-color: $low-colour; }
        &:hover &__icon { filter: unset; }

        /** Set the style of the icon. */
        &__icon { height: 100%; filter: $low-colour-filter; }
    }

    /** Set the style of the organisation logo. */
    &__logo { filter: $tertiary-colour-filter; width: 15rem; }

    /** Set the style of the standalone header. */
    &__standalone {
        // Display the contents in a row.
        display: flex; flex-direction: row;
        justify-content: space-between; align-items: end;

        // Set the style of the link.
        &__powered-by {
            // Display the contents in a row.
            display: flex; flex-direction: row;
            justify-content: flex-end; align-items: center;

            // Set the style of the text.
            font-size: 0.75rem;
            color: $dark-text-colour; font-style: italic;
            text-decoration: none; white-space: nowrap;
            line-height: 1;

            // Set the style of the image.
            &__icon {
                user-select: none; pointer-events: none; -webkit-user-select: none;
                height: 1.5rem; margin-left: 0.25rem; margin-bottom: 0.35rem;
                &--gray { display: block; } &--colour { display: none; }
            }
            &:hover &__icon {
                &--gray { display: none; } &--colour { display: block; }
            }
        }
    }
}
