/*!
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import the colours.
@import "css/colours";

/** Set the style of the file body. */
.body {
    position: relative;

    // Roundify the bottom border.
    border-bottom-left-radius: 1.5rem;
    border-bottom-right-radius: 1.5rem;
    > * {
        border-bottom-left-radius: 1.5rem;
        border-bottom-right-radius: 1.5rem;
    }

    // Set the style of the hidden body.
    max-height: 80vh;
    &--hidden { max-height: 0; padding: 0; }

    padding: 0;

    // Hide any overflow.
    overflow: hidden;

    // Set the colour of the background.
    background-color: white;
    &:before {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 0.25rem;
        content: "";
        box-shadow: inset 0 0.25rem 0.25rem -0.25rem $tertiary-colour;
        z-index: 1;
    }

    // Render the contents in the center.
    display: flex; flex-direction: column;
    justify-content: center; align-items: center;

    // Force all the sub-elements to fit.
    > * { max-width: 100%; max-height: 100%; }
    /** Set the style of the unsupported flag. */
    > p { color: white; text-align: center; margin: 1rem 0; font-weight: bold; font-style: italic; }
    /** Change the cursor for the selectable elements. */
    &--selectable { cursor: pointer; }

    /** Set the style of the containers. */
    &__container {
        position: relative; overflow: hidden;
        display: flex; flex-direction: row;
        justify-content: flex-start; align-items: flex-start;
        align-self: stretch; padding: 0;

        // Force the height of the loading item.
        &--loading { height: 30rem; }
    }

    /** Set the style of the text. */
    &__text {
        width: 100%; color: $blue;
        flex: 1 1 0; resize: none; padding: 0.5rem 1rem 1rem;
        min-height: 5rem;

        // Remove the default styling.
        &:active, &:hover, &:focus, & { background-color: unset; outline: unset; border: none; }
        &::placeholder { color: $low-colour; font-weight: normal; font-style: italic; }

        &--hidden { display: none; }
    }

    /** Set the style of the selectable element. */
    &__selectable {
        flex: 1 1 auto; align-self: stretch;
        cursor: pointer;
        // Remove the default styling.
        &:active, &:hover, &:focus, & { background-color: unset; outline: unset; border: none; }

        // Display the contents in the center.
        display: flex; flex-direction: column;
        justify-content: center; align-items: center;
        overflow: hidden;
        img {
            max-height: 80vh;
            width: 100%;
            object-fit: contain;
        }
    }

    // Set the style of the conversion element.
    &__conversion {
        position: absolute; top: 0; left: 0.5rem;
    }
}

// Set the style of the help tool.
.markdown-helper {
    // Display the items in a row.
    display: flex; flex-direction: row;
    justify-content: flex-start; align-items: center;
    gap: 0.125rem;

    // Set the style of the text.
    padding: 0; margin: 0;
    font-size: 0.75rem;

    // Set the style of the variants.
    &--title { font-size: 1rem; margin-bottom: 0.25rem; }
    &--symbol { font-family: monospace; letter-spacing: 0.125rem; }
    &--italic { font-style: italic; }
    &--bold { font-weight: bold; }
    &--underline { text-decoration: underline; }
    &--strikethrough { text-decoration: line-through; }

    // Set the style of the icon.
    &__icon {
        // Align to the end.
        position: absolute; right: 0.5rem; bottom: 0.5rem;
        height: 1.5rem; width: 1.5rem; object-fit: contain;

        // Set the style of the box.
        background-color: white; border-radius: 100%;
        padding: 0.125rem; border: solid 2px $blue;
    }

    // Set the style of the icon.
    &__container {
        // Display the elements in a column.
        display: flex; flex-direction: column;
        justify-content: center; align-items: flex-start;

        // Clear the default style.
        list-style: none; padding: 0.25rem; margin: 0;

        // Set the style of the box.
        background-color: white; border-radius: 0.5rem;
        box-shadow: 0.25rem 0.25rem 0.5rem $tertiary-colour;
        border: none;
    }
}

.speech-button { z-index: 1; margin-top: 0.5rem; }
