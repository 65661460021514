/*!
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

@use "../../../../assets/src/css/colours";

/** Set the style of the drop target. */
.drop-target {
    // Place above everything else.
    position: fixed; top: 0; right: 0; bottom: 0; left: 0;
    pointer-events: none; z-index: 10000;

    // Set the style of the background.
    background-color: transparentize(colours.$high-colour, 0.33);

    // Set the style of the box.
    &__box {
        // Fill the parent.
        position: absolute; top: 1rem; right: 1rem; bottom: 1rem; left: 1rem;
        // Display the image in the center.
        display: flex; justify-content: center; align-items: center;

        // Add a border.
        border: dashed 1.5rem white; border-radius: 8rem;
    }

    // Set the style of the image.
    &__add {
        max-height: 25vh; max-width: 25vw;
        height: 100%; width: 100%;
    }

    // Set the style of the hidden element.
    &--hidden { background-color: transparent; }
    &--hidden &__box { display: none; }
    &--hidden &__add { display: none; }
}
