/*!
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

@use "scss/shadows";

/** Set the style of the popper. */
.popper {
    // Hide any overflow.
    overflow: hidden;

    // Display on top of the parent.
    z-index: 11;

    // Add a slight shadow to detach from the parent.
    @include shadows.distance;
}
