/*!
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

@use "scss/colours";


/** Set the style of the message. */
.message {
    // Display the contents in a column.
    display: flex; flex-direction: column;
    justify-content: center; align-items: center;
    gap: 1rem;

    // Set the style of the box.
    font-size: 1.5rem;
    @include colours.themed {
        color: colours.t("secondary");
    }

    // Set the size of the icon.
    &__icon { height: 8rem; }
}
