/*!
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import the colours.
@import "../../../../assets/src/css/colours";
// Import the mixins.
@import "../../../../assets/src/css/mixins";
@import "../../../../assets/src/css/shadows";

/** Set the style of the creation menu. */
.create-menu {
    img { pointer-events: none; }

    // Place at the bottom right of the screen.
    position: fixed; bottom: 2vw; right: 4vw;
    z-index: 1000;

    // Display the contents in a column.
    display: flex; flex-direction: column-reverse;
    justify-content: flex-end; align-items: flex-end;
    flex-wrap: nowrap; overflow: visible;

    /** Set the style of the anchor. */
    &__anchor {
        // Set the size of the button.
        height: 4rem; width: 4rem;
        @include media-breakpoint-up(lg) { height: 4rem; width: 4rem; }
        // Set the visual style of the button.
        background: white; padding: 0; margin-right: calc(0.25rem + 1px);
        border-radius: 100%;
        img { filter: $high-colour-filter; }
        border: none;
        z-index: 1;

        // Fit the image in the center.
        display: flex; justify-content: center; align-items: center;
        img { width: 100%; height: 100%; }

        // Add a transform transition.
        img { transition: transform 0.1s ease-in-out; }
    }
    &--open &__anchor img { transform: rotateZ(45deg); }

    /** Set the style of the list. */
    &__list {
        // Display the contents in a column.
        display: flex; flex-direction: column;
        justify-content: flex-start; align-items: stretch;
        flex-wrap: nowrap; overflow: hidden auto;

        margin-bottom: -2.5rem; margin-right: 3rem;

        // Set the colour of the background.
        background-color: white;
        -webkit-backdrop-filter: blur(0.25rem);
        border-radius: 1rem;
        // add shadow
        box-shadow: $regular-box-shadow;
        height: 0;
        transition: height 0.1s linear, border 0.1s step-end;
    }
    &--open &__list {
        height: auto;
        max-height: 80vh;
        border-width: 2px;
        transition: height 0.1s ease-in-out, border 0.1s step-start;
    }

    /** Set the style of the buttons. */
    &__create {
        // Set the size of the button.
        height: 4.5rem;
        @include media-breakpoint-up(lg) { height: 5.5rem; }

        // Set the visual style of the button.
        padding: 0 0.5rem 0 0;
        img { filter: $high-colour-filter; }
        border: none; background-color: unset;

        // Fit the contents at the end.
        display: flex; flex-direction: row;
        justify-content: flex-end; align-items: center;

        img { height: 4rem; }
        @include media-breakpoint-up(lg) { img { height: 5rem; } }

        // Set the style of the text.
        p {
            color: $primary-colour;
            font-size: 1.125rem;
            flex: 1 1 0; align-self: stretch;
            display: flex; justify-content: flex-end; align-items: center;
            padding: 0 0.5rem; margin: 0 0.25rem 0 0;
            border-bottom: solid 2px $secondary-colour;
        }
        &:first-child p { border-bottom-color: transparent; }
    }
}
