/*!
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

@use "common";

/** Set the style of the copy button */
.button {
    // Extend the base button style.
    @include common.button;

    // Hide when the link is inactive or expired.
    :global(.share-link--expired) &, :global(.share-link--inactive) & {
        display: none;
    }
}
