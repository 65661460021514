/*!
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

@use "scss/colours";
@use "scss/shadows";


/** Set the style of the selector. */
.selector {
    // Clear the default styling.
    all: unset;

    // Stick to the top of the parent.
    position: sticky; top: 0; z-index: 2;

    // Display the elements in a scrolling row.
    display: flex; flex-direction: row;
    justify-content: flex-start; align-items: center;
    flex-wrap: nowrap; overflow: auto hidden; gap: 1rem;

    // Set the style of the box.
    padding: 0.5rem 1rem 0.25rem;
    @include colours.themed {
        background-color: colours.t("background");
    }
}

/** Set the style of the items. */
.item {
    // Clear the default styling.
    all: unset;

    // Change the cursor on hover.
    cursor: pointer; user-select: none;

    // Set the style of the text.
    font-weight: 300; font-size: 1.25rem; line-height: 1;
    @include colours.themed {
        color: colours.t("background-dark");
    }

    // Set the style of the active item.
    &[aria-pressed^="true"] {
        // Change the cursor.
        cursor: default;

        // Set the style of the text.
        font-weight: 500;
        @include colours.themed {
            color: colours.t("primary");
        }
    }
}
