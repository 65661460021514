/*!
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

@use "sass:color";
@use "scss/colours";
@use "scss/shadows";

/** Set the style of the delete button. */
.delete-button {
    // Set the style of the button's box.
    margin: 0 0 0 0.5rem; padding: 0.375rem;
    border-radius: 1rem;

    &__icon { width: 1rem; height: 1rem; }

    // Display the icon in the center.
    display: flex; justify-content: center; align-items: center;

    // Set the colours of the button.
    @include colours.themed {
        border: solid 1px colours.t("danger");
        color: colours.t("danger");
        background-color: colours.t("background");
    }
    &:hover {
        @include colours.themed {
            color: colours.t("background");
            background-color: colours.t("danger");
        }
    }
}

