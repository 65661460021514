/*!
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

@use "scss/colours";

// Set the style of the list.
.list {
    // Clear the default styling.
    all: unset;

    // Fill the parent.
    flex: 0 0 auto;

    // Display the elements in a column.
    display: flex; flex-direction: column;
    justify-content: flex-start; align-items: stretch;
    overflow: hidden auto; gap: 0.125rem;
}

/** Set the style of the items. */
.item {
    // Fill the parent.
    flex: 0 0 1.5rem;

    // Display the contents in a row.
    display: flex; flex-direction: row;
    justify-content: flex-start; align-items: center;
    gap: 0.5rem;

    // Add some inside padding.
    padding: { left: 1rem; right: 0.5rem; }

    // Set the style of the selected element.
    &--selected {
        font-style: italic; text-decoration: underline;
        @include colours.themed {
            color: colours.t("secondary");
        }
    }

    /** Set the style of the item title. */
    &__title { flex: 1 1 0; }
}
