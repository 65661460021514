/*!
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

@use "scss/colours";
@use "scss/shadows";
@use "scss/mixins";
@use "sizes";

/** Set the style of the header. */
.header {
    // Display the contents in a grid.
    display: grid;
    grid-template-columns:
        sizes.sizeof("title")
        sizes.sizeof("due-date")
        sizes.sizeof("expected-level")
        sizes.sizeof("achieved-level")
        sizes.sizeof("status");
    grid-template-rows: auto;
    grid-template-areas: "title due-date expected achieved status";
    align-items: end; justify-items: center;
    gap: 0.5rem; padding: 0.25rem 1.25rem 0.25rem 0.75rem;

    // Change the sizes on smaller displays.
    @include mixins.media-breakpoint-down(sm) {
        grid-template-columns:
        sizes.sizeof("title", "sm")
        sizes.sizeof("due-date", "sm")
        sizes.sizeof("expected-level", "sm")
        sizes.sizeof("achieved-level", "sm")
        sizes.sizeof("status", "sm");
        grid-template-areas: "title due-date level level status";
        padding: 0.25rem 0.75rem 0.25rem 0.75rem;
    }

    // Show in front of the siblings.
    z-index: 1;

    // Set the style of the background.
    @include colours.themed {
        background-color: colours.t("background");
    }

    // Set the style of the text.
    font-size: 0.875rem; text-align: center;
    @include colours.themed { color: colours.t("text"); }
    @include shadows.bottom;

    // Make the text smaller on mobile devices.
    @include mixins.media-breakpoint-down(md) {
        font-size: 0.75rem;
    }
}

/** Set the style of the title. */
.title { grid-area: title; justify-self: start; }

/** Set the style of the due-date. */
.due-date { grid-area: due-date; }

/** Set the style of the expected level. */
.expected {
    grid-area: expected;

    // Hide on smaller screens.
    @include mixins.media-breakpoint-down(sm) { display: none; }
}

/** Set the style of the achieved level. */
.achieved {
    grid-area: achieved;

    // Hide on smaller screens.
    @include mixins.media-breakpoint-down(sm) { display: none; }
}

/** Set the style of the level. */
.level {
    grid-area: level;
    // Hide by default.
    display: none;

    // Show on smaller screens.
    @include mixins.media-breakpoint-down(sm) { display: block; }
}

/** Set the style of the status. */
.status { grid-area: status; }
