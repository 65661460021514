/*!
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

/** Set the style of the button list. */
.buttons {
    display: flex; flex-direction: column;
    justify-content: flex-start; align-items: center;
    gap: 0.25rem;
}
