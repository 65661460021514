/*!
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

@use "scss/colours";
@use "scss/shadows";
@use "scss/mixins";


/** Set the style of the backdrop. */
.backdrop {
    // Hide by default.
    display: none;

    // Render on top of the toolbar.
    z-index: 2;

    // Fit in the viewport.
    position: fixed; inset: 0;

    // Set the style of the background.
    background-color: #0008;
    backdrop-filter: blur(0.5rem);
    -webkit-backdrop-filter: blur(0.5rem);

    // Show when the menu is open.
    &--visible { display: block; }
}

/** Set the style of the summary. */
.summary {
    // Render on top of the toolbar.
    z-index: 2;

    // Render at the right of the display.
    position: fixed; right: -100%; top: 0; bottom: 0;
    width: min(32rem, 100%);

    // Add the navbar offset if it is present.
    :global(.with-navbar) & { top: 3rem; }

    &--open { right: 0; }
    transition: right 0.25s ease-in-out;

    // Display the elements in a column.
    display: flex; flex-direction: column;
    justify-content: flex-start; align-items: stretch;

    // Set the style of the box.
    padding: 0;
    @include colours.themed { background-color: colours.t("background"); }

    // Set the style of the header.
    &__header {
        // Display the elements in a row.
        display: flex; flex-direction: row;
        justify-content: space-between; align-items: center;
        padding: 0.5rem;

        @include shadows.bottom;
    }

    // Set the style of the title.
    &__title {
        // Fill the parent.
        flex: 1 1 0;

        // Display the contents in a column.
        display: flex; flex-direction: column;
        justify-content: center; align-items: center;

        // Set the style of the text.
        font-size: 1.5rem; font-weight: normal; line-height: 1;
        @include colours.themed { color: colours.t("secondary"); }
    }

    // Set the style of the close button.
    &__close {
        // Set the size of the button.
        height: 2rem; width: 2rem;

        // Display the image in the center.
        display: flex; justify-content: center; align-items: center;

        // Set the style of the box.
        border-radius: 1rem; padding: 0.25rem;
        @include colours.themed {
            background-color: colours.t("background");
            color: colours.t("danger");
            border: solid 2px colours.t("danger");

            &:active {
                background-color: colours.t("danger");
                color: colours.t("text-light");
            }
        }

        // Make the icon fill the button.
        &__icon { height: 100%; width: 100%; }
    }

    // Set the style of the list.
    &__container {
        // Display the contents in a column.
        display: flex; flex-direction: column;
        justify-content: flex-start; align-items: stretch;
        gap: 0.5rem; overflow: hidden auto;

        // Set the style of the box.
        list-style: none; padding: 1rem; margin: 0;

        // Set the style of the scrollbar.
        &::-webkit-scrollbar {
            // Set the width of the scrollbar background.
            border-radius: 0.5rem; width: 0.5rem;

            // Set the colour of the background.
            @include colours.themed {
                background-color: colours.t("text");
                box-shadow: inset 0 0 0.125rem transparentize(colours.t("text-light"), 0.5);
            }
        }

        // Set the style of the scrollbar thumb.
        &::-webkit-scrollbar-thumb {
            // Set the width of the scrollbar background.
            border-radius: 0.5rem;

            @include colours.themed {
                background-color: colours.t("primary");
                box-shadow: inset 0 0 0.125rem transparentize(colours.t("text-light"), 0.75);
            }
        }
    }
}

/** Set the style of the items. */
.summary-item {
    // Set the height of the element.
    $height: 2.5rem;
    $padding-v: 0.25rem;
    //height: $height;

    // Set the style of the button.
    &__button {
        // Fill the parent.
        height: 100%; width: 100%;

        // Display the contents in a row.
        display: flex; flex-direction: row;
        justify-content: flex-start; align-items: center;
        overflow: hidden; flex-wrap: nowrap; gap: 1rem;

        // Set the style of the box.
        border-radius: 1.5rem; padding: $padding-v 1rem;
        @include colours.themed {
            border: solid 2px colours.t("primary");
            color: colours.t("primary")
        }

        .summary-item &:hover {
            @include colours.themed {
                border-color: colours.t("primary");
                background-color: colours.t("primary");
                color: colours.t("text-light");
            }
        }

        .summary-item--title & {
            @include colours.themed {
                border-color: colours.t("secondary");
                color: colours.t("secondary")
            }
        }
    }

    // Set the style of the selected item.
    &--selected &__button {
        @include colours.themed {
            background-color: colours.t("primary");
            color: colours.t("text-light");
        }
    }

    // Set the style of the indented item.
    &--indented { margin-left: 2rem; }

    // Set the style of the title item.
    &--title {
        // Add some gap between the sections.
        &:not(:first-child) { margin-top: 2rem; }

        // Make the gap smaller.
        .summary-item__button { gap: 0.5rem; }

        // Set the text in bold.
        .summary-item__text { font-weight: 600; }

        // Increment the chapter counter.
        counter-increment: chapter;
    }

    // Set the style of the icons.
    &__icon {
        // Fill the container.
        height: 100%;

        // Hide in the title chapters.
        .summary-item--title & { display: none; }

        /** Set the style of the container. */
        &__container {
            // Set the size of the container.
            height: $height - ($padding-v * 4); width: 1.5rem;

            // Display the icon in the center.
            display: flex; flex-direction: row;
            justify-content: center; align-items: center;

            .summary-item--title &::after {
                content: counter(chapter) ".";
                font-weight: 600;
            }
        }
    }

    // Set the style of the text.
    &__text {
        // Fill the parent.
        flex: 1 1 0;

        // Set the style of the box.
        padding: 0; margin: 0;

        // Set the style of the text.
        text-align: start;
    }
}
