/*!
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

@use "./index.module";
@use "scss/colours";
@use "scss/mixins";


/** Set the style of the video module. */
.video {
    @extend .module;

    // Use the position as an anchor.
    position: relative;

    // Fill the parent.
    height: 100%;

    /** Set the style of the overlay. */
    &__overlay {
        // Fill the parent.
        position: absolute; top: 0; left: 0; right: 0; bottom: 0;

        // Set the style of the cursor.
        cursor: pointer;

        // Display the controls in a column.
        display: flex; flex-direction: column;
        justify-content: space-between; align-items: stretch;
        overflow: hidden; flex-wrap: nowrap; gap: 0.5rem;

        // Add an opacity to the background.
        @include colours.themed {
            background-color: transparentize(colours.t("text"), 0.25);
        }

        // Change the opacity when visible or invisible.
        transition: opacity 0.2s linear;
        &--visible { opacity: 1; }
        &--hidden { opacity: 0; }
    }

    /** Set the style of the title. */
    &__title {
        @extend .title;
        @extend .module;

        // Set the colour of the text.
        @include colours.themed {
            color: colours.t("text-light");
        }

        // Set the size of the title.
        height: 6rem; overflow: hidden auto;

        /** Set the style of the container. */
        &__container {
            // Display the text in the center.
            display: flex; justify-content: center; align-items: center;
            padding: 0.25rem 0;

            // Generate a background gradient.
            border-radius: 1rem 1rem 0 0;
            @include colours.themed {
                background: linear-gradient(
                        180deg,
                        black,
                        transparentize(black, 0.25) 50%,
                        transparentize(black, 0.5) 75%,
                        transparent 100%
                );
            }
        }
    }

    /** Set the style of the progress bar. */
    &__progressbar {
        // Set the height of the bar.
        height: 0.5rem; flex: 1 1 0;

        // Set the style of the box.
        @include colours.themed {
            background-color: colours.t("text-light");
        }
        border-radius: 1rem;

        /** Set the style of the container. */
        &__container {
            // Add some surrounding margins.
            margin: 0 3.5rem 0.5rem;

            // Display the contents in a column.
            display: flex; flex-direction: column;
            justify-content: center; align-items: stretch;
        }
        /** Set the style of the bar container. */
        &__bar-container {
            // Display the contents in a row.
            display: flex; flex-direction: row;
            justify-content: flex-start; align-items: center;
            gap: 1rem; padding-left: 2rem;
        }

        // Accept pointer events when the overlay is visible.
        pointer-events: none;
        .video__overlay--visible & {
            pointer-events: all; cursor: pointer;
        }

        /** Set the style of the value. */
        &__value {
            // Make empty by default.
            height: 100%; width: 0;

            // Set the style of the box.
            @include colours.themed {
                background-color: colours.t("primary");
            }
            border-radius: 1rem;
            cursor: pointer;

            // Add an animation on the progress bar.
            transition: width 33ms ease-in-out;
        }

        /** Set the style of the text. */
        &__text {
            // Add the button offset.
            margin: 0 2rem;

            // Spread the contents.
            display: flex; flex-direction: row;
            justify-content: space-between; align-items: center;
            overflow: hidden; flex-wrap: nowrap;

            // Set the colour of the text.
            @include colours.themed {
                color: colours.t("text-light");
            }
        }

        /** Set the style of the fullscreen button. */
        &__fs {
            flex: 0 0 1.5rem; align-self: center;
            height: 1.5rem;

            // Set the style of the box.
            padding: 0; margin: 0;
            @include colours.themed { color: colours.t("text-light"); }

            // Set the style of the icon.
            &__icon { height: 100%; width: 100%; object-fit: contain; }
        }
    }

    /** Set the style of the controls. */
    &__controls {
        // Display the controls in a row.
        display: flex; flex-direction: row;
        justify-content: center; align-items: center;
        flex-wrap: nowrap; overflow: hidden; gap: 1rem;

        // Increase the gap on larger devices.
        @include mixins.media-breakpoint-up(sm) { gap: 4rem; }

        /** Set the style of the icons. */
        &__button {
            // Use the position as an anchor.
            position: relative;

            // Display the icon in the center.
            display: flex; flex-direction: row;
            justify-content: center; align-items: center;
            overflow: hidden;

            // Accept pointer events when the overlay is visible.
            pointer-events: none;
            .video__overlay--visible & {
                pointer-events: all; cursor: pointer;
            }

            // Set the size of the button.
            height: 100%;
            max-height: 8rem; max-width: 8rem;

            // Set the colour of the icon.
            @include colours.themed {
                color: colours.t("text-light");
            }

            /** Set the style of the icon. */
            &__icon { height: 90%; width: 90%; object-fit: contain; }

            /** Set the style of the text. */
            &__time {
                // Place in the center of the parent.
                position: absolute; top: 50%; left: 50%;

                // Set the style of the text.
                margin: 0; padding: 0;
                font-size: 1.25rem; font-weight: bold;
            }
            // Adjust the transformation of the back and forward texts.
            &--back &__time { transform: translate(-30%, -50%); }
            &--forward &__time { transform: translate(-50%, -50%); }
        }
    }

    /** Set the style of the video element. */
    &__video { position: absolute; width: 100%; height: 100%; object-fit: contain; background-color: black; }
}
