/*!
 * Copyright © 2021-2022 - Zimproov.
 * All rights reserved.
 */

@use "scss/mixins";
@import "common";


// Set the style of the text.
.text {

    // Add the padding if needed.
    &--with-padding {
        @include mixins.media-breakpoint-up(md)   { padding-left  : 0.5rem; }
        @include mixins.media-breakpoint-down(md) { padding-bottom: 0.5rem; }
    }

    // Hide the optional section on smaller screens.
    &__optional {
        @include mixins.media-breakpoint-down(md) { display: none; }
    }
}
