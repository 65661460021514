/*!
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

@use "scss/colours";


/** Set the style of the button. */
.button {
    // Clear the default style.
    all: unset;

    // Display the icon in the center.
    display: flex; flex-direction: column;
    justify-content: center; align-items: center;
    padding: 0.25rem;

    // Set the style of the button.
    border-radius: 1rem;

    // Hide when disabled.
    &:disabled { display: none; }

    // Set the style of the primary button.
    &--primary {
        @include colours.themed {
            border: solid 1px colours.t("primary");
            color: colours.t("primary");
            background-color: colours.t("background");

            // Change the hovered style.
            &:hover {
                color: colours.t("text-light");
                background-color: colours.t("primary");
            }

            // Change the active style.
            &:active {
                color: colours.t("text-light");
                background-color: colours.t("secondary");
            }
        }
    }

    // Set the style of the secondary button.
    &--danger {
        @include colours.themed {
            border: solid 1px colours.t("danger");
            color: colours.t("danger");
            background-color: colours.t("background");

            // Change the hovered style.
            &:hover {
                color: colours.t("text-light");
                background-color: colours.t("danger");
            }

            // Change the active style.
            &:active {
                color: colours.t("text-light");
                background-color: colours.t("danger");
            }
        }
    }
}

/** Set the style of the icon. */
.icon { height: 0.75rem; }
