/*!
 * Copyright © 2021-2022 - Zimproov.
 * All rights reserved.
 */

@use "scss/colours";
@use "scss/mixins";


/** Set the style of the sections. */
.section {
    // Take as much space as needed.
    flex: 0 0 auto;

    // Display the contents in a column.
    display: flex; flex-direction: column;
    justify-content: center; align-items: center;
    gap: 0.5rem; overflow: hidden; flex-wrap: nowrap;
    // Display in a row on larger screens.
    @include mixins.media-breakpoint-up(md) { flex-direction: row; }

    // Render a separator between the elements.
    @include mixins.media-breakpoint-down(md) {
        &:not(:last-child)::before {
            content: ""; align-self: stretch;
            @include colours.themed {
                border: solid 0.5px colours.t("background-dark");
            }
        }
    }
    @include mixins.media-breakpoint-up(md) {
        &:not(:last-child)::after {
            content: ""; align-self: stretch;
            @include colours.themed {
                border: solid 0.5px colours.t("background-dark");
            }
        }
    }

}

/** Set the style of the buttons. */
.button {
    // Clear the default styling.
    all: unset;

    // Display the icon in the center.
    display: grid; justify-items: center; align-items: center;

    // Override the default colours.
    @include colours.themed {
        box-shadow: none;
        background-color: unset;
        color: colours.t("text-light");
        border: solid 1px colours.t("text-light");
    }

    // Set the size of the buttons.
    width: 2rem; height: 2rem;
    border-radius: 100%;

    // Set the style of the hidden buttons.
    &--hidden { display: none; }
    // Set the style of the disabled buttons.
    &--disabled {
        @include colours.themed {
            background-color: colours.t("secondary");
        }
    }
    // Set the style of the forbidden buttons.
    &--forbidden {
        cursor: not-allowed;
        @include colours.themed {
            background-color: colours.t("background-dark");
            color: colours.t("text");
            border-color: colours.t("text");
        }
    }
}

/** Set the style of the text. */
.text {
    // Clear the defaults.
    margin: 0; padding: 0;

    // Set the style of the font.
    font-size: 1rem; font-weight: lighter; text-align: center;
    @include colours.themed { color: colours.t("text-light"); }
}
