/*!
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import the colours and shadows.
@use "scss/colours";
@use "scss/shadows";


/** Define the style of the card element. */
.card {
    // Use as a basis for the z-index.
    z-index: 0;

    // Fill the parent.
    position: relative;
    align-self: stretch; flex: 1 1 0;
    height: calc(100% - 1.5rem); max-width: 100%;

    // Display the contents in the center.
    display: flex; flex-direction: column;
    align-items: stretch; justify-content: center;
    overflow: hidden;

    // Set the style of the box.
    border-radius: 1rem;
    @include shadows.distance;
    @include colours.themed {
        background-color: colours.t("background-dark");
        color: colours.t("text");
    }

    /** Set the style of the button container. */
    &__button-container {
        // Pass all pointer events through.
        pointer-events: none;

        // Place at the bottom of the card.
        position: absolute; left: 0.5rem; right: 0.5rem; bottom: 0.5rem;

        // Display the buttons at each end of the container.
        display: flex; flex-direction: row;
        justify-content: space-between; align-items: flex-end;
        flex-wrap: nowrap; overflow: visible;
    }
}
