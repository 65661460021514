/*!
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

/** Set the style of the tab list. */
.list {
    // Clear the default styling.
    all: unset;

    // Display the tabs in a row.
    display: flex; flex-direction: row;
    justify-content: flex-start; align-items: center;
    gap: .5rem; padding: 0.5rem 0.5rem 0;
}
