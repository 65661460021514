/*!
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

@use "scss/colours";

/** Set the style of the modal. */
.modal {
    // Clear the default styling for the content.
    &__content {
        overflow: hidden;
        border-radius: 0.5rem;
        border: none;
    }
}

/** Set the style of the header. */
.header {
    // Display the contents in a row.
    display: flex; flex-direction: row;
    justify-content: space-between; align-items: center;

    // Add some padding.
    padding: 0.25rem 0.5rem;
}

/** Set the style of the title. */
.title {
    // Add a slight margin.
    margin-left: 0.5rem;

    // Set the style of the text.
    font-size: 1.5rem;
    @include colours.themed {
        color: colours.t("primary");
    }
}

/** Set the style of the close button. */
.close {
    // Clear the default styling.
    all: unset;

    // Fill the parent.
    flex: 0 0 2rem; height: 2rem;

    // Display the image in the center.
    display: flex; justify-content: center; align-items: center;
    &__icon { height: 100%; width: 100%; }

    // Set the style of the button.
    padding: 0.125rem; border-radius: 100%;
    @include colours.themed {
        color: colours.t("danger");
        background-color: colours.t("background");
        &:hover, &:active {
            background-color: colours.t("danger");
            color: colours.t("background");
        }
    }
}

/** Set the style of the body. */
.form {
    // Display the contents in a column.
    display: flex; flex-direction: column;
    justify-content: flex-start; align-items: stretch;
}

/** Set the style of the groups. */
.group {
    position: relative;

    // Display the contents in a row.
    display: flex; flex-direction: row;
    justify-content: center; align-items: center;
    gap: 0.5rem;
}

/** Set the style of the separator line. */
.separator {
    display: block; align-self: stretch;
    height: 1px; margin: 0.5rem;
    @include colours.themed {
        background-color: transparentize(colours.t("background-dark"), 0.75);
    }
}

/** Set the style of the labels. */
.label {
    // Fill the parent.
    flex: 0 0 25%; &--course-name { flex: 2 2 0; }

    // Display the text at the end.
    display: flex; flex-direction: row;
    justify-content: flex-end; align-items: center;
    &--course-name { justify-content: flex-start; }

    // Set the style of the group.
    padding: 0; margin: 0; font-weight: bold;
    @include colours.themed {
        color: colours.t("secondary");
        &--course-name { color: colours.t("text"); }
    }
}

/** Set the style of the controls. */
.control {
    // Fill the parent.
    flex: 2 2 0; height: 2rem;
    &--small { flex: 0 0 4rem; }

    // Display the contents in the center.
    display: flex; justify-content: flex-start; align-items: center;

    // Remove the default border.
    @include colours.themed {
        padding: 0 0.5rem 0 1rem; margin: 0;
        outline: none;
        border: solid 1px transparentize(colours.t("background-dark"), 0.75);
        border-radius: 1rem;
        box-shadow: inset 0.125rem 0.125rem 0.5rem transparentize(colours.t("background-dark"), 0.5);
        background-color: colours.t("background");
    }
}

/** Set the style of the clear button. */
.clear {
    // Clear the default styling.
    all: unset;

    // Place at the right of the parent.
    height: 1.5rem; width: 1.5rem;

    // Display the image in the center.
    display: flex; justify-content: center; align-items: center;
    padding: 0.125rem; margin: 0; border-radius: 100%;
    &__icon { height: 100%; width: 100%; }

    // Set the colour of the icon.
    @include colours.themed {
        color: colours.t("background");
        background-color: colours.t("text");
    }
}

/** Set the style of the add button. */
.set {
    // Clear the default styling.
    all: unset;

    /** Set the style of the container. */
    &__container {
        // Fill the parent.
        flex: 2 2 0;

        // Align the button at the start.
        display: flex; flex-direction: row;
        justify-content: flex-start; align-items: center;
    }

    // Set the size of the button.
    height: 1.5rem; width: 1.5rem;

    // Display the image in the center.
    display: flex; justify-content: center; align-items: center;
    padding: 0.125rem; margin: 0; border-radius: 100%;
    &__icon { height: 100%; width: 100%; }

    // Set the colour of the icon.
    @include colours.themed {
        color: colours.t("background");
        background-color: colours.t("success");
    }
}
