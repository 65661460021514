/*!
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

@use "scss/colours";
@use "scss/shadows";


/** Add a padding to the root container when the navbar is mounted. */
:global(.with-navbar) :global(#root) { padding-top: 3rem; }

/** Set the style of the navbar. */
.navbar {
    // Fix at to the top of the screen.
    position: fixed; top: 0; left: 0; right: 0; height: 3rem;
    z-index: 100;

    // Display the elements in a row.
    display: flex; flex-direction: row;
    justify-content: flex-start; align-items: center;
    padding: 0 0.5rem; gap: 0.5rem;

    // Set the style of the box.
    @include colours.themed {
        background-color: colours.t("background");
    }
    @include shadows.bottom($distance: 0.125rem, $intensity: 33%);
}

/** Set the style of the separator. */
.separator { flex: 1 1 0; }
