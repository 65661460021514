/*!
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

@use "scss/colours";
@use "sass:color";


/** Set the style of the error box. */
.error {
    // Display the contents in a grid.
    display: grid; grid-template: 2rem auto / 3rem 1fr;
    grid-template-areas: "icon title" "detail detail";
    padding: 0.5rem; gap: 0.5rem 0.25rem;

    // Set the style of the background.
    @include colours.themed {
        background-color: colours.t("background");
    }

    // Set the style of the icon.
    &__icon {
        // Set the size of the icon.
        height: 1.5rem;
        // Align in the center of its area.
        grid-area: icon; align-self: center; justify-self: end;

        // Set the style of the text.
        @include colours.themed { color: colours.t("danger"); }
    }

    // Set the style of the title.
    &__title {
        grid-area: title; justify-self: stretch; align-self: end;

        // Set the style of the text.
        font-size: 1.25rem;
        @include colours.themed { color: colours.t("text"); }
    }

    // Set the style of the error message.
    &__detail {
        // Fill the allocated area.
        grid-area: detail; justify-self: stretch; align-self: center;
        padding: 0.25rem 0.5rem;

        // Set the style of the background.
        border-radius: 0.25rem;
        @include colours.themed {
            background-color: colours.t("text");
            color: colours.t("page-background");
        }

        // Set the style of the text.
        font-size: 0.8rem;
    }
}

/** Set the style of the list. */
.list {
    // Clear the default styling.
    all: unset;

    // Fill the parent.
    flex: 1 1 auto;

    // Display the contents in a column.
    display: flex; flex-direction: column;
    justify-content: flex-start; align-items: stretch;
    gap: 0.25rem; padding: 0.5rem 0.25rem; overflow: hidden scroll;

    // Set the style of the scrollbar.
    &::-webkit-scrollbar {
        // Set the width of the scrollbar background.
        width: 0.5rem;

        // Set the colour of the background.
        @include colours.themed {
            background-color: colours.t("text");
        }
    }

    // Set the style of the scrollbar thumb.
    &::-webkit-scrollbar-thumb {
        // Set the width of the scrollbar background.
        border-radius: 0.5rem;

        @include colours.themed {
            border: solid 1px colours.t("text");
            background-color: colours.t("primary");
        }
    }
}

/** Set the style of the items. */
.item {
    // Clear the default styling.
    all: unset;

    // Set the style of the background.
    @include colours.themed { background-color: colours.t("background"); }
}

/** Set the style of the empty message. */
.empty {
    // Fill the parent.
    flex: 1 1 auto;

    // Display the contents in a column.
    display: flex; flex-direction: column;
    justify-content: center; align-items: center;
    gap: 1rem;

    // Add some margins.
    margin: 0.5rem;

    // Set the style of the text.
    font-size: 1.25rem;
    text-align: center; font-style: italic;
    @include colours.themed {
        color: colours.t("secondary");
    }

    // Set the style of the icon.
    &__icon { height: 2rem; }
}
