/*!
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

// Import the colours.
@use "../../../../assets/src/scss/colours";
// Import the shadows.
@use "../../../../assets/src/scss/shadows";

/** Set the style of the course's title section. */
.title {
    // Fill the parent.
    align-self: stretch;

    // Display the contents in a row.
    display: flex; flex-direction: row;
    justify-content: space-between; align-items: center;

    // Set the style of the input.
    textarea {
        border: unset; background: unset;
        &:focus, &:active { outline: unset; font-style: italic; }
        &::placeholder {
            font-weight: normal; font-style: italic;
            @include colours.themed {
                color: colours.t("background-dark");
            }
        }
    }

    // Set the style of the text.
    h2, textarea {
        overflow: hidden;
        padding: 0 0.5rem; margin: 0;
        width: 100%; font-size: 2rem;
        line-height: normal; font-weight: normal;
        white-space: break-spaces;
        @include colours.themed {
            color: colours.t("primary");
        }
    }

    @include colours.themed {
        border-bottom: solid 2px colours.t("primary");
    }
}

/** Set the style of the switch. */
.switch {
    padding: 0.125rem 0.25rem;
    margin-bottom: 0.5rem;

    // Set the style of the box.
    background-color: white; border-radius: 1.5rem;
    @include colours.themed {
        border: solid 1px colours.t("primary");
    }
}
