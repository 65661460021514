/*!
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import the app colours.
@import "css/colours";
// Import the media mixins.
@import "css/mixins";

/** Set the style of the user editor. */
.user-editor {
    // Display the contents in a column.
    display: flex; flex-direction: column;
    justify-content: flex-start; align-items: stretch;
    overflow: hidden; flex-wrap: nowrap;

    // Roundify the table.
    border-radius: 0.75rem;

    // Add a slight drop shadow.
    background-color: white;
    box-shadow: 0.25rem 0.25rem 0.5rem $tertiary-colour;

    // Add a right margin to fix a chrome rendering issue...
    > * { margin-right: -1px; }

    /** Set the style of the header. */
    &__header {
        // Display the elements in a row.
        display: flex; flex-direction: row;
        justify-content: center; align-items: stretch;
        overflow: hidden; flex-wrap: nowrap;

        background-color: white;
        box-shadow: 0 0 0.25rem $tertiary-colour;

        // Set the style of the cells.
        &__item {
            // Align the text at the bottom.
            display: flex; flex-direction: row;
            justify-content: flex-start; align-items: center;
            overflow: hidden;

            // Add some padding.
            padding: 0.25rem 0;
            > p { margin-bottom: 2px; margin-left: 1.25rem; font-size: 1.25rem; }

            // Set the style of the text.
            color: $blue-dark;

            &--name { padding-left: 0.75rem; }
            &--role { border-left: solid 3px white; }
            &--actions { border-left: solid 3px white; }

            /** Set the size of the cells. */
            &--name { flex: 65 65 0; }
            &--role { flex: 25 25 0; }
            &--actions { flex: 10 10 0; }
            &--back { width: 100%; }

            /** Hide the role and actions on smaller screens. */
            &--actions, &--role {
                @include media-breakpoint-down(lg) {
                    display: none !important;
                }
            }


            /** Set the style of the back arrow. */
            &--back button {
                // Render the element in a row.
                display: flex; flex-direction: row;
                justify-content: center; align-items: center;
                overflow: hidden; flex-wrap: nowrap;

                // Remove the default styling.
                background: unset; outline: unset;
                // Add a focused effect.
                border: {
                    top: unset; right: unset; left: unset;
                    bottom: solid 2px transparent;
                }
                &:focus { border-bottom-color: $primary-colour; }

                // Set the style of the font.
                color: inherit; font-size: 1.25rem;

                // Add some inside padding.
                padding: 0 0.5rem;

                // Rotate and scale the image.
                img { transform: scaleX(-1); height: 0.75rem; margin-right: 0.25rem; filter: $blue-dark-filter; }
            }
        }
    }

    /** Set the style of the sorting arrows. */
    &__select-arrows {
        display: flex; flex-direction: column;
        justify-content: center; align-items: center;
        overflow: visible; flex-wrap: nowrap;

        &--up .user-editor__arrow--up { filter: $blue-darkest-filter; }
        &--down .user-editor__arrow--down { filter: $blue-darkest-filter; }
    }

    /** Set the style of the individual arrows. */
    &__arrow {
        height: 0.5rem; filter: $blue-filter; margin-left: 0.5rem;

        &--up   { transform: rotateZ( 90deg); }
        &--down { transform: rotateZ(-90deg); }
    }
}
