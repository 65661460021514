/*!
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

@use "scss/colours";
@use "scss/shadows";


/** Set the style of the hidden element. */
.hidden { display: none; }

/** Set the style of the button. */
.button {
    // Center in the parent.
    grid-column-start: 1; grid-column-end: -1;
    align-self: center;

    // Display the contents in a row.
    display: flex; flex-direction: row;
    justify-content: center; align-items: center;
    gap: 0.5rem;

    // Set the style of the icon.
    &__icon { height: 1rem; width: 1rem; }

    // Set the style of the box.
    border-radius: 1rem; font-weight: bold;
    @include colours.themed {
        background-color: colours.t("background");
        color: colours.t("success");
        border: solid 1px colours.t("success");
        &:hover {
            background-color: colours.t("success");
            color: colours.t("background")
        }
    }
    @include shadows.distance;
}

/** Set the style of the labels. */
.label {
    // Set the style of the text.
    font-weight: bold;
    @include colours.themed {
        color: colours.t("secondary");
    }

    // Change the cursor when hovered.
    cursor: pointer;
}

/** Set the style of the text field. */
.text-field {
    // Clear the default styling.
    all: unset;

    // Set the style of the box.
    border-radius: 0.5rem; padding: 0 0.5rem;
    @include colours.themed {
        color: colours.t("text");
        border: solid 1px colours.t("primary");
    }
}

/** Set the style of the icon. */
.icon {
    // Fill the parent.
    aspect-ratio: 1;

    // Set the style of the box.
    border-radius: 1rem; cursor: pointer;
    @include colours.themed {
        border: solid 2px colours.t("primary");
        color: colours.t("secondary");
    }

    // Display the image in the center.
    display: flex; justify-content: center; align-items: stretch;
    overflow: hidden;
    > * { object-fit: contain; flex: 1 1 0; height: unset; }
}
