/*!
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

@use "sass:color";
@use "scss/colours";
@use "scss/shadows";
@use "scss/mixins";


/** Set the style of the container. */
.container {
    // Fill the parent.
    align-self: stretch;

    // Display the items in a row.
    display: flex; flex-direction: row;
    justify-content: flex-end; align-items: center;
    gap: 0.25rem;
}

/** Set the style of the checkmark. */
.checkmark {
    // Hide by default.
    display: none;

    // Set the size of the element.
    height: 1rem; width: 1rem;

    // Set the style of the element.
    border-radius: 100%; padding: 0.25rem;

    /** Set the style of the objective met icon. */
    &--objective-met {
        display: block;
        @include colours.themed {
            color: colours.t("success");
            border: solid 2px colours.t("success");
        }
    }

    /** Set the style of the validated icon. */
    &--validated {
        display: block;
        @include colours.themed {
            color: colours.t("text-light");
            border: solid 2px colours.t("success");
            background-color: colours.t("success");
        }
    }
}

/** Set the style of the level icon. */
.level {
    // Set the size of the icon.
    height: 1.5rem; width: 1.5rem;
}

/** Set the style of the text. */
.text {
    margin-right: 0.25rem;
    @include mixins.media-breakpoint-down(md) {
        display: none;
    }
}

/** Set the style of the delete button. */
.delete-button {
    // Remove the default style.
    all: unset;

    // Display the icon in the center.
    display: flex; justify-content: center; align-items: center;
    padding: 0.375rem;
    &__icon { width: 1rem; height: 1rem; }

    // Set the style of the box.
    border-radius: 1rem;
    @include colours.themed {
        background-color: colours.t("background");
        color: colours.t("danger");
        border: solid 1px colours.t("danger");
        &:hover {
            background-color: colours.t("danger");
            color: colours.t("background");
        }
    }
}
