/*!
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

/** Set the style of the capture body. */
.capture {
    // Fill the parent.
    height: 100%; width: 100%;
    position: relative;

    // Display the contents in the center.
    display: flex; flex-direction: column;
    justify-content: space-between; align-items: stretch;
    gap: 1rem;

    // Make the video fit in the screen.
    video { margin: auto; border-radius: 1rem; }
}
