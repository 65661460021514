/*!
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

@use "sass:color";
@use "scss/colours";
@use "scss/shadows";


/** Set the style of the delete button. */
.button {
    // Clear the default styling.
    all: unset;

    // Place at the top-left of the parent.
    position: absolute; top: 0.5rem; right: 0.5rem;

    // Display the items in a row.
    display: grid;
    align-items: center; justify-items: center;
    padding: 0.375rem;

    // Set the style of the box.
    border-radius: 1rem;
    @include colours.themed {
        background-color: colours.t("background");
        color: colours.t("danger");
        border: solid 1px colours.t("danger");
        &:hover {
            background-color: colours.t("danger");
            color: colours.t("text-light");
        }
    }

    // Set the style of the icon.
    &__icon { height: 0.75rem; width: 0.75rem; }
}
