/*!
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

@use "scss/colours";
@use "scss/shadows";


/** Set the style of the searchbar. */
.searchbar {
    // Display the contents in a row.
    display: flex; flex-direction: row;
    justify-content: flex-start; align-items: center;
    gap: 0.25rem; cursor: text;

    // Set the style of the bar.
    height: 2rem;
    padding: 0.25rem 0.5rem; border-radius: 1rem;
    @include colours.themed {
        color: colours.t("text");
        background-color: colours.t("background");
        border: solid 1px colours.t("text");
    }

    // Add more gap in large mode.
    .searchbar__container--lg & { padding: 1rem; gap: 1rem; }
}

/** Set the style of the search icon. */
.search-icon {
    // Set the size of the icon.
    height: 0.75rem;

    // Change the icon size in large mode.
    .searchbar__container--lg & { height: 1rem; }
}

/** Set the style of the input. */
.input {
    // Remove the default styling.
    all: unset;

    // Fill the parent.
    flex: 1 1 0; width: 0;
    margin: 0 0.25rem;

    // Set the style of the text.
    font-size: 1rem;

    // Change the font size in large mode.
    .searchbar__container--lg & { font-size: 1.25rem; }

    // Remove the cancel search button.
    &::-webkit-search-cancel-button { display: none; }

    // Change the style of the placeholder.
    &::placeholder {
        @include colours.themed { color: colours.t("text"); }
    }
}

// Set the style of the buttons.
.button {
    // Display the contents in the center.
    width: 1rem; padding: 0;
    display: flex; justify-content: center; align-items: center;

    // Set the colour of the button.
    @include colours.themed { color: colours.t("text"); }

    // Set the size of the icon.
    &__icon { height: 1rem; }

    // Change the icon size in large mode.
    .searchbar__container--lg &__icon { height: 1.25rem; }

    // Hide the clear button when disabled.
    &--clear:disabled { display: none; }

    // Add a slight offset to the clear button to fix visual alignment.
    &--clear { margin-right: 0.25rem; }

    // Add a margin around the speech recognition button.
    &--speech { margin: 0 0.5rem; }
}
