/*!
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import the colours.
@import "../../../../assets/src/css/colours";

/** Set the style of the file. */
.file {
    border-radius: 1.5rem;
    overflow: hidden;
    padding: 0; margin: 0 0.25rem;

    box-shadow: 0.25rem 0.25rem 1rem $tertiary-colour;
}
