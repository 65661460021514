/*!
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

@use "scss/colours";


/** Set the style of the loader. */
.loader {
    // Set the size of the element.
    height: 100%; width: 100%;

    // Set the colour of the spinner.
    @include colours.themed {
        color: colours.t("primary");
    }

    // Make the loader spin.
    @keyframes loader {
        from { transform: rotateZ(0); }
        to { transform: rotateZ(360deg); }
    }
    animation: loader linear 1s infinite;
}

/** Set the style of the question mark. */
.questionmark {
    // Set the size of the element.
    height: 100%; width: 100%;

    // Set the colour of the question mark.
    @include colours.themed {
        color: colours.t("text");
    }
}

/** Set the style of the icon. */
.icon {
    // Round the borders.
    border-radius: 0.25rem;
}
