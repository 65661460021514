/*!
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

@use "scss/shadows";


/** Set the style of the button contents.  */
.button {
    // Set the size of the buttons.
    height: 2.5rem; width: 2.5rem; border-radius: 100%;
    // Receive pointer events.
    pointer-events: all;

    @include shadows.distance($intensity: 50%);

    // Invert the next icon.
    &--next &__icon { transform: scaleX(-1); }
    // Set the size of the icons.
    &__icon { height: 1.5rem; }
}
