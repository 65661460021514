/*!
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

@use "scss/colours";
@use "scss/mixins";


/** Set the style of the icon. */
.icon {
    // Set the size of the container.
    width: 2.5rem; height: 2.5rem; margin-right: 0.5rem;

    // Display the icon at the end.
    display: flex; flex-direction: row;
    justify-content: center; align-items: center;
}

/** Set the style of the default question mark. */
.question-mark {
    // Fill the parent.
    height: 2.5rem; width: 2.5rem;
    justify-self: center;

    // Set the colour of the element.
    @include colours.themed {
        color: colours.t("secondary");
    }
}
