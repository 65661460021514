/*!
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

@use "scss/colours";


/** Set the style of the information line. */
.info {
    // Display the contents in a grid.
    display: grid; grid-template-columns: 1fr 3fr 2rem;
    grid-gap: 0.5rem 0.5rem;

    // Add a border under the element.
    &::after {
        // Fill the width of the parent.
        grid-column-start: 1; grid-column-end: -1;

        // Set the style of the element.
        content: ""; height: 1px;
        margin-top: 0.25rem;
        @include colours.themed {
            background-color: colours.t("primary");
        }
    }
}

/** Set the style of the label. */
.label {
    // Ensure that the labels always start on the first column.
    grid-column-start: 1;

    // Set the style of the text.
    font-weight: bold;
    @include colours.themed { color: colours.t("secondary"); }
}

/** Set the style of the values. */
.value {
    // Fill the rest of the available space.
    grid-column-start: 2; grid-column-end: -1 ;

    // Set the style of the text.
    @include colours.themed {
        color: colours.t("text");
    }
}
