/*!
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

/** Set the style of the container. */
.container {
    // Display the elements in a column.
    display: flex; flex-direction: column;
    justify-content: flex-start; align-items: center;
    gap: 1rem; padding: 0 0 4rem;
}

/** Set the style of the personalisation tool. */
.personalise { flex: 0 0 2rem; }

/** Set the style of the list. */
.list {
    // Clear the default styling.
    all: unset;

    // Fill the parent.
    flex: 1 0 auto; align-self: stretch;

    // Display the elements in a wrapping row.
    display: flex; flex-direction: row;
    justify-content: flex-start; align-items: stretch;
    gap: 2rem 1rem; flex-wrap: wrap;
}
