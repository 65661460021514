/*!
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

@import "css/colours";
@import "css/shadows";
@import "css/mixins";

/** Set the style of the list. */
.notification-list {
    // Ensure that the element is ALWAYS on top.
    z-index: 1000000000;

    // Ignore pointer events.
    pointer-events: none;

    // Place at the top right of the screen.
    position: absolute; top: 0.5rem; right: 0.25rem; bottom: 0.5rem; width: 25%;
    @include media-breakpoint-down(md) { width: 100%; }
    overflow: hidden auto;

    // Display the items in a column.
    display: flex; flex-direction: column;
    justify-content: flex-start; align-items: stretch;
    gap: 0.75rem;

    // Clear the default style.
    padding: 0.5rem; margin: 0; list-style: none;
}

/** Set the style of the individual notifications. */
.notification {
    pointer-events: all;

    // Fore the size of the item.
    flex: 0 0 auto;

    // Display the contents in a column.
    display: flex; flex-direction: column;
    justify-content: flex-start; align-items: stretch;
    overflow: hidden; flex-wrap: nowrap;

    // Set the style of the box.
    background-color: white; border-radius: 1rem; box-shadow: 0.125rem 0.125rem 0.25rem $dark-text-colour;

    /** Hide any hidden item. */
    &--hidden { display: none; }

    /** Set the style of the dissipating item. */
    &--dissipating {
        @keyframes dissipate {
            from {
                opacity: 1;
                max-height: 100%;
                transform: translateX(0);
            }
            to {
                opacity: 0;
                max-height: 0;
                transform: translateX(100%);
            }
        }
        animation: dissipate 0.5s ease-in-out;
    }
    @keyframes appear {
        from {
            opacity: 0;
            transform: translateX(100%);
        }
        to {
            opacity: 1;
            transform: translateX(0);
        }
    }
    animation: appear 0.5s ease-in-out;

    /** Set the style of the header. */
    &__header {
        // Display the contents in a row.
        display: flex; flex-direction: row;
        justify-content: space-between; align-items: center;
        overflow: hidden; flex-wrap: nowrap;
        gap: 0.5rem;

        // Add some inside padding.
        padding: 0.125rem 0.125rem 0.125rem 1rem;
    }
    /** Set the style of the body. */
    &__body {
        // Display the contents in a column.
        display: flex; flex-direction: column;
        justify-content: flex-start; align-items: stretch;
        overflow: hidden; flex-wrap: nowrap;
        gap: 0.5rem;

        /** Set the style of the body rows. */
        &__row {
            // Display the contents in a row.
            display: flex; flex-direction: row;
            justify-content: flex-start; align-items: center;
            overflow: hidden; flex-wrap: nowrap;
            gap: 1rem;
        }

        // Add a box shadow to separate the header.
        box-shadow: inset 0 0.25rem 0.5rem -0.25rem $tertiary-colour;

        // Add some inside padding.
        padding: 0.5rem 0.5rem 0;

        // Set the size of the icon.
        img { max-height: 100%; width: 4rem; }
    }

    /** Set the style of the body icon. */
    &--debug &__body img { filter: invert(84%) sepia(34%) saturate(2725%) hue-rotate(264deg) brightness(95%) contrast(99%); }
    &--warning &__body img { filter: $med-colour-filter; }
    &--error &__body img { filter: invert(62%) sepia(24%) saturate(6974%) hue-rotate(352deg) brightness(93%) contrast(93%); }
    &--fatal &__body img { filter: invert(80%) sepia(34%) saturate(4844%) hue-rotate(346deg) brightness(104%) contrast(97%); }

    /** Set the style of the close button. */
    &__close-button {
        // Do no shrink in the parent.
        flex: 0 0 auto;

        // Display the image in the center.
        display: flex; justify-content: center; align-items: center; width: 2rem;
        img { max-width: 100%; max-height: 100%; filter: $blue-filter; }
    }
    /** Set the style of the heading title. */
    &__title {
        // Remove the default margin.
        margin: 0;

        // Set the style of the text.
        font-size: 1.125rem;
    }
    /** Set the style of the text. */
    &__text {
        // Remove the default margin.
        margin: 0;

        // Center the text.
        text-align: center;
    }

    /** Set the style of the error details. */
    &__detail {
        // Fill the parent.
        flex: 1 1 0; overflow: hidden;
        // Display the contents in a column.
        display: flex; flex-direction: column; align-items: stretch;

        // Set the style of the text.
        p { font-size: 0.75rem; font-style: italic; margin: 0; }
        // Set the style of the error message.
        pre { max-width: 100%; overflow-x: auto; font-size: 0.75rem; margin: 0; background-color: $dark-text-colour; color: white; padding: 0.125rem; }
        // Set the style of the copy button.
        button {
            // Set the margin/padding.
            margin: 0.5rem 0; padding: 0.125rem 1rem; align-self: flex-end;

            // Set the style of the text.
            font-size: 0.75rem; color: white;
            // Set the style of the box.
            background-color: $blue; border-radius: 1rem;
        }
    }
}
