/*!
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

@use "scss/colours";


/** Set the style of the outline. */
.outline {
    // Remove the fill.
    fill: none;

    // Set the style of the stroke.
    stroke-width: 0;
    stroke-dasharray: 2, 2;
    @include colours.themed {
        stroke: colours.t("debug");
    }

    // Show only when necessary.
    &--visible { stroke-width: 1px; }
}

/** Set the style of the background. */
.background {
    // Set the colour of the fill.
    @include colours.themed {
        fill: colours.t("page-background");
    }
}
