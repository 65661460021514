/*!
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

@use "scss/colours";
@use "scss/shadows";


/** Set the style of the button. */
.button {
    // Align on the center.
    align-self: center;

    // Display the contents in a row.
    display: flex; flex-direction: row;
    justify-content: center; align-items: center;
    gap: 1rem;

    // Set the style of the box.
    padding: 0.25rem 1rem; margin-top: 1rem;
    border-radius: 1rem; cursor: pointer;
    @include colours.themed {
        background-color: colours.t("background");
        color: colours.t("primary");
        border: solid 1px colours.t("primary");
        &:hover {
            background-color: colours.t("primary");
            color: colours.t("background");
        }
        &:disabled {
            cursor: not-allowed;
            background-color: colours.t("background-dark");
            color: colours.t("text");
            border-color: colours.t("background-dark");
        }
    }

    /** Set the style of the icon. */
    &__icon { height: 1.5rem; width: 1.5rem; }
}
