/*!
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

// Import the colours.
@import "css/colours";

/** Set the style of the default header. */
.default-header {
    // Display the contents in a row.
    display: flex; flex-direction: row;
    justify-content: space-between; align-items: center;
    overflow: hidden; flex-wrap: nowrap;

    // Set the padding.
    padding: 0.25rem;

    // Remove the default border.
    border: unset;

    // Set the style of the text.
    &__title {
        // Fill the parent.
        flex: 1 1 0;

        // Set the style of the text.
        text-indent: 1rem;
        font-size: 1.25rem; font-weight: normal;
        color: $blue-dark;
    }

    /** Set the style of the close button. */
    &__close {
        // Remove the default styling.
        background: none; border: none; outline: none;
        // Set the size of the element.
        flex: 0 0 2rem; height: 2rem;

        // Style the background.
        border-radius: 100%;
        &:hover { background-color: $secondary-colour; }
        &:hover img { filter: unset; }

        // Fit the image in the center.
        display: flex; justify-content: center; align-items: center;
        padding: 0.125rem;
        img { height: 100%; width: 100%; filter: $secondary-colour-filter }
    }
}
