/*!
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

@use "scss/colours";
@use "scss/shadows";


/** Set the style of the sharing container. */
.container {
    // Use the position as an anchor.
    position: relative;

    // Set some max sizes to the container.
    max-width: min(24rem, calc(100vw - 1rem)); max-height: 50vh;

    // Display the contents in the center.
    display: flex; flex-direction: column;
    justify-content: flex-start; align-items: stretch;
    padding: 0;

    // Set the style of the box.
    @include shadows.distance-filter($intensity: 50%);

    // Show on top of the other buttons.
    z-index: 10;
}

/** Set the style of the title. */
.title {
    // Add some padding.
    padding: 0.5rem 1.5rem;

    // Set the style of the text.
    font-size: 1.25rem;

    // Set the style of the background.
    border-radius: 0.25rem 0.25rem 0 0;
    @include colours.themed {
        background-color: colours.t("background");
    }
    @include shadows.bottom($intensity: 100%);
}

/** Add a rounded arrow to the right of the box. */
.arrow {
    &, &::before { height: 1rem; width: 1rem; position: absolute; }
    &::before {
        content: "";
        border-radius: 0 0.25rem 0 0;
        @include colours.themed { background-color: colours.t("background"); }
    }

    // Change the position based on the placement.
    [data-popper-placement^="left"] & { right: -.5rem; }
    [data-popper-placement^="bottom"] & { top: -.5rem; }

    [data-popper-placement^="left"] &::before { transform: scaleY(75%) rotateZ(45deg); }
    [data-popper-placement^="bottom"] &::before { transform: scaleX(75%) rotateZ(315deg); }
}
