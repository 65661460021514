/*!
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

@use "scss/mixins";

.iframe-container {
    flex: 1 1 0;
    justify-self: stretch;
}

.overflow-none { overflow: hidden; }

.centered-container {
    &.always-on-top { z-index: 100; }

    // Fill the window.
    position: absolute; top: 0; right: 0; bottom: 0; left: 0;
    // Set the colour of the background.
    background-color: white;

    // Center the contents.
    display: flex;
    justify-content: center;
    align-items: center;

    // Hide any overflow.
    overflow: hidden;

    // Set the colour of the text.
    > p { color: black; font-style: italic; }
}
