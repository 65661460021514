/*!
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

.explorer {
    display: flex;
    flex-direction: row;
}

.carousel {
    display: flex;
    flex-direction: column;
    margin-right: 5rem;
    &__category {
        font-size: 2rem;
        // center the text
        margin-left: auto;
        margin-right: auto;
    }
}
