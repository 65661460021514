/*!
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

@use "sass:color";
@use "scss/colours";

// Set the style of the text.
.text {
    // Add some inside padding.
    margin: 0; padding-left: 0.5rem;

    @include colours.themed {
        color: colours.t("primary");
    }
}

.separator {
    // Fill the entire width of the grid.
    grid-column: text / validator;

    // Set the style of the separator.
    height: 2px; margin: 0 1rem;
    @include colours.themed {
        background-color: color.change(colours.t("secondary"), $alpha: 0.5);
    }

    // Hide the last separator item.
    &:last-child { display: none; }
}
