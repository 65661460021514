/*!
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import the colours.
@import "../../../../../../assets/src/css/colours";
// Import the shadows.
@import "../../../../../../assets/src/css/shadows";

/** Set the style of the preparation interface. */
.preparation {
    // Fill the parent.
    height: 100%; align-self: center;

    // Display the contents in the center.
    display: flex; flex-direction: column;
    justify-content: center; align-items: stretch;
    flex-wrap: nowrap; overflow: hidden;
    gap: 2rem; padding: 2rem;

    // Set the style of the button container.
    &__button {
        // Display the contents in a row.
        display: flex; flex-direction: row;
        justify-content: flex-start; align-items: center;
        flex-wrap: nowrap; overflow: hidden;
        gap: 1rem;

        // Set the style of the button.
        --width: 4rem;
        height: var(--width);
        background-color: $blue;
        border-radius: calc(var(--width) / 2);
        padding: 0.5rem 1.5rem;
        box-shadow: box-shadow(true);

        /** Set the style of the icon. */
        img { width: calc(var(--width) / 2); max-height: calc(var(--width) / 2); }

        /** Set the style of the text. */
        p { color: white; margin: 0; font-size: 1.5rem; white-space: nowrap; text-align: center; flex: 1 1 0; }
    }

    /** Override the style of the loader. */
    &__loader {
        // Render the elements in white.
        p { color: white; } img { filter: unset; }
    }
}
