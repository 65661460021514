/*!
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

@use "scss/colours";


/** Set the style of the progress bar. */
.progress {
    // Use the position as an anchor.
    position: relative;

    // Set the size of the bar.
    height: 0.5rem;

    // Set the style of the bar background.
    border-radius: 0.25rem;
    @include colours.themed { background-color: colours.t("background-dark"); }

    // Set the style of the bar fill.
    &::before {
        content: "";

        // Place at the left of the parent.
        position: absolute; left: 0; top: 0; bottom: 0;

        // Animate the size of the fill.
        @keyframes fill {
            from { width: 0; }
            to { width: var(--progress); }
        }
        animation-name: fill;
        animation-duration: 500ms;
        animation-timing-function: ease-in-out;
        animation-delay: 1s;
        animation-fill-mode: forwards;

        // Set the style of the background.
        border-radius: 0.25rem;
        @include colours.themed { background-color: colours.t("secondary"); }
    }
}
