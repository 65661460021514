/*!
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

@use "scss/colours";
@use "scss/shadows";


/** Set the style of the loader. */
.loader { height: 12rem; }

/** Set the style of the container. */
.container {
    // Display the elements in a grid.
    display: grid; grid-template-columns: 1fr 6rem 8rem;
    overflow: auto;
}

/** Set the style of the header text. */
.header {
    padding: 0.5rem 0 0.25rem 0.5rem;
    text-align: center;
    @include colours.themed {
        color: colours.t("secondary");
        background-color: colours.t("background");
    }
    &--first { padding-left: 1rem; text-align: start; }
    &--last { }
}
