/*!
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

// Import the colours.
@import "css/colours";

/** Set the style of the confirmation modal. */
.confirmation-modal {
    /** Set the style of the content. */
    &__content {
        // Remove the border.
        border: unset; border-radius: 0.25rem;
        &--transparent { background: unset; }
        overflow: hidden;
    }

    /** Set the style of the backdrop. */
    &__backdrop {
        -webkit-backdrop-filter: blur(0.5rem);
        backdrop-filter: blur(0.5rem);
        opacity: 1 !important;
        background-color: #0008;
    }
}
