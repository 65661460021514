/*!
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

@use "scss/colours";
@use "scss/shadows";
@use "sass:color";


/** Set the style of the item. */
.item {
    // Display the items in a column.
    display: flex; flex-direction: column;
    justify-content: flex-start; align-items: stretch;
    padding: 0.5rem; gap: 1rem; overflow: hidden;

    &[role="button"]:hover {
        // Change the opacity of the icon on hover.
        .icon { filter: brightness(75%); }

        // Change the colour of the text on hover.
        .title {
            @include colours.themed {
                color: colours.t("primary");
            }
        }
    }
}

/** Set the style of the icon. */
.icon {
    /** Set the style of the container. */
    &__container {
        // Display the contents in the center.
        position: relative;
        display: flex; flex-direction: column;
        justify-content: center; align-items: stretch;
        overflow: hidden;

        // Set the style of the box.
        border-radius: 0.5rem; aspect-ratio: 1;

        // Add a background colour.
        .item[role="button"] & {
            @include colours.themed {
                border: solid 1px colours.t("primary");
            }
        }
    }

    // Fill the container.
    max-height: 100%; flex: 1 1 0; object-fit: contain;

    // Set the style of the image.
    @include colours.themed {
        background-color: colours.t("background");
    }

    // Set the style of the missing icon.
    &--missing {
        @include colours.themed {
            color: colours.t("text");
        }
    }
}

/** Set the style of the title */
.title {
    // Center the text.
    text-align: center;
}

/** Set the style of the type icon. */
.type {
    // Place at the top-left of the parent.
    position: absolute; top: 0.5rem; left: 0.5rem;

    // Display the items in a row.
    display: grid; grid-template-columns: 1fr 1rem;
    align-items: center; justify-items: center;
    grid-column-gap: 0.25rem; padding: 0 0.5rem;

    // Set the style of the box.
    @include colours.themed {
        background-color: color.change(colours.t("background"), $alpha: 0.8);
        color: colours.t("text");
    }

    // Set the style of the text.
    &__text { font-weight: normal; }

    // Set the style of the icon.
    &__icon { height: 0.75rem; }
}
