/*!
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

@use "scss/colours";


/** Set the style of the line. */
.finish-line {
    // Place at the end of the parent.
    position: absolute; right: 0; top: -.25rem;

    // Set the size of the element.
    height: 1rem;
}

/** Set the style of the square. */
.square {
    // Set the size of the rectangle.
    width: 1px; height: 1px;

    // Set the style of the light fill.
    &--light {
        @include colours.themed { fill: colours.t("page-background"); }
    }

    // Set the style of the dark fill.
    &--dark {
        @include colours.themed { fill: colours.t("text"); }
    }
}
