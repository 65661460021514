/*!
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

@use "sass:color";
@use "scss/colours";

/** Set the style of the overflow helper. */
.overflow-container {
    // Display the container in the middle.
    display: grid; justify-items: center; align-items: center;
    overflow: auto hidden;
    padding: 1rem;
}

/** Set the style of the legend. */
.legend {
    // Display the content in a column.
    display: flex; flex-direction: column;
    justify-content: flex-start; align-items: center;
    padding: 1rem 0; gap: 1rem;

    /** Set the style of the rows. */
    &__row {
        // Display the items in a grid.
        display: grid; justify-items: center; align-items: center;
        grid-column-gap: 1rem;

        &:nth-child(2) { grid-template-columns: repeat(5, 6rem); }
        &:nth-child(3) { grid-template-columns: repeat(2, 12rem); }

        // Decorate the rows.
        &:nth-child(2) {
            @include colours.themed {
                background-image: repeating-linear-gradient(to right,
                    transparent 0px,
                    transparent calc(6.5rem - 1px),
                    colours.t("background-dark") 6.5rem,
                    colours.t("background-dark") calc(6.5rem + 1px),
                    transparent calc(6.5rem + 1px),
                    transparent 7rem
                );
                background-origin: content-box;
                background-position-y: center;
                background-size: 100% 75%;
                background-repeat: no-repeat;
            }
        }
        &:nth-child(3) {
            @include colours.themed {
                background-image: linear-gradient(to right,
                    transparent 0px,
                    transparent calc(12.5rem - 1px),
                    colours.t("background-dark") 12.5rem,
                    colours.t("background-dark") calc(12.5rem + 1px),
                    transparent calc(12.5rem + 1px),
                    transparent 13rem
                );
                background-origin: content-box;
                background-position-y: center;
                background-size: 100% 75%;
                background-repeat: no-repeat;
            }
        }

        // Set the style of the cells.
        &__cell {
            display: flex; flex-direction: row;
            justify-content: center; align-items: center;
            gap: 0.5rem;
        }
    }
}

/** Set the style of the legend. */
.title {
    // Clear the default margin/padding.
    margin: 0; padding: 0;

    // Set the style of the text.
    font-size: 1.25rem; font-weight: normal;
    line-height: 1; text-align: center;

    @include colours.themed {
        color: colours.t("secondary");
    }
}

/** Set the style of the icons. */
.icon {
    // Set the size of the icon.
    height: 1.5rem; width: 1.5rem;

    /** Set the style of the gray icons. */
    &--gray {
        justify-self: end;
        @include colours.themed { color: colours.t("background-dark"); }
    }

    /** Set the style of the icon container. */
    &__container {
        // Display the elements in a row.
        display: flex; flex-direction: row;
        justify-items: center; align-items: center;
        gap: 0.25rem;
    }
}

/** Set the style of the checks. */
.check {
    // Set the size of the element.
    height: 0.875rem; width: 0.875rem;

    // Set the style of the element.
    border-radius: 100%; padding: 0.125rem;

    /** Set the style of the objective met icon. */
    &--objective-met {
        @include colours.themed {
            color: colours.t("success");
            border: solid 2px colours.t("success");
        }
    }

    /** Set the style of the validated icon. */
    &--validated {
        @include colours.themed {
            color: colours.t("text-light");
            border: solid 2px colours.t("success");
            background-color: colours.t("success");
        }
    }
}

/** Set the style of the texts. */
.text {
    margin: 0; justify-self: start;
    font-size: 0.75rem;

    // Add a background colour.
    @include colours.themed {
        color: colours.t("text");
    }

    /** Override the style of the N/A text. */
    &--gray {
        // Add a small amount of padding.
        padding: 0 0.5rem; border-radius: 0.25rem;
        justify-self: end;

        // Add a background colour.
        @include colours.themed {
            background-color: colours.t("background-dark");
        }
    }
}

.level-background {

    @include colours.themed {
        fill: colours.t("background");
    }
}
