/*!
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

@use "scss/colours";

/** Set the style of the background. */
.background {
    // Fill the parent.
    flex: 1 0 auto;

    // Set the colour of the background.
    @include colours.themed {
        background-color: colours.t("primary");
    }
}

/** Set the style of the home container. */
.container {
    // Display the elements in a column.
    display: flex; flex-direction: column;
    justify-content: flex-start; align-items: stretch;
    gap: 0; padding: 0;

}

/** Set the style of the search area. */
.search-area {
    // Add a margin at the top.
    margin-top: 2rem;

    // Display the elements in a column.
    display: flex; flex-direction: column;
    justify-content: flex-start; align-items: center;
    gap: 1rem;
}

/** Set the style of the ZaqTiv logo. */
.zaqtiv { height: 4rem; }

/** Set the style of the search bar. */
.searchbar { max-width: 32rem; }
