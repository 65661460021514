/*!
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

@use "scss/colours";
@use "./index.module.scss";

/** Set the style of the title module. */
.title {
    @extend .module;

    // Fill the parent.
    height: 100%;

    // Display the contents in a row.
    display: flex; flex-direction: row;
    justify-content: center; align-items: center;
    pointer-events: all; gap: 1rem;

    // Add some inside padding.
    padding: 0 1rem 0;

    // Set the style of the text.
    &__text { flex: 0 1 auto; font-size: 2rem; @include colours.themed { color: colours.t("text"); }}

    // Add a line before and after the text.
    &::before, &::after { content: ""; height: 1px; flex: 1 1 15vh; @include colours.themed { background-color: colours.t("text"); }}
}
