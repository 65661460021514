/*!
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

@use "scss/colours";


/** Set the style of the filling. */
.fill {
    // Set the style of the fill.
    @include colours.themed {
        fill: colours.t("success");
    }
}
