/*!
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

@use "common";

/** Set the style of the copy button */
.button {
    // Extend the base button style.
    @include common.button;

    // Hide when the link is inactive or expired.
    :global(.share-link--expired) &, :global(.share-link--inactive) & {
        display: none;
    }

    // Build the rotation animation for the icon.
    @keyframes rotate {
        from { rotate: 0deg; }
        to { rotate: 360deg; }
    }

    // Apply the animation to the icon.
    &:disabled &__icon { animation: rotate 1s linear infinite; }
}

/** Hide the canvas. */
.canvas { display: none; }
