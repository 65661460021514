/*!
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

@use "scss/colours";

/** Set the style of the button. */
.button {
    // Set the colour of the inner icon.
    @include colours.themed {
        color: colours.t("text");
        background-color: colours.t("background-dark");
        &--active {
            color: colours.t("text-light");
            background-color: colours.t("primary");
        }
    }

}


