/*!
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

@use "sass:math";

// Import the colours and shadows.
@use "scss/colours";
@use "scss/shadows";


/** Set the style of the share menu.*/
.share-menu {
    /** Set the style of the container. */
    &__container { position: relative; width: auto; height: auto; }

    // Set the style of the button.
    &__button {
        // Set the style of the inner icon.
        &__icon { height: 1rem; width: 1rem; }
    }

    // Place relative to the parent.
    position: absolute; z-index: 2;

    // Set the size of the element.
    $height: 6rem; $width: 14rem;
    height: $height; width: $width;

    // Display the contents in a row.
    display: flex; flex-direction: row;
    justify-content: space-between; align-items: center;
    gap: 0.5rem;

    // Set the style of the box.
    border-radius: 1rem;
    @include colours.themed {
        background-color: colours.t("background");
    }
    @include shadows.distance(0.25rem, 30%);

    // Close the menu by default.
    visibility: hidden; opacity: 0;
    transition: opacity linear 250ms;
    &--open { visibility: visible; opacity: 1; }

    // Set the style of the arrow.
    &__arrow {
        // Set the style of the element.
        position: absolute; visibility: hidden;
        height: 1rem; width: 1rem;

        // Set the style of the arrow component.
        &::before {
            position: absolute;
            content: ""; visibility: visible;
            height: 1rem; width: 1rem;
            border: {
                top:    solid 0.5rem white;
                right:  solid 0.5rem white;
                bottom: solid 0.5rem transparent;
                left:   solid 0.5rem transparent;
            }
            border-radius: 0 0.25rem 0 0;

        }
    }
    &[data-popper-placement^="top"]    > &__arrow { bottom: -0.5rem; &::before { transform: rotateZ(135deg); }}
    &[data-popper-placement^="right"]  > &__arrow { left  : -0.5rem; &::before { transform: rotateZ(225deg); }}
    &[data-popper-placement^="bottom"] > &__arrow { top   : -0.5rem; &::before { transform: rotateZ(315deg); }}
    &[data-popper-placement^="left"]   > &__arrow { right : -0.5rem; &::before { transform: rotateZ(45deg); }}

    /** Set the style of the inner buttons. */
    &__share-button {
        // Display the contents in a column.
        display: flex; flex-direction: column;
        justify-content: space-between; align-items: center;
        gap: 0.5rem; overflow: hidden;
        padding: 0.5rem 0;

        // Set the size of the button.
        height: $height; width: calc(calc($width - 0.5rem) / 3);

        // Remove the default style.
        &:focus, &:active, &:hover, & { background: none; border: none; outline: none; }

        border-radius: 1rem;

        // Set the colour of the text.
        @include colours.themed {
            color: colours.t("primary");
            &:hover, &:active {
                * { filter: saturate(0) brightness(0) invert(1); }

                background: colours.t("secondary");
            }
        }
        text-decoration: none;


        // Set the style of the icon.
        &__icon {
            // Fill the container.
            height: 75%; max-width: 100%;

            &:is(img) { height: 100%; }

            flex: 1 1 0;

            // Set the style of the qr-code container.
            &__container {
                // Fill the container.
                flex: 1 1 0;

                // Display the image in the center.
                display: flex; flex-direction: column;
                justify-content: center; align-items: center;
                overflow: hidden;
            }
        }

        // Set the style of the text.
        &__text { margin: 0; font-size: 0.8rem; line-height: 1; }
    }
}
