/*!
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

@use "scss/colours";
@use "sass:math";

/** Set the style of the activity row. */
.activity {
    // Display the contents in a row.
    display: flex; flex-direction: row;
    justify-content: flex-start; align-items: center;
    gap: 2rem;

    // Change the cursor when hovered.
    cursor: pointer;

    // Hide when the link is expired.
    :global(.share-link--expired) & { display: none; }
}

/** Set the style of the label. */
.label {
    // Set the size of the label.
    flex: 0 0 2.5rem;

    // Set the style of the text.
    font-size: 0.8rem;
    @include colours.themed {
        color: colours.t("text");
    }

    // Change the colour when the link is inactive.
    :global(.share-link--inactive) & {
        @include colours.themed {
            color: colours.t("background-dark");
        }
    }
}

/** Set the style of the toggle. */
.toggle {
    $height: 1.25rem; $margin: 2px;

    // Use the position as anchor.
    position: relative;

    // Set the size of the element.
    height: $height; width: $height * 2; flex: 0 0 $height * 2;

    // Set the style of the background.
    border-radius: math.div($height, 2);
    @include colours.themed {
        background-color: colours.t("primary");
    }

    // Set the style of the thumb.
    &::after {
        content: "";

        // Place at the right of the parent.
        position: absolute; top: $margin; right: $margin;

        // Add a transition when toggled.
        transition: right ease-in-out 0.25s;

        // Set the size of the thumb.
        height: calc($height - $margin * 2); width: calc($height - $margin * 2);

        // Set the style of the box.
        border-radius: $height;
        @include colours.themed {
            background-color: colours.t("background");
        }
    }

    // Change the style when the link is inactive.
    :global(.share-link--inactive) & {
        // Change the colour of the background.
        @include colours.themed {
            background-color: colours.t("background-dark");
        }

        // Place the thumb at the left of the parent.
        &::after { right: calc($height * 2 - $height + $margin); }
    }
}

/** Hide the underlying input. */
.input { display: none; }
