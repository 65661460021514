/*!
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

@use "scss/colours";

/** Set the style of the button. */
.button {
    // Set the size of the button.
    height: 2rem; width: 2rem;

    // Display the icon in the center.
    display: flex; justify-content: center; align-items: center;

    // Set the style of the box.
    border-radius: 1rem;
    @include colours.themed {
        color: colours.t("primary");
        border: solid 1px colours.t("primary");
    }

    // Change the style when opened.
    &--toggled {
        @include colours.themed {
            color: colours.t("text-light");
            background-color: colours.t("primary");
        }
    }

    transition: background-color 0.15s ease-in-out, color 0.15s ease-in-out;
}
