/*!
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import the colours.
@import "css/colours";

/** Set the style of the list. */
.list {
    // Display the contents in a column.
    flex: 0 0 auto;
    display: flex; flex-direction: column;
    justify-content: flex-start; align-items: stretch;
    overflow: hidden auto; flex-wrap: nowrap;

    // Remove the default styling.
    padding: 0; margin: 0; list-style: none;

    /** Set the style of the items. */
    &__item {
        cursor: pointer;

        // Add a slight padding.
        padding: 0 0.5rem; margin: 0;

        // Add a bottom border.
        border-bottom: solid 1px $tertiary-colour;
    }

    // Set the style of the selectable items.
    :global(.list--selectable) &__item:hover { color: white; background-color: $blue; }

    /** Set the style of the category. */
    &__category {
        cursor: pointer;

        // Display the contents in a column.
        display: flex; flex-direction: column;
        justify-content: flex-start; align-items: stretch;
        overflow: hidden; flex-wrap: nowrap;
        flex: 0 0 auto;

        // Do not allow overflow inside of a category.
        & .list { overflow: hidden; }

        /** Set the style of the title. */
        &__title {
            // Display the contents in a row.
            display: flex; flex-direction: row;
            justify-content: flex-start; align-items: center;

            // Add a bottom border.
            border-bottom: solid 2px $tertiary-colour;

            // Set the style of the text.
            p { color: $blue-dark; margin: 0; }
            // Set the style of the icon.
            img { height: 1rem; width: 1rem; margin-right: 0.5rem; }

        }
        // Handle the icon rotation.
        &--opened &__title img { transform: rotate(270deg); }
        &--closed &__title img { transform: rotate(180deg); }

        // Set the style of the opened/closed lists.
        &--opened .list { height: auto; }
        &--closed .list { height: 0; }
    }
}
