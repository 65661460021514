/*!
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

@use "scss/colours";
@use "scss/mixins";


/** Set the style of the type container. */
.type {
    // Display the contents in a row.
    display: flex; flex-direction: row;
    justify-content: flex-start; align-items: center;
    gap: 0.25rem; padding: 0; margin: 0;

    // Set the colour of the inner elements.
    @include colours.themed {
        color: colours.t("text");
    }
}

/** Set the style of the text. */
.text {
    // Clear the default margin.
    margin: 0;

    // Set the style of the text.
    font-size: 1rem; font-weight: bold;
    white-space: nowrap;

    // Change the size on smaller devices.
    @include mixins.media-breakpoint-down(sm) { font-size: .75rem; }
}

/** Set the style of the icon. */
.icon {
    // Set the default size of the icon.
    height: 1rem; width: 1rem;

    // Change the size on smaller devices.
    @include mixins.media-breakpoint-down(sm) { height: .75rem; width: .75rem; }
}
