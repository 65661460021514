/*!
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

/** Set the style of the parsers. */
.parser {
    position: absolute; top: 0; left: 0;
    width: 100%; height: 100%; visibility: hidden; pointer-events: none;
}
