/*!
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

@use "scss/colours";
@use "scss/shadows";
@use "scss/mixins";

/** Set the style of the header. */
.header {
    // Set the style of the locked title.
    &--locked {
        @include colours.themed {
            color: colours.t("background-dark");
        }
    }

    // Override the default colour of the text.
    > h2, input { color: inherit; font-size: 1.5rem; }
}

/** Set the style of the body container. */
.body {
    // Display the elements in a column.
    display: flex; flex-direction: column;
    justify-content: flex-start; align-items: stretch;
    gap: 0;

    // Display the elements in a row on larger screens.
    @include mixins.media-breakpoint-up(md) {
        flex-direction: row;
    }
}

/** Set the style of the item container. */
.item-container {
    // Display the items in a grid.
    display: grid; grid-gap: 1rem;

    // Set the style of the small container.
    grid-template-columns: 1fr;
    @include mixins.media-breakpoint-up(sm) { grid-template-columns: repeat(2, 1fr); }

    // Set the style of the larger containers.
    @include mixins.media-breakpoint-up(md) {
        grid-template-columns: repeat(4, 1fr); flex: 1 1 0;
        .body--editing & { grid-template-columns: repeat(3, 1fr); flex: 3 3 0; }
    }
    @include mixins.media-breakpoint-up(xl) {
        grid-template-columns: repeat(6, 1fr);
        .body--editing & { grid-template-columns: repeat(4, 1fr); flex: 2 2 0; }
    }

    // Add some outside padding.
    padding: 1rem 2rem;
}
