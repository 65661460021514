/*!
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

@use "scss/colours";


/** Set the style of the microphone icon. */
.microphone {
    // Default to the text colour.
    @include colours.themed {
        color: colours.t("text");
    }

    // Add a transition to the microphone colour and transform.
    transform-origin: center bottom;
    transition: color 0.125s ease-in-out, transform 0.125s ease-in-out;

    // Change the colour and scale when active.
    &--active {
        transform: scale(150%);
        @include colours.themed {
            color: colours.t("secondary");
        }
    }

    // Add a shaking animation when clicked.
    &--active &__icon {
        @keyframes shake {
            from { transform: rotateZ(0); }
            12.5%  { transform: rotateZ(-12.5deg); }
            25.0%  { transform: rotateZ(0); }
            37.5%  { transform: rotateZ(+12.5deg); }
            50.0%  { transform: rotateZ(0); }
            62.5%  { transform: rotateZ(-12.5deg); }
            75.0%  { transform: rotateZ(0); }
            87.5%  { transform: rotateZ(+12.5deg); }
            to     { transform: rotateZ(0); }
        }
        animation: shake 0.5s linear;
    }
}
