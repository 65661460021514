/*!
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import the colours.
@import "css/colours";

/** Set the style of the role element. */
.role {
    // Display the elements in a row.
    display: flex; flex-direction: row;
    justify-content: flex-start; align-items: stretch;
    overflow: hidden; flex-wrap: nowrap;

    // Remove the default styling.
    list-style: none; padding: 0 0 0 1rem; margin: 0;

    /** Set the style of the switches. */
    &__switch {
        &--editor { margin-left: 1rem; }
    }
}
