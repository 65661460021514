/*!
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

@use "scss/colours";
@use "scss/mixins";
@use "sizes";


/** Set the style of the loader icon. */
.loader { height: 2rem; }

/** Set the style of the item. */
.item {
    // Display the contents in a grid.
    display: grid;
    grid-template-columns:
        sizes.sizeof("title")
        sizes.sizeof("due-date")
        sizes.sizeof("expected-level")
        sizes.sizeof("achieved-level")
        sizes.sizeof("status");
    grid-template-rows: auto auto;
    grid-template-areas: "title due-date expected achieved status" "progress progress progress progress progress";
    align-items: center; justify-items: center;
    gap: 0 0.5rem; padding: 0.25rem 0.5rem 0.5rem;

    // Change the sizes on smaller displays.
    @include mixins.media-breakpoint-down(sm) {
        grid-template-columns:
        sizes.sizeof("title", "sm")
        sizes.sizeof("due-date", "sm")
        sizes.sizeof("expected-level", "sm")
        sizes.sizeof("achieved-level", "sm")
        sizes.sizeof("status", "sm");
    }
}

/** Set the style of the title. */
.title { grid-area: title; justify-self: start; }
/** Set the style of the due-date. */
.due-date { grid-area: due-date; }
/** Set the style of the expected level. */
.expected { grid-area: expected; }
/** Set the style of the achieved level. */
.achieved { grid-area: achieved; }
/** Set the style of the status. */
.status { grid-area: status; }
/** Set the style of the progress-bar. */
.progress { grid-area: progress; justify-self: stretch; margin-top: 1rem; }
