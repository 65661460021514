/*!
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

// Import the colours.
@use "scss/colours";


/** Set the style of the validation component. */
.validate {
    // Display the contents in a row.
    display: flex; flex-direction: row;
    justify-content: space-between; align-items: center;
    flex-wrap: nowrap; overflow: hidden;

    // Add some inside padding.
    padding: 0 0.5rem 0.5rem;
}

/** Set the style of the buttons. */
.button {
    // Display the contents in a row.
    display: flex; flex-direction: row;
    justify-content: flex-start; align-items: center;
    padding: 0.25rem 0.5rem; gap: 0.5rem;

    // Set the style of the button.
    border-radius: 1.5rem;
    @include colours.themed {
        background-color: colours.t("background");
        border: solid 1px transparent;
    }

    // Set the style of the text.
    font-size: 1rem; line-height: 1.2;

    // Set the style of the icon.
    &__icon { height: 1rem; }

    // Hide when disabled.
    &:disabled { display: none; }

    // Set the style of the validate button.
    &--validate {
        @include colours.themed {
            color: colours.t("primary");
            border-color: colours.t("primary");

            // Change when active.
            &:active {
                color: colours.t("text-light");
                background-color: colours.t("primary");
            }
        }
    }

    // Set the style of the cancel button.
    &--cancel {
        @include colours.themed {
            color: colours.t("danger");
            border-color: colours.t("danger");

            // Change when active.
            &:active {
                color: colours.t("text-light");
                background-color: colours.t("danger");
            }
        }
    }
}
