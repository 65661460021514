/*!
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

@use "./index.module.scss";

/** Set the style of the text module. */
.text {
    @extend .module;

    // Display the contents in a column.
    display: flex; flex-direction: column;
    justify-content: flex-start; align-items: flex-start;
    overflow: hidden auto; height: 100%;
    pointer-events: all;

    // Add some inside padding.
    padding: 0 0.5rem 2rem;
    margin: 1rem 0.5rem;

    // Set the style of the title.
    &__title { @extend .title; align-self: center; }
}
