/*!
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

@use "scss/colours";


/** Set the style of the container. */
.container {
    // Display the contents in a grid.
    display: grid; grid-template-columns: 1fr 3fr 2rem;
    grid-column-gap: 0.5rem;
}

/** Set the style of the label. */
.label {
    // Set the style of the text.
    font-weight: bold;
    @include colours.themed { color: colours.t("secondary"); }
}

/** Set the style of the input. */
.input {
    // Remove the default styling.
    all: unset;

    // Display the text at the start.
    display: flex; flex-direction: row;
    justify-content: flex-start; align-items: center;

    // Set the style of the box.
    border-radius: 0.5rem; height: 1.5rem;
    line-height: 1; padding: 0 0.5rem;
    @include colours.themed {
        border: solid 1px colours.t("primary");
    }

    // Set the style of the text.
    @include colours.themed {
        color: colours.t("text");
    }
}

/** Set the style of the buttons. */
.button {
    // Clear the default styling.
    all: unset;

    // Display the icon in the center.
    display: flex; justify-content: center; align-items: center;

    // Set the style of the container.
    padding: 0.25rem; margin: 0;
    border-radius: 1rem;
    align-self: center;
    justify-self: center;
}

/** Set the style of the delete button. */
.delete {
    @extend .button;

    // Set the colour of the button.
    @include colours.themed {
        color: colours.t("danger");
        &:hover {
            color: colours.t("danger-pressed");
        }
    }

    // Set the style of the icon.
    &__icon { height: 1rem; width: 1rem; }
}

/** Set the style of the add button. */
.add {
    @extend .button;

    // Justify at the start of the column.
    justify-self: start;

    // Set the colour of the button.
    @include colours.themed {
        color: colours.t("success");
        &:hover {
            color: colours.t("success-pressed");
        }
    }

    // Set the style of the icon.
    &__icon { height: 1rem; width: 1rem; }
}
