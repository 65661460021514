/*!
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import the app colours.
@import "css/colours";
// Import the mixins.
@import "css/mixins";

/** Set the style of the delete user modal. */
.delete-user {
    // Display the contents in a column.
    display: flex; flex-direction: column;
    justify-content: flex-start; align-items: stretch;
    overflow: hidden; flex-wrap: nowrap;

    // Add a slight inset shadow.
    box-shadow: inset 0 0 1rem $tertiary-colour;
    border-radius: 0 0 1rem 1rem;

    /** Set the style of the rows. */
    &__row {
        // Display the contents in a row.
        display: flex; flex-direction: row;
        justify-content: space-evenly; align-items: center;
        overflow: hidden; flex-wrap: nowrap;
    }

    /** Set the style of the warning icon. */
    &__warning {
        // Fill a fourth of the row.
        flex: 25 25 0; min-width: 4rem; max-height: 8rem;
        filter: $low-colour-filter; align-self: center;

        // Display the image in the center.
        display: flex; justify-content: center; align-items: stretch;
        padding: 1rem 0;
        img { flex: 1 1 0; }
    }

    /** Set the style of the text. */
    &__text {
        text-align: justify;
        @include media-breakpoint-down(md) { text-align: center; }
        padding-bottom: 1rem;
    }

    /** Set the style of the buttons. */
    &__button {
        // Display the contents in a row.
        display: flex; flex-direction: row;
        justify-content: space-between; align-items: center;
        overflow: hidden; flex-wrap: nowrap;

        // Set the size of the button.
        height: 2.5rem; min-width: 6rem; max-width: 10rem;
        padding: 0.25rem 0.5rem 0.25rem 0.25rem;
        &--delete { padding-left: 0.75rem; }

        // Roundify the borders.
        border-radius: 2rem;

        // Set the style of the image.
        img { height: 100%; }
        // Set the style of the text.
        p { margin: 0 0 0 0.5rem; flex: 1 1 0; color: white; font-size: 1rem; }
    }
}
