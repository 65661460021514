/*!
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import the colours.
@import "css/colours";

/** Set the style of the select. */
.select {
    position: relative; width: auto;
    display: flex; justify-content: center; align-items: stretch;
    cursor: pointer;

    /** Set the style of the underlying select. */
    select {
        border: unset; outline: none;
        appearance: none; -webkit-appearance: none; -moz-appearance: none;
        cursor: pointer;

        // Set the style of the box.
        background-color: white;
        // Set the colour of the text.
        color: $secondary-colour; font-weight: normal;
        white-space: break-spaces; text-align: center;

        // Add a padding to the select if it is active.
        &:not(&--disabled) { padding-right: 2rem; }
    }


    // Set the style of the button.
    background: white;
    padding: 0; border-radius: 0.5rem;
    margin: 0.5rem;
    box-shadow: 0.2rem 0.2rem 0.2rem -0.125rem rgba(0, 0, 0, 0.2);

    /** Render the chevron in the box. */
    &:not(&--disabled){
        &::after {
            position: absolute;
            content: "";
            top: 50%; right: 1rem;
            transform: translateY(-50%) rotateZ(-90deg);
            height: 1rem; width: 0.5rem;
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
            background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI1LjAuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkNhbHF1ZV8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHg9IjBweCIgeT0iMHB4IgogICAgIHZpZXdCb3g9IjAgMCAzMC4xIDUwIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAzMC4xIDUwOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6IzM1OWRkMTt9Cjwvc3R5bGU+CjxwYXRoIGNsYXNzPSJzdDAiIGQ9Ik01LjIsMzEuMUwyMyw0OC45YzEsMSwyLjYsMSwzLjYsMGwyLjctMi43YzEtMSwxLTIuNiwwLTMuNkwxMy40LDI2LjZjLTEtMS0xLTIuNiwwLTMuNkwyOS4zLDdjMS0xLDEtMi42LDAtMy42CglsLTIuNy0yLjdjLTEtMS0yLjYtMS0zLjYsMEw1LjIsMTguNUwwLjgsMjNjLTEsMS0xLDIuNiwwLDMuNkw1LjIsMzEuMXoiLz4KPC9zdmc+Cg==");
            pointer-events: none;
            cursor: pointer;
        }
    }
}
