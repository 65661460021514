/*!
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import the app colours.
@use "scss/colours";

/** Set the style applied to the pdf renderer. */
.pdf {
    // Display the contents in the center.
    position: relative;
    display: grid; flex-direction: column;
    justify-items: center; align-items: center;
    align-self: stretch; gap: 0.5rem;

    /** Hide the annotations. */
    :global .annotationLayer { display: none; }

    // Use the z-index as a basis.
    z-index: 0;

    /** Set the style of the document. */
    &__document, &__page {
        // Display the contents in the center.
        display: flex; flex-direction: column;
        justify-content: center; align-items: center;
    }

    /** Set the style of the selector. */
    &__selector {
        // Display in front of the pdf file.
        z-index: 1;

        // Display on the top left of the parent.
        position: absolute; top: 0.5rem; left: 1rem;
        &--fixed { position: fixed; left: 0.5rem; }

        // Display the contents in a row.
        display: flex; flex-direction: row;
        justify-content: flex-start; align-items: center;

        // Forbid any text or image user selection.
        user-select: none;

        // Set the style of the container.
        @include colours.themed { background-color: colours.t("text"); }
        border-radius: 1.5rem;
        &--fixed { border: solid 0.125rem white; }

        // Add some padding.
        padding: 0.5rem;

        // Set the style of the font.
        font-size: 1rem;
        @include colours.themed { color: colours.t("text-light"); }

        /** Set the style of the text. */
        &__text { margin: 0 0.25rem; line-height: 1; }

        /** Set the style of the buttons. */
        &__button {
            // Set the size of the button.
            height: 1rem; flex: 0 0 auto;

            // Display the icon in the center.
            display: flex; flex-direction: column;
            justify-content: center; align-items: center;
            overflow: hidden; padding: 0;

            // Remove the default styling.
            background-color: unset; border: unset;
            &:hover { background-color: unset; border: unset; outline: none; }
            &:focus { background-color: unset; border: unset; outline: none; }

            // Set the style of the icons.
            color: inherit;
            &__icon { height: 100%; }

            // Add a disabled filter.
            &:disabled { display: none; }
        }
    }
}
