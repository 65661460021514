/*!
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import the colours.
@import "css/colours";
// Import the shadows.
@import "css/shadows";

/** Set the style of the header. */
.header {
    // Display the contents in a row.
    display: flex; flex-direction: row;
    justify-content: flex-start; align-items: center;
    > * { flex: 1 1 0; }
    padding: 0.5rem 0;

    // Fill a bit more of the parent.
    width: 110%; margin: 1rem 0 0 -5%;

    // Add a box shadow.
    box-shadow: $regular-centered-box-shadow;
    border-radius: 1rem 1rem 0 0;

    // Set the style of the cells.
    &__cell {
        display: flex; flex-direction: row;
        justify-content: space-between; align-items: center;
        flex-wrap: wrap; padding: 0 1rem;
        p { color: $blue-dark; margin: 0; padding: 0; }
        select { border: none; color: $blue-dark; }
    }
}
