/*!
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

@use "scss/colours";
@use "scss/shadows";


/** Set the style of the required level. */
.required {
    height: 1.5rem; width: 1.5rem;

    /** Set the style of the container. */
    &__container {
        // Display the elements in a row.
        display: flex; flex-direction: row;
        justify-content: flex-start; align-items: center;
        gap: 0.5rem;
    }

    /** Set the style of the title. */
    &__title {
        padding-left: 1rem;
        @include colours.themed {
            color: colours.t("secondary");
        }
    }

    &__pill {
        display: flex; flex-direction: row;
        justify-content: flex-start; align-items: center;
        gap: 0.5rem; padding: 0.25rem 0.5rem;
        border-radius: 0.25rem;
    }

    &__background {
        @include colours.themed {
            fill: colours.t("background");
        }
    }
}

/** Set the style of the alert. */
.alert {
    border-radius: 0;
    @include colours.themed {
        background-color: colours.t("background");
        border: none;
    }

    // Remove the ugly box shadow.
    > button:focus { box-shadow: none };
}
