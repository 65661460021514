/*!
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

@use "scss/colours";


/** Set the style of the container. */
.container {
    display: flex; flex-direction: row;
    justify-content: flex-start; align-items: flex-start;
    gap: 0.5rem;
}

/** Set the style of the body. */
.body {
    // Take the entire width of the parent.
    flex: 1 1 0; resize: none; overflow: hidden;

    // Remove the default border.
    border: unset; background: unset;
    &:focus, &:active { outline: unset; }
    &:is(textarea) {
        &:focus, &:active {
            font-style: italic;
            @include colours.themed {
                border: solid 2px colours.t("secondary");
            }
        }
    }
    &::placeholder {
        @include colours.themed {
            color: colours.t("danger");
        }
        font-weight: normal;
    }

    // Clear all padding/margins.
    padding: 0; margin: 0 0 0 0.5rem;

    // Preserve any whitespace.
    white-space: pre-line; word-wrap: break-word;

    // Set the style of the text.
    @include colours.themed {
        color: colours.t("secondary");
    }
    font-size: 1rem;
}
